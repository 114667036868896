import {Icon, Portal} from '@kensho/neo'
import clsx from 'clsx'
import {useCallback, useRef, useState} from 'react'

import FloatingContainer from '../../components/FloatingContainer'
import Media from '../../components/Media'
import useObjectURL from '../../hooks/useObjectURL'
import {ScribeError} from '../../types/types'

interface Props extends React.HTMLAttributes<HTMLMediaElement> {
  mediaFile: File
  setMediaEle: React.Dispatch<React.SetStateAction<HTMLMediaElement | null>>
  controls?: boolean
  playbackRate?: number
  volume?: number
  muted?: boolean
  paused: boolean
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
  fullscreen?: boolean
  mediaPlayable: boolean
  onMediaError: (error?: ScribeError) => void
  onFullscreenChange?: () => void
}

export default function MediaContainer(props: Props): React.ReactNode {
  const {mediaPlayable, setMediaEle, mediaFile, paused, setPaused, ...passthru} = props
  const [mediaIsVideo, setMediaIsVideo] = useState<boolean>(false)
  const [isFloating, setIsFloating] = useState<boolean>(false)
  const mouseDownPositionRef = useRef<{x: number; y: number} | null>(null)

  const mediaSrc = useObjectURL(mediaFile)

  const onMediaTypeChange = useCallback((isVideo: boolean) => {
    setMediaIsVideo(isVideo)
  }, [])

  const handleSetMediaEle = useCallback(
    (element: HTMLMediaElement | null) => {
      setMediaEle(element)
    },
    [setMediaEle],
  )

  return (
    <div
      className={clsx(
        'relative flex h-16 w-16 items-center justify-center overflow-hidden rounded-sm bg-slate-200',
        !mediaIsVideo && 'hidden',
      )}
    >
      {mediaIsVideo && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video className="h-full w-full object-cover">
          <source src={mediaSrc} type={mediaFile.type} />
        </video>
      )}

      <div className="absolute z-10 flex h-full w-full items-center justify-center bg-black/50 opacity-0 transition-opacity duration-200 ease-in-out hover:opacity-100">
        <button
          type="button"
          className="flex h-1/2 w-1/2 items-center justify-center rounded-full bg-slate-200"
          aria-label="Popout video"
          onClick={() => setIsFloating(!isFloating)}
        >
          <span className={clsx(isFloating && 'rotate-180')}>
            <Icon icon="ChevronUpIcon" size="large" />
          </span>
        </button>
      </div>

      <Portal>
        <FloatingContainer
          className={clsx('group rounded-lg bg-black', isFloating ? 'block' : 'hidden')}
          initialWidth={380}
          initialHeight={210}
          initialTop={115}
          initialLeft={15}
          minHeight={150}
          minWidth={150}
        >
          <Media
            className="h-full w-full cursor-move"
            ref={handleSetMediaEle}
            mediaIsVideo={mediaIsVideo}
            mediaSrc={mediaSrc}
            paused={paused}
            showSubtitles={isFloating}
            onMouseDown={(e) => {
              mouseDownPositionRef.current = {x: e.clientX, y: e.clientY}
            }}
            onMouseUp={(event) => {
              if (!mouseDownPositionRef.current) return

              const {x, y} = mouseDownPositionRef.current
              if (event.clientX === x && event.clientY === y) {
                setPaused((prev) => !prev)
              }
            }}
            onMediaTypeChange={onMediaTypeChange}
            {...passthru}
          >
            <source src={mediaSrc} type={mediaFile.type} />
          </Media>
          {paused && (
            <div className="pointer-events-none absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-300 text-slate-600 opacity-70 transition-all duration-200 ease-in-out">
                <Icon icon="PlayIcon" size="large" />
              </div>
            </div>
          )}
          <button
            className="absolute top-2 right-2 flex h-8 w-8 items-center justify-center rounded-full bg-slate-200 opacity-0 transition-all duration-200 ease-in-out group-hover:opacity-100"
            type="button"
            aria-label="Close popout video player"
            onClick={() => setIsFloating(false)}
          >
            <Icon icon="XMarkIcon" />
          </button>
        </FloatingContainer>
      </Portal>
    </div>
  )
}
