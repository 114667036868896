import {useLogger} from '@kensho/lumberjack'
import {Tooltip, useToaster} from '@kensho/neo'
import {throttle} from 'lodash-es'
import {use, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {FileRejection} from 'react-dropzone'

import KeyboardShortcutsIcon from '../../assets/keyboard.svg'
import ErrorDialog from '../../components/ErrorDialog'
import ResizableContainer, {ResizableContainerRef} from '../../components/ResizableContainer'
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import useMultiplayerContext from '../../hooks/useMultiplayerContext'
import useTranscriptSelection from '../../hooks/useTranscriptSelection'
import TranscriptContext from '../../providers/TranscriptContext'
import ShortcutDrawer from '../../shortcuts/ShortcutDrawer'
import SHORTCUTS from '../../shortcuts/shortcutRegistration'
import {
  APITranscriptToken,
  EditorAction,
  Mode,
  ScribeError,
  TranscriptSelection,
  TranscriptionConfiguration,
} from '../../types/types'
import {TranscriptEditAction} from '../../utils/transcriptRevisionUtils'
import TranscriptionContext from '../../providers/TranscriptionContext'

import ActionBar from './ActionBar'
import FileUpload from './FileUpload'
import PlaybackUploadDialog from './PlaybackUploadDialog'
import {TranscriptPermissionsContext} from './TranscriptPermissionsProvider'
import {PLAYBACK_RATES} from './actions/PlaybackRateButton'
import TimestampManager from './timestampManager/TimestampManager'
import {DispatchEditorActionProvider} from './transcript/DispatchEditorActionProvider'
import TranscriptContainer, {TranscriptContainerRef} from './transcript/TranscriptContainer'
import useRemoteTranscriptChanges from './transcript/useRemoteTranscriptChanges'
import useTranscriptEditQueue from './transcript/useTranscriptEditQueue'

interface Props {
  transcriptId: string
  mode: Mode
  mediaFile?: File
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  reset: () => void
}

export default function Transcription({
  transcriptId,
  mode,
  mediaFile,
  setMediaFile,
  audioInputDeviceId,
  transcriptionConfiguration,
  setTranscriptionConfiguration,
  reset,
}: Props): React.ReactNode {
  const {metadata, stage} = use(TranscriptionContext)
  const {transcript, dispatch: transcriptContextDispatch, undos, redos} = use(TranscriptContext)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {sendMessage, sessionId} = useMultiplayerContext()
  const toaster = useToaster()

  const [isShortcutOpen, setIsShortcutOpen] = useState(false)

  const [error, setError] = useState<ScribeError>()
  const [currentTime, setCurrentTime] = useState(0) // seconds
  const [duration, setDuration] = useState<number>() // seconds
  const [showTimestampManager, setShowTimestampManager] = useState(false)

  // media
  const [mediaPlayable, setMediaPlayable] = useState(true)
  const [paused, setPaused] = useState(true)
  const [playbackRate, setPlaybackRate] = useState(1)

  // transcript
  const [transcriptDownloaded, setTranscriptDownloaded] = useState(false)
  const transcriptContainerRef = useRef<TranscriptContainerRef>(null)
  const [isTranscriptFocused, setIsTranscriptFocused] = useState(false)

  // confirm before navigating away from page without downloading realtime transcript
  useEffect(() => {
    setTranscriptDownloaded(false)
  }, [transcript])

  useEffect(() => {
    const warnUnsavedTranscript = (event: BeforeUnloadEvent): void => {
      if (mode !== 'realtime' || !['POST_TRANSCRIPTION'].includes(stage) || transcriptDownloaded)
        return

      event.preventDefault()
      /* eslint-disable-next-line no-param-reassign */
      event.returnValue = 'Leave page and discard this transcript?'
    }

    window.addEventListener('beforeunload', warnUnsavedTranscript)
    return () => {
      window.removeEventListener('beforeunload', warnUnsavedTranscript)
    }
  }, [transcriptDownloaded, stage, mode])
  const showUnsavedTranscriptWarning = useMemo(
    () => mode === 'realtime' && ['POST_TRANSCRIPTION'].includes(stage) && !transcriptDownloaded,
    [transcriptDownloaded, stage, mode],
  )

  const handleFileSwitch = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
      if (acceptedFiles.length + fileRejections.length > 1) {
        toaster.show({
          label: `Only one audio/video file can be uploaded at a time`,
          intent: 'danger',
        })
        return
      }

      if (fileRejections.length) {
        toaster.show({
          label: `Unsupported file. Please upload a valid audio/video file`,
          intent: 'danger',
        })
        return
      }

      setMediaPlayable(true)
      setMediaFile(acceptedFiles[0])
    },
    [toaster, setMediaFile],
  )

  const [mediaEle, setMediaEle] = useState<HTMLAudioElement | null>(null)

  const onMediaError = useCallback((e?: ScribeError) => {
    // if the file loads but is not playable, skip playback but still let them transcribe
    if (!e) {
      setMediaPlayable(false)
      return
    }

    setError(e)
  }, [])

  const seekMedia = useCallback(
    ({
      timeSeconds,
      play = true,
      scroll = true,
    }: {
      timeSeconds: number
      play?: boolean
      scroll?: boolean
    }): void => {
      if (mediaFile && mediaPlayable && mediaEle) {
        if (timeSeconds >= 0 && timeSeconds <= mediaEle.duration) {
          mediaEle.currentTime = timeSeconds
          if (play) {
            setPaused(false)
          }
          setCurrentTime(timeSeconds)
        } else if (timeSeconds <= 0) {
          mediaEle.currentTime = 0
          setCurrentTime(0)
        } else {
          mediaEle.currentTime = mediaEle.duration
          setCurrentTime(mediaEle.duration)
        }
      }
      if (scroll) transcriptContainerRef.current?.scrollTranscriptToTime(timeSeconds * 1000)
    },
    [mediaEle, mediaFile, mediaPlayable],
  )

  const onClickToken = useCallback(
    (token: APITranscriptToken): void => {
      if (stage !== 'POST_TRANSCRIPTION') return
      if (paused) seekMedia({timeSeconds: token.startMs / 1000, play: false, scroll: false})
    },
    [seekMedia, stage, paused],
  )

  const {transcriptSelection, updateTranscriptSelection} = useTranscriptSelection(
    transcript,
    isTranscriptFocused,
  )
  const transcriptEditQueue = useTranscriptEditQueue({
    onError: (err: ScribeError) => setError(err),
    transcriptId,
    disabled: !transcriptPermissions.edit || stage !== 'POST_TRANSCRIPTION' || mode === 'realtime',
    protocol: metadata?.protocol,
  })
  useRemoteTranscriptChanges()

  const dispatchEditorAction = useCallback(
    (editorAction: EditorAction): void => {
      if (!transcriptPermissions.edit) return
      // update client state
      transcriptContextDispatch({type: 'revision', editorAction})
      // copy operation to queue to be sent to server
      transcriptEditQueue.push(editorAction.revision)
      if (!editorAction.selectionChangeDisabled)
        updateTranscriptSelection(editorAction.afterSelection)
    },
    [
      transcriptPermissions,
      transcriptEditQueue,
      transcriptContextDispatch,
      updateTranscriptSelection,
    ],
  )

  const log = useLogger()
  const onEditOperationError = useCallback(
    (editOperationError: Error, action: TranscriptEditAction): void => {
      log.error(editOperationError, {actionType: action.type, transcriptId})
    },
    [transcriptId, log],
  )

  function undo(): void {
    if (!transcriptPermissions.edit) return
    const last = undos.at(-1)
    if (!last) return
    transcriptContextDispatch({type: 'undo', editorAction: last})
    transcriptEditQueue.push(last.inverseRevision)

    if (!last.selectionChangeDisabled) {
      setIsTranscriptFocused(true)
      updateTranscriptSelection(last.beforeSelection, false)
    }
  }
  function redo(): void {
    if (!transcriptPermissions.edit) return
    const last = redos.at(-1)
    if (!last) return
    transcriptContextDispatch({type: 'redo', editorAction: last})
    transcriptEditQueue.push(last.revision)

    if (!last.selectionChangeDisabled) {
      setIsTranscriptFocused(true)
      updateTranscriptSelection(last.afterSelection, false)
    }
  }

  useKeyboardShortcut(
    SHORTCUTS.Audio.skipBackwards.keys,
    () => {
      seekMedia({timeSeconds: currentTime - 3})
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
      preventDefault: true,
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.playPause.keys,
    () => {
      setPaused(!paused)
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )

  useKeyboardShortcut(
    SHORTCUTS.Audio.increaseSpeed.keys,
    () => {
      setPlaybackRate(
        PLAYBACK_RATES[
          Math.min(PLAYBACK_RATES.length - 1, PLAYBACK_RATES.indexOf(playbackRate) + 1)
        ],
      )
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.decreaseSpeed.keys,
    () => {
      setPlaybackRate(PLAYBACK_RATES[Math.max(0, PLAYBACK_RATES.indexOf(playbackRate) - 1)])
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
    },
  )
  useKeyboardShortcut(
    SHORTCUTS.Audio.skipForwards.keys,
    () => {
      seekMedia({timeSeconds: currentTime + 3})
    },
    {
      enabled: Boolean(mediaFile && mediaPlayable),
      preventDefault: true,
    },
  )

  useKeyboardShortcut(
    SHORTCUTS.View.toggleShortcutDrawer.keys,
    () => setIsShortcutOpen((prev) => !prev),
    {preventDefault: false, ignoreModifiers: true, enableOnContentEditable: false},
  )

  const bottomBarContainerRef = useRef<ResizableContainerRef>(null)

  useKeyboardShortcut(
    SHORTCUTS.View.toggleTimeline.keys,
    () => {
      const nextShowTimestampManager = !showTimestampManager
      setShowTimestampManager(nextShowTimestampManager)

      if (nextShowTimestampManager) {
        bottomBarContainerRef.current?.setSize({height: 224})
      } else {
        bottomBarContainerRef.current?.setSize({height: 80})
      }
    },
    {
      preventDefault: false,
      enableOnContentEditable: false,
      enabled: stage === 'POST_TRANSCRIPTION',
    },
  )

  const throttledSendCursorUpdate = useMemo(
    () =>
      throttle((selection: TranscriptSelection | null) => {
        if (selection && sessionId)
          sendMessage({
            type: 'cursor-update',
            payload: {start: selection.start, end: selection.end, sessionId},
          })
      }, 500),
    [sendMessage, sessionId],
  )

  useEffect(() => {
    throttledSendCursorUpdate(transcriptSelection)

    // periodically send cursor updates even when transcriptSelecition is unchanged
    const interval = window.setInterval(() => {
      throttledSendCursorUpdate(transcriptSelection)
    }, 3000)

    return () => {
      clearInterval(interval)
      throttledSendCursorUpdate.cancel()
    }
  }, [transcriptSelection, throttledSendCursorUpdate])

  return (
    <DispatchEditorActionProvider dispatchEditorAction={dispatchEditorAction}>
      <div className="flex h-[calc(100vh-80px)] flex-col overflow-hidden">
        <ErrorDialog isOpen={!!error} error={error} onClose={() => setError(undefined)} />

        <div className="m-auto mt-3 flex w-full max-w-[100vw] flex-auto justify-center gap-10 overflow-hidden">
          <TranscriptContainer
            ref={transcriptContainerRef}
            showPlayControls={Boolean(mediaFile)}
            mode={mode as Mode}
            stage={stage}
            currentTimeMs={currentTime * 1000}
            paused={paused}
            onClickToken={onClickToken}
            seekMedia={seekMedia}
            transcriptionConfiguration={transcriptionConfiguration}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
            transcriptSelection={transcriptSelection}
            updateTranscriptSelection={updateTranscriptSelection}
            onEditOperationError={onEditOperationError}
            undo={undo}
            redo={redo}
            isTranscriptFocused={isTranscriptFocused}
            setIsTranscriptFocused={setIsTranscriptFocused}
            setPaused={setPaused}
            setTranscriptDownloaded={setTranscriptDownloaded}
            showUnsavedTranscriptWarning={showUnsavedTranscriptWarning}
            media={mediaFile}
            transcriptId={transcriptId}
            reset={reset}
          />
        </div>

        <ResizableContainer
          ref={bottomBarContainerRef}
          className="max-h-56 flex-none border-t border-gray-200"
          disabled={!transcript || stage !== 'POST_TRANSCRIPTION'}
          initialWidth="100vw"
          initialHeight={80}
          minHeight={80}
          resize={{north: true}}
          onResize={({height}) =>
            setShowTimestampManager(typeof height === 'number' && height > 80)
          }
        >
          {['POST_TRANSCRIPTION'].includes(stage) && (
            <>
              <ShortcutDrawer isOpen={isShortcutOpen} onClose={() => setIsShortcutOpen(false)} />
              <Tooltip content="Keyboard shortcuts">
                <button
                  data-testid="keyboard-shortcuts-button"
                  className="absolute -top-14 right-5 z-10 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white ring-1 shadow-md ring-black/10 hover:bg-gray-50 active:bg-gray-100"
                  type="button"
                  onClick={() => setIsShortcutOpen((prev) => !prev)}
                >
                  <img src={KeyboardShortcutsIcon} alt="keyboard" />
                </button>
              </Tooltip>
            </>
          )}

          <FileUpload
            handleFileDrop={(acceptedFiles, fileRejections) =>
              handleFileSwitch(acceptedFiles, fileRejections)
            }
            enableDragState={false}
            disabled={mode === 'realtime'}
          >
            {({open}) => (
              <>
                <PlaybackUploadDialog
                  mode={mode}
                  stage={stage}
                  transcriptId={transcriptId}
                  hasMedia={Boolean(mediaFile)}
                  openFileChooser={open}
                />
                <ActionBar
                  mode={mode}
                  onMediaError={onMediaError}
                  setMediaEle={setMediaEle}
                  transcript={transcript}
                  transcriptId={transcriptId}
                  mediaFile={mediaFile}
                  mediaPlayable={mediaPlayable}
                  currentTime={currentTime}
                  seekMedia={seekMedia}
                  paused={paused}
                  setPaused={setPaused}
                  duration={duration}
                  playbackRate={playbackRate}
                  setPlaybackRate={setPlaybackRate}
                  setCurrentTime={setCurrentTime}
                  setDuration={setDuration}
                  setError={setError}
                  setMediaFile={setMediaFile}
                  audioInputDeviceId={audioInputDeviceId}
                  openFileChooser={open}
                />
              </>
            )}
          </FileUpload>

          {['POST_TRANSCRIPTION'].includes(stage) && showTimestampManager && transcript && (
            <TimestampManager
              transcript={transcript}
              currentTimeMs={currentTime * 1000}
              mediaEle={mediaEle}
              paused={paused}
              seekMedia={seekMedia}
            />
          )}
        </ResizableContainer>
      </div>
    </DispatchEditorActionProvider>
  )
}
