import {RouteObject} from 'react-router'

import Auth from './layouts/Auth'
import Root from './layouts/Root'
import AccountPage from './pages/AccountPage'
import ErrorPage from './pages/ErrorPage'
import HomePage from './pages/HomePage'
import RedirectTranscriptionPage from './pages/RedirectTranscriptionPage'
import TranscriptionConfigPage from './pages/TranscriptionConfigPage'
import TranscriptionLayoutPage from './pages/TranscriptionLayoutPage'
import TranscriptionPage from './pages/TranscriptionPage'
import TranscriptionsPage from './pages/TranscriptionsPage'

const routes: RouteObject[] = [
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {path: '/', element: <HomePage />},
      {path: '/transcription/:transcriptId?', element: <RedirectTranscriptionPage />},
      {
        element: <Auth />,
        children: [
          {path: '/account', element: <AccountPage />},
          {path: '/transcriptions', element: <TranscriptionsPage />},
          {
            element: <TranscriptionLayoutPage />,
            children: [
              {path: '/transcriptions/new/:mode', element: <TranscriptionConfigPage />},
              {path: '/transcriptions/:transcriptId/:mode?', element: <TranscriptionPage />},
            ],
          },
        ],
      },
    ],
  },
]

export default routes
