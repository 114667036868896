import ErrorBoundary from './ErrorBoundary'
import Footer from './Footer'
import Header from './Header'

interface BasePageProps {
  children: React.ReactNode
  header?: boolean
  footer?: boolean
}

export default function BasePage(props: BasePageProps): React.ReactNode {
  const {children, header = true, footer = true} = props

  const fallback = (
    <div className="mx-auto my-24 flex max-w-screen-sm flex-col items-center gap-2">
      <p className="text-lg font-bold">Something went wrong.</p>
      <p>Please refresh the page to try again.</p>
    </div>
  )

  return (
    <div className="flex min-h-screen flex-col">
      {header && <Header />}
      <div className="flex-1">
        <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
      </div>
      {footer && <Footer />}
    </div>
  )
}
