import {isEqual} from 'lodash-es'
import {useMemo} from 'react'

import {CursorHighlight} from '../../highlights/types'
import getUserColor from '../../utils/getUserColor'
import useMultiplayerContext from '../useMultiplayerContext'

export function useCursorHighlights(): CursorHighlight[] {
  const {connectedUsers, sessionId} = useMultiplayerContext()
  return useMemo(() => {
    const highlights: CursorHighlight[] = []
    const otherCursors = connectedUsers
      .filter((user) => {
        if (user.sessionId === sessionId) return false
        if (!user.cursorPosition) return false
        if (user.cursorPosition.start && user.cursorPosition.end) {
          return true
        }
        return false
      })
      .map(({cursorPosition, clientName}) => {
        const color = getUserColor(clientName)
        return {cursorPosition, color}
      })
    for (const {cursorPosition, color} of otherCursors) {
      if (cursorPosition) {
        const {end, start} = cursorPosition
        if (start && end) {
          const type = isEqual(start, end) ? 'Caret' : 'Range'
          highlights.push({
            type: 'cursor',
            range: {end, start, type},
            style: {backgroundColor: type === 'Caret' ? color.border : color.fill},
          })
        }
      }
    }
    return highlights
  }, [connectedUsers, sessionId])
}
