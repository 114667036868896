import {useMemo, useRef, useEffect} from 'react'

import useInView from '../hooks/useInView'

import {Rect, StyledSegments} from './types'
import drawSegmentInBlock from './drawSegmentInBlock'

export function AnnotationsLayerBlock(props: {
  styledSegments: StyledSegments[]
  pixelRatio: number
  x: number
  y: number
  height: number
  width: number
}): React.ReactNode {
  const {styledSegments, pixelRatio, x, y, height, width} = props
  const block = useMemo<Rect>(() => ({height, width, x, y}), [height, width, x, y])
  const {inView, ref} = useInView({initialInView: true})
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d')
    if (!ctx || !inView) return undefined
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.clearRect(0, 0, block.width, block.height)
    styledSegments.forEach((styledSegment) => {
      const {segmentGroup, style} = styledSegment
      segmentGroup.forEach((segment) => {
        const adjust = 24
        const upper = block.y - adjust
        const lower = block.y + block.height + adjust
        if (segment.y < upper || segment.y > lower) {
          return
        }
        drawSegmentInBlock(ctx, segment, style, block)
      })
    })
    return () => ctx.clearRect(0, 0, block.width, block.height)
  }, [block, pixelRatio, inView, styledSegments])

  const canvasStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'block',
    pointerEvents: 'none',
    top: block.y,
    left: block.x,
    height: block.height,
    width: block.width,
    willChange: 'transform',
  }
  return (
    <canvas
      height={block.height * pixelRatio}
      width={block.width * pixelRatio}
      style={canvasStyle}
      ref={(r) => {
        canvasRef.current = r
        ref(r)
      }}
    />
  )
}
