import {Component} from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback: React.ReactNode
}

interface ErrorBoundaryState {
  error: Error | null
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error}
  }

  state: ErrorBoundaryState = {
    error: null,
  }

  public render(): React.ReactNode {
    const {children, fallback} = this.props
    const {error} = this.state
    return error === null ? children : fallback
  }
}
