import {Button, Callout, Icon} from '@kensho/neo'
import {uniq} from 'lodash-es'
import {useDropzone} from 'react-dropzone'

import prettyFileSize from '../../../utils/prettyFileSize'
import {VALID_AUDIO_FILE_EXTS, VALID_VIDEO_FILE_EXTS} from '../constants'

import {MAX_FILE_UPLOAD_SIZE_BYTES} from './constants'

const VALID_FILE_EXTS = uniq([...VALID_AUDIO_FILE_EXTS, ...VALID_VIDEO_FILE_EXTS]).join(', ')

interface MediaFileUploadProps {
  errorText?: string
  file?: File
  isInvalid?: boolean
  onChange: (file: File | null, validation?: string) => void
}

function validateBatchFile(file?: File): string | undefined {
  if (!file) return `File is required`
  if (file.size > MAX_FILE_UPLOAD_SIZE_BYTES)
    return `File is too large. Max size ${prettyFileSize(MAX_FILE_UPLOAD_SIZE_BYTES)}`
  const extension = file.name.match(/.+(\..+)$/)?.[1]
  if (!extension || !VALID_FILE_EXTS.includes(extension.toLowerCase()))
    return `Invalid file type. Supported file types: ${VALID_FILE_EXTS}`
  return undefined
}

export default function MediaFileUpload({
  file,
  isInvalid = false,
  errorText,
  onChange,
}: MediaFileUploadProps): React.ReactNode {
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    maxSize: MAX_FILE_UPLOAD_SIZE_BYTES,
    multiple: false,
    onDrop(acceptedFiles, rejections) {
      const validationError =
        validateBatchFile(acceptedFiles[0] || rejections[0]?.file) ||
        (rejections.length
          ? uniq(
              rejections.map((rejection) => rejection.errors.map((err) => err.message).join(' ')),
            ).join(', ')
          : undefined)
      onChange(!validationError ? acceptedFiles[0] : null, validationError)
    },
    accept: {
      'audio/*': VALID_AUDIO_FILE_EXTS,
      'video/*': VALID_VIDEO_FILE_EXTS,
    },
  })

  return (
    <div className="flex flex-col gap-4">
      {isInvalid && (
        <Callout intent="danger" title="Invalid file.">
          {errorText}
        </Callout>
      )}

      <div className="flex flex-col gap-4">
        <div
          className="flex h-64 cursor-pointer items-center justify-center rounded-lg bg-blue-50"
          {...getRootProps()}
        >
          <input {...getInputProps()} multiple={false} />
          <div className="flex flex-col items-center text-brand-700">
            <Icon icon="PlusIcon" size="large" />
            <p className="mt-4 text-black">Select an audio/video file or drag-and-drop</p>
            <p className="text-gray-500">
              Maximum file size {prettyFileSize(MAX_FILE_UPLOAD_SIZE_BYTES)}
            </p>
          </div>
        </div>
        <p className="text-sm">Accepted file formats: {VALID_FILE_EXTS}</p>
      </div>

      {file && (
        <div>
          <p className="block pb-2 text-sm leading-6 font-medium text-gray-900">File</p>
          <div className="flex items-center gap-4 rounded-lg p-4 ring ring-gray-300">
            <div className="flex-none text-brand-700">
              <Icon icon="DocumentIcon" />
            </div>
            <p className="truncate">{file.name}</p>
            <div className="-my-2 -mr-2 ml-auto flex-none">
              <Button intent="primary" minimal icon="XMarkIcon" onClick={() => onChange(null)} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
