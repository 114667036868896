import {HeadlessButton, Tooltip} from '@kensho/neo'

import Ellipsis from '../../../../components/Ellipsis'
import useEditableTranscriptName from '../../../../hooks/useEditableTranscriptName'

interface TranscriptNameProps {
  name: string
  id: string
  refreshData?: () => void
}

export default function TranscriptName(props: TranscriptNameProps): React.ReactNode {
  const {name, id, refreshData} = props
  const {isEditing, value, contentRef, getTitleProps, getButtonProps, status} =
    useEditableTranscriptName({
      name,
      id,
      onSuccess: refreshData,
    })

  if (status === 'pending') return <Ellipsis />

  return (
    <div
      className="-mx-1 w-32"
      data-clarity-mask="True"
      onClick={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
    >
      {isEditing ? (
        <div
          className="w-full cursor-text rounded-md border border-brand-700 px-1 py-0.5 break-all whitespace-normal outline-none"
          ref={contentRef}
          role="textbox"
          contentEditable
          suppressContentEditableWarning
          {...getTitleProps()}
        >
          {value}
        </div>
      ) : (
        <Tooltip content={value}>
          <HeadlessButton
            className="block w-full truncate rounded-md border border-transparent px-1 py-0.5 text-left hover:border-gray-300"
            {...getButtonProps()}
          >
            {value}
          </HeadlessButton>
        </Tooltip>
      )}
    </div>
  )
}
