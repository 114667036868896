import {ButtonLink} from '@kensho/neo'

import error from '../assets/error.svg'

export default function NotFoundPage(): React.ReactNode {
  return (
    <div className="grid h-screen place-items-center px-8 py-32">
      <div className="mb-32 flex flex-col items-center">
        <div className="mb-16 grid grid-cols-2 items-center gap-6">
          <img className="w-80" src={error} alt="404" />
          <div className="flex flex-col gap-3">
            <h1 className="text-5xl">Oops…</h1>
            <h2 className="text-lg">Looks like this page doesn’t exist.</h2>
          </div>
        </div>
        <ButtonLink href="/" intent="primary">
          Return home
        </ButtonLink>
      </div>
    </div>
  )
}
