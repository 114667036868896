import {ProgressBar} from '@kensho/neo'
import {useQuery} from '@tanstack/react-query'
import {useNavigate} from 'react-router'

import useGetTranscript from '../../api/useGetTranscript'
import useGetTranscriptMetadata from '../../api/useGetTranscriptMetadata'
import ErrorDialog from '../../components/ErrorDialog'
import {MultiplayerProvider} from '../../providers/MultiplayerProvider'
import SpellCheckProvider from '../../providers/SpellCheckProvider'
import TranscriptProvider from '../../providers/TranscriptProvider'
import {APITranscript, Mode, TranscriptionConfiguration} from '../../types/types'
import getDefaultTranscript from '../../utils/getDefaultTranscript'
import parseError from '../../utils/parseError'
import TranscriptionProvider from '../../providers/TranscriptionProvider'

import Transcription from './Transcription'
import {TranscriptPermissionsProvider} from './TranscriptPermissionsProvider'

interface Props {
  transcriptId: string
  mode: Mode
  mediaFile?: File
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  reset: () => void
}

export function TranscriptionWithContext({
  transcriptId,
  mode,
  mediaFile,
  setMediaFile,
  audioInputDeviceId,
  transcriptionConfiguration,
  setTranscriptionConfiguration,
  reset,
}: Props): React.ReactNode {
  const getTranscriptMetadata = useGetTranscriptMetadata()
  const {data: metadata, error: metadataError} = useQuery({
    queryKey: ['transcriptMetadata', transcriptId],
    queryFn: async ({signal}) => getTranscriptMetadata(transcriptId, signal),
    gcTime: 0,
    enabled: mode === 'batch',
    refetchInterval: ({state: {data, status}}) => {
      const POLL_INTERVAL = 5000
      if (status === 'error') return false
      if (status === 'pending') return POLL_INTERVAL
      if (status === 'success') {
        if (!data) return false
        const {status: transcriptStatus} = data
        if (['complete', 'failed'].includes(transcriptStatus)) return false
        if (['in_progress', 'submitted'].includes(transcriptStatus)) return POLL_INTERVAL
      }
      return false
    },
    retry: false,
  })

  const [, getTranscript] = useGetTranscript()
  const {data: batchTranscript, error: transcriptError} = useQuery({
    queryKey: ['transcript', transcriptId],
    queryFn: async () => getTranscript({transcriptId}) as Promise<APITranscript>,
    enabled: metadata?.status === 'complete',
    gcTime: 0,
    retry: false,
  })
  const navigate = useNavigate()

  const realtimeTranscript = getDefaultTranscript()

  const transcript = mode === 'batch' ? batchTranscript : realtimeTranscript

  if (metadataError) {
    return (
      <ErrorDialog
        isOpen={!!metadataError}
        error={parseError(metadataError)}
        onClose={() => navigate('/transcriptions')}
      />
    )
  }

  if (metadata && metadata.status === 'failed') {
    return (
      <ErrorDialog
        isOpen
        error={{type: 'generic', title: 'Transcription Failed'}}
        onClose={() => {
          navigate('/transcriptions')
        }}
      />
    )
  }

  if (transcriptError) {
    return (
      <ErrorDialog
        isOpen={!!transcriptError}
        error={transcriptError ? parseError(transcriptError) : undefined}
        onClose={() => {
          navigate('/transcriptions')
        }}
      />
    )
  }
  if (!transcript) {
    return (
      <div className="mx-auto mt-10 h-full w-[750px] max-w-full overflow-auto px-6 py-5">
        <div className="mt-10">
          <div className="mt-2 mb-3 text-lg">
            {(() => {
              if (!metadata) return 'Checking Transcription Status…'
              if (['in_progress', 'submitted'].includes(metadata.status)) return 'Transcribing…'
              if (metadata.status === 'complete') return 'Loading…'
              return null
            })()}
          </div>
          <ProgressBar />
        </div>
      </div>
    )
  }

  return (
    <TranscriptionProvider
      initialMetadata={metadata}
      initialStage={mode === 'batch' ? 'POST_TRANSCRIPTION' : 'TRANSCRIPTION'}
    >
      <TranscriptPermissionsProvider>
        <MultiplayerProvider>
          <TranscriptProvider initialTranscript={transcript}>
            <SpellCheckProvider>
              <Transcription
                mode={mode}
                transcriptId={transcriptId}
                mediaFile={mediaFile}
                setMediaFile={setMediaFile}
                transcriptionConfiguration={transcriptionConfiguration}
                setTranscriptionConfiguration={setTranscriptionConfiguration}
                audioInputDeviceId={audioInputDeviceId}
                reset={reset}
              />
            </SpellCheckProvider>
          </TranscriptProvider>
        </MultiplayerProvider>
      </TranscriptPermissionsProvider>
    </TranscriptionProvider>
  )
}
