import {useLogger} from '@kensho/lumberjack'
import {Button} from '@kensho/neo'
import {use, useCallback, useMemo} from 'react'

import SpellCheckContext from '../../../../providers/SpellCheckContext'
import TranscriptContext from '../../../../providers/TranscriptContext'
import {TranscriptSelection} from '../../../../types/types'
import {produceEditorAction, TranscriptEditAction} from '../../../../utils/transcriptRevisionUtils'
import {getTextFromTranscriptSelection} from '../../../../utils/transcriptUtils'
import {TranscriptPermissionsContext} from '../../TranscriptPermissionsProvider'
import {useDispatchEditorAction} from '../DispatchEditorActionProvider'

interface Props {
  transcriptSelection: TranscriptSelection | null
  onEditOperationError?: (error: Error, action: TranscriptEditAction) => void
  onComplete: () => void
}

function ReviewSpelling(props: Props): React.ReactNode {
  const {transcriptSelection, onEditOperationError, onComplete} = props
  const {dispatchEditorAction} = useDispatchEditorAction()
  const {transcript} = use(TranscriptContext)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {suggest, addWord} = use(SpellCheckContext)
  const log = useLogger()

  const {text, suggestions} = useMemo(() => {
    const value: {text: string; suggestions: string[] | null} = {text: '', suggestions: null}

    if (!transcriptSelection) return value

    value.text = getTextFromTranscriptSelection(transcript, transcriptSelection)
    value.suggestions = suggest(value.text).slice(0, 3)

    return value
  }, [transcript, transcriptSelection, suggest])

  const replaceWord = useCallback(
    (suggestion: string): void => {
      if (!transcriptPermissions.edit) return

      // replace word with suggestion
      const editorAction = produceEditorAction({
        action: {type: 'insert-text', data: suggestion},
        transcript,
        transcriptSelection,
        onError: onEditOperationError,
        log,
      })
      if (!editorAction) return
      dispatchEditorAction(editorAction)
    },
    [
      transcript,
      transcriptPermissions,
      transcriptSelection,
      onEditOperationError,
      log,
      dispatchEditorAction,
    ],
  )

  return (
    <div className="flex h-full flex-col overflow-auto p-5">
      <div className="mb-10 shrink grow basis-full">
        <p>
          change <span className="font-bold">{text}</span> to
        </p>
        {!!suggestions?.length && (
          <ul className="my-4 flex gap-4">
            {suggestions.map((suggestion) => (
              <li key={suggestion}>
                <Button
                  rounded
                  onClick={() => {
                    if (!transcriptPermissions.edit) return
                    replaceWord(suggestion)
                    onComplete()
                  }}
                >
                  {suggestion}
                </Button>
              </li>
            ))}
          </ul>
        )}
        {suggestions && !suggestions.length && <p className="my-7 italic">No suggestions found</p>}
      </div>
      <footer className="text-right">
        <Button
          onClick={() => {
            // add to custom dictionary
            addWord(text)
            onComplete()
          }}
        >
          Ignore
        </Button>
      </footer>
    </div>
  )
}

export default ReviewSpelling
