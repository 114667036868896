import clsx from 'clsx'
import {useState} from 'react'
import {Portal} from '@kensho/neo'
import {autoUpdate, useFloating, offset, flip, shift} from '@floating-ui/react-dom'

import {ConnectedUser} from '../providers/MultiplayerProvider'
import getUserColor from '../utils/getUserColor'

type UserPresenceSize = 'small' | 'medium'

interface UserPresenceProps {
  user: ConnectedUser
  isDetailed?: boolean
  isCurrentUser?: boolean
  isFollowing?: boolean
  enablePopover?: boolean
  details?: string
  size?: UserPresenceSize
}

export default function UserPresence(props: UserPresenceProps): React.ReactNode {
  const {
    user,
    isDetailed = false,
    isCurrentUser = false,
    enablePopover = true,
    isFollowing = false,
    size = 'medium',
  } = props

  const [hover, setHover] = useState(false)
  const color = getUserColor(user.clientName)

  const {refs, floatingStyles} = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [offset(3), flip({fallbackAxisSideDirection: 'start'}), shift()],
  })

  const sizeMap = {
    small: 'h-6 w-6 text-xs',
    medium: 'h-8 w-8 text-sm',
  }

  return (
    <div
      className="flex items-center gap-2"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={refs.setReference}
      data-testid="user-presence"
    >
      <div
        style={{backgroundColor: color.fill, borderColor: color.border}}
        className={clsx(
          'flex items-center justify-center rounded-full border border-gray-200',
          isFollowing && 'ring-3 ring-slate-200',
          sizeMap[size],
        )}
      >
        {user.clientName[0].toUpperCase()}
        {isFollowing && !isDetailed && (
          <div
            style={{backgroundColor: color.fill}}
            className="absolute top-full left-1/2 mt-2 -translate-x-1/2 transform rounded-sm p-1 text-xs text-white"
          >
            Following
          </div>
        )}
      </div>
      {isDetailed && (
        <div className="justify flex flex-col items-start justify-center">
          {user.clientName}
          <div className="flex gap-2">
            {isCurrentUser && <p className="text-sm text-gray-500">You</p>}
            {isFollowing && (
              <div
                style={{backgroundColor: color.fill}}
                className="rounded-sm p-1 text-xs text-white"
              >
                Following
              </div>
            )}
          </div>
        </div>
      )}

      {hover && enablePopover && (
        <Portal>
          <div
            className="absolute rounded-lg bg-white p-2 shadow-lg"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <div className="justify flex items-center justify-center gap-2">
              <div
                style={{backgroundColor: color.fill, borderColor: color.border}}
                className={clsx(
                  'flex items-center justify-center rounded-full border border-gray-200',
                  isFollowing && 'ring-3 ring-slate-200',
                  sizeMap[size],
                )}
              >
                {user.clientName[0].toUpperCase()}
              </div>
              <div>
                {user.clientName}
                <div className="flex">
                  {isCurrentUser && <p className="text-sm text-gray-500">You</p>}
                  {isFollowing && (
                    <div
                      style={{backgroundColor: color.fill}}
                      className="rounded-sm p-1 text-xs text-white"
                    >
                      Following
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}
