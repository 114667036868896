import {ButtonLink, InputGroup} from '@kensho/neo'
import {debounce} from 'lodash-es'
import {use, useMemo} from 'react'

import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'

import {OptionsAction} from './types'

interface ActionBarProps {
  search: string
  optionsDispatch: React.Dispatch<OptionsAction>
}
export default function ActionBar(props: ActionBarProps): React.ReactNode {
  const {search, optionsDispatch} = props

  const analytics = use(SiteAnalyticsContext)

  const debouncedSendEvent = useMemo(
    () =>
      debounce(() => {
        analytics.sendEvent('search_transcripts')
      }, 3000),
    [analytics],
  )

  return (
    <div className="flex items-center justify-between">
      <div className="text-xl">My transcripts</div>
      <div className="flex gap-4">
        <div className="w-72">
          <InputGroup
            placeholder="Search transcript titles"
            size="medium"
            value={search}
            onChange={(e) => {
              optionsDispatch({type: 'search', payload: e.target.value})
              debouncedSendEvent()
            }}
            icon="MagnifyingGlassIcon"
          />
        </div>
        <ButtonLink
          intent="primary"
          icon="CloudArrowUpIcon"
          size="medium"
          href="/transcriptions/new/batch"
        >
          Upload File
        </ButtonLink>
        <ButtonLink icon="MicrophoneIcon" size="medium" href="/transcriptions/new/realtime">
          Record
        </ButtonLink>
      </div>
    </div>
  )
}
