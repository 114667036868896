import {Button} from '@kensho/neo'
import {useCallback, use, useEffect, useState} from 'react'

import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import copyText from '../../../utils/copyText'
import {prettyText} from '../../../utils/transcriptUtils'

/** Copy prettified transcript text to user's clipboard */
export default function CopyToClipboardButton(): React.ReactNode {
  const {transcript} = use(TranscriptContext)

  const analytics = use(SiteAnalyticsContext)

  const [copied, setCopied] = useState(false)

  const copyTranscript = useCallback((): void => {
    copyText(prettyText(transcript))
  }, [transcript])

  useEffect(() => {
    if (!copied) return undefined

    const timeout = setTimeout(() => {
      setCopied(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Button
      onClick={() => {
        analytics.sendEvent('copy_transcript_to_clipboard')
        copyTranscript()
        setCopied(true)
      }}
      icon={copied ? 'CheckCircleIcon' : 'DocumentDuplicateIcon'}
      minimal
      intent="primary"
      aria-label="Copy to clipboard"
    />
  )
}
