import {Button, ButtonLink, ProgressBar} from '@kensho/neo'
import {AnimatePresence, motion} from 'framer-motion'
import {uniqueId} from 'lodash-es'
import {use, useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router'

import useUploadBatch from '../../../api/useUploadBatch'
import ErrorDialog from '../../../components/ErrorDialog'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import {
  BatchMachineTranscriptionConfiguration,
  ScribeError,
  TranscriptionConfiguration,
} from '../../../types/types'

import {FormState} from './FormState'
import MediaFileUpload from './MediaFileUpload'
import TranscriptName from './TranscriptName'

interface BatchConfigFormProps {
  mediaFile?: File
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptionConfiguration: BatchMachineTranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
}

export default function BatchConfigForm(props: BatchConfigFormProps): React.ReactNode {
  const {mediaFile, setMediaFile, transcriptionConfiguration, setTranscriptionConfiguration} = props

  const defaultHotwords =
    'hotwords' in transcriptionConfiguration ? transcriptionConfiguration.hotwords : []

  const [formState, setFormState] = useState<FormState>({
    transcriptName:
      transcriptionConfiguration.name ||
      new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'short'}).format(new Date()),
    hotwords: new Set<string>(defaultHotwords),
    errors: {},
  })

  const [scribeError, setScribeError] = useState<ScribeError>()
  const [uploadBatchStatus, uploadBatch, cancelUploadBatch] = useUploadBatch()
  const uploadRequestID = useRef<string>(undefined)
  const navigate = useNavigate()

  const analytics = use(SiteAnalyticsContext)

  const startTranscription = useCallback((): void => {
    if (!mediaFile) return
    if (Object.values(formState.errors).some((error) => error)) return
    const newTranscriptionConfiguration = {
      ...transcriptionConfiguration,
      name: formState.transcriptName,
      hotwords: formState.hotwords,
    }

    setTranscriptionConfiguration(newTranscriptionConfiguration)

    const requestId = uniqueId('batch-upload')
    uploadRequestID.current = requestId

    uploadBatch({file: mediaFile, options: newTranscriptionConfiguration})
      .then((id: string) => {
        if (requestId !== uploadRequestID.current) return // noop
        navigate(`/transcriptions/${id}`)
      })
      .catch((e: DOMException | ScribeError) => {
        if (requestId !== uploadRequestID.current) return // noop

        if (e instanceof DOMException && e.name === 'AbortError') return // noop
        setScribeError(e instanceof DOMException ? {type: 'unknown'} : e)
      })
      .finally(() => {
        if (requestId === uploadRequestID.current) {
          uploadRequestID.current = undefined
        }
      })
  }, [
    mediaFile,
    uploadBatch,
    transcriptionConfiguration,
    formState,
    setTranscriptionConfiguration,
    navigate,
  ])

  useEffect(
    () => () => {
      uploadRequestID.current = undefined
    },
    [],
  )

  return (
    <>
      <ErrorDialog
        isOpen={!!scribeError}
        error={scribeError}
        onClose={() => setScribeError(undefined)}
      />
      <AnimatePresence mode="wait">
        {uploadBatchStatus === 'pending' ? (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="uploadingStatus"
          >
            <div className="mt-10">
              <div className="mt-2 mb-3 text-lg">Uploading…</div>
              <ProgressBar />
              <div className="mt-2 mb-4 text-sm text-gray-500">
                This may take several minutes depending on file size and connection speed.
              </div>
              <Button
                onClick={() => {
                  uploadRequestID.current = undefined
                  analytics.sendEvent('cancel_batch_upload')
                  cancelUploadBatch()
                }}
              >
                Cancel
              </Button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="selectBatchFile"
          >
            <div className="flex flex-col gap-10">
              <div className="text-lg font-semibold">Start a new transcription</div>
              <MediaFileUpload
                file={mediaFile}
                isInvalid={!!formState.errors?.file}
                errorText={formState.errors?.file}
                onChange={(file, validation) => {
                  setMediaFile(file || undefined)
                  setFormState((prev) => ({
                    ...prev,
                    transcriptName: file?.name || prev.transcriptName,
                    errors: {...prev.errors, file: validation},
                  }))
                }}
              />
              <TranscriptName
                error={formState.errors.transcriptName}
                setFormState={setFormState}
                transcriptName={formState.transcriptName}
              />
            </div>

            <div className="mt-10 flex flex-col gap-10">
              <div className="flex justify-end gap-4">
                <ButtonLink href="/transcriptions">Cancel</ButtonLink>
                <Button
                  disabled={Object.values(formState.errors).some((error) => error) || !mediaFile}
                  intent="primary"
                  onClick={() => {
                    analytics.sendEvent('transcribe_batch')
                    startTranscription()
                  }}
                >
                  Transcribe
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
