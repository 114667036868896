import {useLogger} from '@kensho/lumberjack'
import {Button, HeadlessButton, Popover, Icon, InputGroup} from '@kensho/neo'
import clsx from 'clsx'
import {DebouncedFunc, debounce, sortBy} from 'lodash-es'
import {useCallback, use, useEffect, useMemo, useRef, useState} from 'react'

import TranscriptContext from '../../../providers/TranscriptContext'
import {APITranscriptSlice, SpeakerInfo} from '../../../types/types'
import getSpeakerColor from '../../../utils/getSpeakerColor'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import StopInputPropagation from './StopInputPropagation'
import {useDispatchEditorAction} from './DispatchEditorActionProvider'

interface SpeakerLabelProps {
  slice: APITranscriptSlice
  sliceIndex: number
  speakerId: number
}

/**
 * Will match if all the characters of matchText are included in text in the correct order,
 * even if the characters are discontiguous
 *
 * If a match, returns a score based on how well it matched, a lower score is better
 * If not a match, returns -1
 *
 * @example orderedMatch('John Smith', 'j') // 0
 * @example orderedMatch('John Smith', 'js') // 1
 * @example orderedMatch('John Smith', 'smith') // 5
 * @example orderedMatch('John Smith', 'jim') // -1
 */
function orderedMatch(text: string, matchText: string): number {
  if (matchText.length > text.length) return -1
  if (!matchText.trim()) return -1

  /* eslint-disable no-param-reassign */
  text = text.toLowerCase()
  matchText = matchText.toLowerCase()
  /* eslint-enable no-param-reassign */

  let firstMatchingCharIndex = -1
  let largestSegmentLength = 0
  let currentSegmentLength = 0
  let textIndex = 0
  let matchTextIndex = 0

  for (matchTextIndex; matchTextIndex < matchText.length; matchTextIndex += 1) {
    currentSegmentLength += 1

    // continue consuming text characters until we find a match
    while (matchText[matchTextIndex] !== text[textIndex]) {
      largestSegmentLength = Math.max(largestSegmentLength, currentSegmentLength)
      currentSegmentLength = 0
      textIndex += 1
      // ran out of text to consume
      if (textIndex >= text.length) return -1
    }

    if (firstMatchingCharIndex === -1) firstMatchingCharIndex = textIndex
    largestSegmentLength = Math.max(largestSegmentLength, currentSegmentLength)
  }

  return (
    matchText.length -
    largestSegmentLength + // prefer matches that are more contiguous
    firstMatchingCharIndex + // prefer matches that start earlier in the string
    (largestSegmentLength === matchText.length ? 0 : matchText.length) // heavily prefer matches that consume the entire matchText contiguously
  )
}

export default function SpeakerLabel(props: SpeakerLabelProps): React.ReactNode {
  const {slice, sliceIndex, speakerId} = props
  const {dispatchEditorAction} = useDispatchEditorAction()
  const {transcript} = use(TranscriptContext)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const [focusedIndex, setFocusedIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isAddingSpeaker, setIsAddingSpeaker] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)
  const [filter, setFilter] = useState('')
  const clearFilterRef = useRef<DebouncedFunc<() => void>>(undefined)
  const log = useLogger()

  const [draftSpeakerName, setDraftSpeakerName] = useState('')

  const changeSpeaker = useCallback(
    (nextSpeakerId: number) => {
      if (!transcriptPermissions.edit) return

      const editorAction = produceEditorAction({
        action: {type: 'change-slice-speaker', sliceIndex, speakerId: nextSpeakerId},
        transcript,
        transcriptSelection: null,
        log,
      })
      if (!editorAction) return

      dispatchEditorAction(editorAction)
    },
    [transcript, transcriptPermissions, dispatchEditorAction, sliceIndex, log],
  )

  const addSpeaker = useCallback(() => {
    if (!transcriptPermissions.edit) return
    if (!draftSpeakerName.trim()) return

    const editorAction = produceEditorAction({
      action: {
        type: 'change-slice-speaker',
        data: {name: draftSpeakerName.trim()},
        speakerId: null,
        sliceIndex,
      },
      transcript,
      transcriptSelection: null,
      log,
    })
    if (!editorAction) return
    dispatchEditorAction(editorAction)

    setIsAddingSpeaker(false)
    setDraftSpeakerName('')
  }, [transcript, transcriptPermissions, draftSpeakerName, dispatchEditorAction, sliceIndex, log])

  const speakers = useMemo<[id: number, speakerInfo: SpeakerInfo][]>(() => {
    if (!transcript.speakers) return []
    return sortBy(Object.entries(transcript.speakers), ([, speakerInfo]) => speakerInfo.name)
      .filter(([id]) => Number(id) !== -1)
      .map(([id, speakerInfo]) => [Number(id), speakerInfo])
  }, [transcript.speakers])

  // after the user stops typing, clear the accumulated text filter
  const clearFilter = useMemo(() => {
    const debouncedFunc = debounce(() => setFilter(''), 2000)

    clearFilterRef.current = debouncedFunc

    return debouncedFunc
  }, [])

  useEffect(
    () => () => {
      clearFilterRef.current?.cancel()
    },
    [],
  )

  // speaker list might have overflow, so keep focused index in view
  useEffect(() => {
    listRef.current?.children[focusedIndex]?.scrollIntoView({block: 'nearest'})
  }, [focusedIndex])

  useEffect(() => {
    if (isOpen) {
      setFocusedIndex(0)
    } else {
      setIsAddingSpeaker(false)
      setDraftSpeakerName('')
    }
  }, [isOpen])

  return (
    <Popover
      isOpen={isOpen}
      content={
        <StopInputPropagation>
          <div
            className="w-80"
            onKeyDown={(event) => {
              if (!isOpen) return

              if (event.key === 'Enter') {
                if (!transcriptPermissions.edit) return
                changeSpeaker(speakers[focusedIndex][0])
              } else if (event.key === 'Escape') {
                setIsOpen(false)
              } else if (event.key === 'ArrowDown' && !isAddingSpeaker) {
                const nextFocusedIndex = Math.min(speakers.length - 1, focusedIndex + 1)
                setFocusedIndex(nextFocusedIndex)
              } else if (event.key === 'ArrowUp' && !isAddingSpeaker) {
                const nextFocusedIndex = Math.max(0, focusedIndex - 1)
                setFocusedIndex(nextFocusedIndex)
              } else if (event.key === ' ') {
                // don't allow default of closing the popover, since they may be filtering speakers
                event.preventDefault()
              } else if (/[a-zA-Z0-9-.]/.test(event.key) || event.key === ' ') {
                const nextFilter = filter + event.key
                setFilter(nextFilter)
                clearFilter()
                const matches = sortBy(
                  speakers
                    .map(([, speaker], i) => [i, orderedMatch(speaker.name, nextFilter)])
                    .filter(([, matchScore]) => matchScore !== -1),
                  ([, matchScore]) => matchScore,
                )

                if (matches.length) {
                  setFocusedIndex(matches[0][0])
                }
              }
            }}
          >
            <ul ref={listRef} className="max-h-72 overflow-auto">
              {speakers.map(([id, speaker], i) => (
                <li
                  key={id}
                  className={clsx(
                    'border-l-4 hover:bg-black/[0.03]',
                    focusedIndex === i ? 'border-cyan-500 bg-black/[0.03]' : 'border-transparent',
                  )}
                >
                  <button
                    className="focus:ouline-none flex h-10 w-full cursor-pointer items-center gap-2 overflow-hidden pl-4 focus-visible:outline-none"
                    type="button"
                    title={speaker.name}
                    onKeyDown={(event) => {
                      if (!transcriptPermissions.edit) return

                      if (event.key === 'Enter') {
                        changeSpeaker(id)
                        setIsOpen(false)
                      }
                    }}
                    onClick={() => {
                      if (!transcriptPermissions.edit) return

                      changeSpeaker(id)
                      setIsOpen(false)
                    }}
                  >
                    <div
                      className={clsx(
                        'flex items-center justify-center',
                        !isAddingSpeaker && speakerId === id ? 'opacity-100' : 'opacity-0',
                      )}
                    >
                      <Icon icon="CheckIcon" size="small" />
                    </div>
                    <div
                      className="h-3 w-3 flex-none rounded-full"
                      style={{backgroundColor: getSpeakerColor(id)}}
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {speaker.name}
                    </span>
                  </button>
                </li>
              ))}
            </ul>

            {isAddingSpeaker && (
              <div className="flex h-10 w-full items-center gap-2 border-l-4 border-transparent pl-4">
                <div className="flex items-center justify-center opacity-100">
                  <Icon icon="CheckIcon" size="small" />
                </div>
                <div className="h-3 w-3 flex-none rounded-full bg-slate-600" />
                <div className="mr-4 flex-1">
                  <InputGroup
                    autoFocus
                    value={draftSpeakerName}
                    onChange={(event) => setDraftSpeakerName(event.target.value)}
                    onKeyDown={(event) => {
                      event.stopPropagation()
                      if (event.key === 'Enter') {
                        if (draftSpeakerName.trim() === '') return
                        if (!transcriptPermissions.edit) return
                        addSpeaker()
                        setIsOpen(false)
                      } else if (event.key === 'Escape') {
                        setIsAddingSpeaker(false)
                        setDraftSpeakerName('')
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {transcriptPermissions.edit && (
              <div className="mt-3 border-t border-gray-300" data-testid="speaker-label-popover">
                <Button
                  icon="PlusCircleIcon"
                  minimal
                  onClick={() => {
                    setDraftSpeakerName('')
                    setIsAddingSpeaker(true)
                    setFocusedIndex(-1)
                  }}
                >
                  Add a new speaker
                </Button>
              </div>
            )}
          </div>
        </StopInputPropagation>
      }
      onOpenChange={setIsOpen}
    >
      <HeadlessButton
        type="button"
        className="mr-2 min-w-5 cursor-pointer overflow-hidden rounded-md bg-black/0 pr-1 text-left font-semibold text-ellipsis whitespace-nowrap ring-1 ring-transparent outline outline-2 outline-offset-2 outline-transparent ring-inset select-none focus-visible:z-10 focus-visible:outline-cyan-600 enabled:hover:bg-black/[0.03] enabled:active:bg-black/[0.06] disabled:cursor-default disabled:text-gray-400 disabled:ring-transparent"
        aria-label={transcript.speakers[speakerId].name}
      >
        <span data-testid="transcript-speaker" style={{color: getSpeakerColor(slice.speakerId)}}>
          {transcript.speakers[speakerId].name}
        </span>
      </HeadlessButton>
    </Popover>
  )
}
