import {Mode, TranscriptionConfiguration} from '../../../../types/types'
import NewTranscriptMenu from '../../NewTranscriptMenu'
import DownloadTranscriptButton from '../../actions/DownloadTranscriptButton'
import DownloadMediaFileButton from '../../actions/DownloadMediaFileButton'

interface TranscriptRightSidebarProps {
  mode: Mode
  setTranscriptDownloaded: React.Dispatch<React.SetStateAction<boolean>>
  transcriptionConfiguration: TranscriptionConfiguration
  showUnsavedTranscriptWarning: boolean
  media?: File
  transcriptId: string
  reset: () => void
}

export default function TranscriptRightSidebar(
  props: TranscriptRightSidebarProps,
): React.ReactNode {
  const {
    mode,
    transcriptionConfiguration,
    setTranscriptDownloaded,
    showUnsavedTranscriptWarning,
    transcriptId,
    media,
    reset,
  } = props

  return (
    <aside className="flex w-44 flex-col gap-2">
      {/* Download Transcript */}
      <DownloadTranscriptButton
        mode={mode}
        transcriptionConfiguration={transcriptionConfiguration}
        onDownload={() => setTranscriptDownloaded(true)}
      />
      {/* New Transcription */}
      <NewTranscriptMenu
        setTranscriptDownloaded={setTranscriptDownloaded}
        showUnsavedTranscriptWarning={showUnsavedTranscriptWarning}
        reset={reset}
      />
      {/* Download Media */}
      <DownloadMediaFileButton mediaFile={media} name={transcriptId} />
    </aside>
  )
}
