import {Button, Icon, Spinner, Tooltip} from '@kensho/neo'
import {debounce} from 'lodash-es'
import {useCallback, use, useEffect, useMemo, useRef, useState} from 'react'

import {UpdateTranscriptSelectionType} from '../../../../hooks/useTranscriptSelection'
import SpellCheckContext from '../../../../providers/SpellCheckContext'
import TranscriptContext from '../../../../providers/TranscriptContext'
import {APITranscript, TranscriptSelection} from '../../../../types/types'
import {DICTIONARIES} from '../../../account/dictionaries'
import {TranscriptEditAction} from '../../../../utils/transcriptRevisionUtils'

import ReviewSpelling from './ReviewSpelling'

interface Props {
  onEditOperationError: (error: Error, action: TranscriptEditAction) => void
  onClose: () => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  scrollTranscriptToTime: (timeMs: number) => Promise<void>
  items: ReviewItem[]
  setItems: React.Dispatch<React.SetStateAction<ReviewItem[]>>
}

interface SpellingReviewItem {
  type: 'spelling'
  transcriptSelection: TranscriptSelection | null
}

export type ReviewItem = SpellingReviewItem

function Reviewer(props: Props): React.ReactNode {
  const {
    onEditOperationError,
    updateTranscriptSelection,
    scrollTranscriptToTime,
    onClose,
    items,
    setItems,
  } = props
  const {transcript} = use(TranscriptContext)
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const spellCheck = use(SpellCheckContext)
  const [hasChecked, setHasChecked] = useState(false)
  const dictionaryResource = DICTIONARIES.find((d) => d.langCode === spellCheck.langCode)

  // skip pending scroll invocations when the component unmounts or when another scroll is triggered
  // before the first finishes
  const pendingSelectRef = useRef<string>(undefined)
  useEffect(
    () => () => {
      pendingSelectRef.current = undefined
    },
    [],
  )

  const scrollThenSelect = useCallback(
    (item?: ReviewItem): void => {
      if (!item?.transcriptSelection?.start) return

      const token =
        transcript.sliceMeta[item.transcriptSelection.start.sliceIndex].tokenMeta[
          item.transcriptSelection.start.tokenIndex
        ]

      if (!token) return

      const scrollId = `${Math.random()}`
      pendingSelectRef.current = scrollId

      scrollTranscriptToTime(token.startMs).then(() => {
        if (pendingSelectRef.current !== scrollId) return

        window.setTimeout(() => {
          if (pendingSelectRef.current !== scrollId) return
          updateTranscriptSelection(item.transcriptSelection, false, true)
        }, 500)
      })
    },
    [transcript, scrollTranscriptToTime, updateTranscriptSelection],
  )

  const onSelectItem = useCallback(
    (index: number) => {
      scrollThenSelect(items[index])
    },
    [items, scrollThenSelect],
  )

  const onCompleteItem = useCallback(() => {
    scrollThenSelect(currentItemIndex + 1 >= items.length ? items[0] : items[currentItemIndex + 1])

    setItems((prevItems) => {
      const nextItems = [...prevItems]
      nextItems.splice(currentItemIndex, 1)
      return nextItems
    })
    setCurrentItemIndex(currentItemIndex + 1 >= items.length ? 0 : currentItemIndex)
  }, [currentItemIndex, items, scrollThenSelect, setItems])

  const reviewTranscript = useCallback(
    (transcriptToCheck: APITranscript): void => {
      const nextItems: ReviewItem[] = []

      // find all tokens in transcript with spelling errors
      for (let i = 0; i < transcriptToCheck.sliceMeta.length; i += 1) {
        const slice = transcriptToCheck.sliceMeta[i]
        for (let j = 0; j < slice.tokenMeta.length; j += 1) {
          const token = slice.tokenMeta[j]
          if (!spellCheck.check(token.transcript)) {
            nextItems.push({
              type: 'spelling',
              transcriptSelection: {
                type: 'Range',
                start: {
                  type: 'token',
                  sliceIndex: i,
                  tokenIndex: j,
                  textOffset: 0,
                },
                end: {
                  type: 'token',
                  sliceIndex: i,
                  tokenIndex: j,
                  textOffset: token.transcript.length,
                },
              },
            })
          }
        }
      }

      setItems(nextItems)
      setHasChecked(true)
    },
    [spellCheck, setItems],
  )

  const reviewTranscriptDebounced = useMemo(
    () => debounce(reviewTranscript, 3000),
    [reviewTranscript],
  )

  // periodically review the transcript for mistakes and build a list of items to review
  useEffect(() => {
    if (hasChecked) {
      reviewTranscriptDebounced(transcript)
    } else if (!hasChecked) {
      reviewTranscript(transcript)
    }
  }, [transcript, reviewTranscript, reviewTranscriptDebounced, hasChecked])

  // ensure currentItemIndex is within bounds and reset to 0 if not
  // this can happen when edits outside the reviewer resolve items
  useEffect(() => {
    if (currentItemIndex >= items.length) setCurrentItemIndex(0)
  }, [items, currentItemIndex])

  return (
    <div className="h-full w-full">
      {items.length === 0 && (
        <div className="flex h-full flex-col">
          <div className="mt-1.5 flex justify-end">
            <Button minimal icon="XMarkIcon" onClick={onClose} />
          </div>
          <div className="flex grow items-center justify-center pb-8">
            {hasChecked ? (
              <>
                No pending items to review{' '}
                <span className="text-green-600">
                  <Icon icon="CheckIcon" />
                </span>
              </>
            ) : (
              <>
                Checking transcript
                <span className="ml-2">
                  <Spinner />
                </span>
              </>
            )}
          </div>
        </div>
      )}
      {!!items.length && (
        <>
          <header className="flex h-12 items-center justify-between border-b border-gray-200 p-4">
            <h2>Spelling ({dictionaryResource?.label} dictionary)</h2>
            <div className="flex">
              <Tooltip content="Previous">
                <Button
                  disabled={items.length === 1}
                  minimal
                  icon="ChevronLeftIcon"
                  onClick={() => {
                    const nextItemIndex =
                      currentItemIndex - 1 < 0 ? items.length - 1 : currentItemIndex - 1
                    setCurrentItemIndex(nextItemIndex)
                    onSelectItem(nextItemIndex)
                  }}
                />
              </Tooltip>

              <Tooltip content="Next">
                <Button
                  disabled={items.length === 1}
                  minimal
                  icon="ChevronRightIcon"
                  onClick={() => {
                    const nextItemIndex =
                      currentItemIndex + 1 > items.length - 1 ? 0 : currentItemIndex + 1
                    setCurrentItemIndex(nextItemIndex)
                    onSelectItem(nextItemIndex)
                  }}
                />
              </Tooltip>
            </div>
            <Button minimal icon="XMarkIcon" onClick={onClose} />
          </header>
          <div>
            {items[currentItemIndex] && items[currentItemIndex].type === 'spelling' && (
              <ReviewSpelling
                transcriptSelection={items[currentItemIndex].transcriptSelection}
                onEditOperationError={onEditOperationError}
                onComplete={() => onCompleteItem()}
              />
            )}
          </div>
          <div className="pointer-events-none relative bottom-[50px] pl-5 opacity-50">
            {!!items.length && <span>{`${currentItemIndex + 1} out of ${items.length}`}</span>}
          </div>
        </>
      )}
    </div>
  )
}

export default Reviewer
