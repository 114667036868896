import {useCallback, useMemo, useState} from 'react'
import {Outlet} from 'react-router'

import {TranscriptionConfiguration, TranscriptionOutletContext} from '../types/types'

export default function TranscriptionLayoutPage(): React.ReactNode {
  const [mediaFile, setMediaFile] = useState<File | undefined>()
  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>()
  const [transcriptionConfiguration, setTranscriptionConfiguration] =
    useState<TranscriptionConfiguration>({})

  const reset = useCallback(() => {
    setMediaFile(undefined)
    setAudioInputDeviceId(undefined)
    setTranscriptionConfiguration({})
  }, [])

  const context = useMemo<TranscriptionOutletContext>(
    () => ({
      mediaFile,
      setMediaFile,
      audioInputDeviceId,
      setAudioInputDeviceId,
      transcriptionConfiguration,
      setTranscriptionConfiguration,
      reset,
    }),
    [
      mediaFile,
      setMediaFile,
      audioInputDeviceId,
      setAudioInputDeviceId,
      transcriptionConfiguration,
      setTranscriptionConfiguration,
      reset,
    ],
  )

  return <Outlet context={context} />
}
