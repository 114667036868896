import {createContext} from 'react'

import {APITranscript, EditorAction} from '../types/types'

export type TranscriptAction =
  | {type: 'revision'; editorAction: EditorAction}
  | {type: 'undo'; editorAction: EditorAction}
  | {type: 'redo'; editorAction: EditorAction}

export interface TranscriptState {
  transcript: APITranscript
  undos: EditorAction[]
  redos: EditorAction[]
}

export type TranscriptContextValue = TranscriptState & {dispatch: React.Dispatch<TranscriptAction>}

export default createContext<TranscriptContextValue>({
  dispatch: () =>
    /* eslint-disable-next-line no-console */
    console.warn('You must wrap this component in TranscriptProvider before using this method.'),
  undos: [],
  redos: [],
  transcript: {} as APITranscript,
})
