import {Button} from '@kensho/neo'
import {useNavigate} from 'react-router'

import {APITranscript, ScribeError} from '../../../../types/types'

import AudioQuality from './AudioQuality'
import SpeakerList from './speakers/SpeakerList'

interface TranscriptLeftSidebarProps {
  transcript: APITranscript
  showUnsavedTranscriptWarning: boolean
  setTranscriptDownloaded: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<ScribeError | undefined>>
}

export default function TranscriptLeftSidebar(props: TranscriptLeftSidebarProps): React.ReactNode {
  const {transcript, showUnsavedTranscriptWarning, setTranscriptDownloaded, setError} = props
  const navigate = useNavigate()
  return (
    <div className="mt-10 ml-2 flex flex-col gap-5">
      <div className="-ml-6">
        <Button
          minimal
          icon="ChevronLeftIcon"
          intent="primary"
          onClick={() => {
            if (showUnsavedTranscriptWarning) {
              setError({type: 'unsavedTranscript'})
              setTranscriptDownloaded(true)
            } else {
              navigate('/transcriptions')
            }
          }}
        >
          Back to my transcripts
        </Button>
      </div>

      <AudioQuality accuracy={transcript.accuracy} />
      <div>
        <h4 className="mb-2 text-sm text-gray-500">Total Word Count</h4>
        <p className="text-lg font-semibold" data-testid="word-count">
          {transcript.sliceMeta.reduce((total, slice) => total + slice.tokenMeta.length, 0)}
        </p>
      </div>
      <SpeakerList transcript={transcript} />
    </div>
  )
}
