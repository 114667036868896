import {useMemo} from 'react'
import {useDevicePixelRatio} from '@kensho/tacklebox'

import {AnnotationsLayerBlock} from './AnnotationsLayerBlock'
import {KHighlight, StyledSegments} from './types'

export default function AnnotationsLayer(props: {
  height: number
  width: number
  kHighlights: KHighlight[]
}): React.ReactNode {
  const {height, width, kHighlights} = props
  const pixelRatio = useDevicePixelRatio()

  const styledSegments = useMemo(() => {
    const nextStyledSegments: StyledSegments[] = []
    kHighlights.forEach(({range, style}) => {
      if (range.segments) {
        nextStyledSegments.push({segmentGroup: range.segments, style})
      }
    })
    return nextStyledSegments
  }, [kHighlights])

  const blocks = []
  const CANVAS_BLOCK_HEIGHT = 512
  for (let i = 0; i < height; i += CANVAS_BLOCK_HEIGHT) {
    const remainingPixels = height - i
    blocks.push(
      <AnnotationsLayerBlock
        key={i}
        styledSegments={styledSegments}
        height={Math.min(CANVAS_BLOCK_HEIGHT, remainingPixels)}
        pixelRatio={pixelRatio}
        width={width}
        x={0}
        y={i}
      />,
    )
  }
  return blocks
}
