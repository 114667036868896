import {Outlet, useNavigate} from 'react-router'
import {NeoProvider} from '@kensho/neo'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {LoggerProvider, transportConsole, transportSentry} from '@kensho/lumberjack'

import UserProvider from '../providers/UserProvider'
import CookieConsentPopup from '../components/CookieBanner/CookieConsentPopup'
import SiteAnalyticsProvider from '../providers/SiteAnalyticsProvider'
import queryClient from '../app/queryClient'

const transports = import.meta.env.PROD
  ? [transportSentry({levels: ['error', 'warning']})]
  : [transportConsole()]

export default function Root(): React.ReactNode {
  const navigate = useNavigate()
  return (
    <LoggerProvider transports={transports}>
      <QueryClientProvider client={queryClient}>
        <NeoProvider navigate={navigate}>
          <ReactQueryDevtools buttonPosition="bottom-left" />
          <SiteAnalyticsProvider>
            <CookieConsentPopup />
            <UserProvider>
              <Outlet />
            </UserProvider>
          </SiteAnalyticsProvider>
        </NeoProvider>
      </QueryClientProvider>
    </LoggerProvider>
  )
}
