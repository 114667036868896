import clsx from 'clsx'

export default function Ellipsis(): React.ReactNode {
  return (
    <div className="inline-block">
      <span
        className={clsx(
          'mr-1 inline-block size-1 animate-[bounce_1s_ease-in-out_infinite_1s] bg-brand-800',
        )}
      />
      <span
        className={clsx(
          'mr-1 inline-block size-1 animate-[bounce_1s_ease-in-out_infinite_0.3s] bg-brand-800',
        )}
      />
      <span
        className={clsx(
          'mr-1 inline-block size-1 animate-[bounce_1s_ease-in-out_infinite_0.5s] bg-brand-800',
        )}
      />
    </div>
  )
}
