import {useEffect, useState} from 'react'
import {useLogger} from '@kensho/lumberjack'

import closeMediaStream from '../../../utils/closeMediaStream'

type MicrophonePermissionState = {
  isPermissionGranted: boolean | null
  error: unknown | null
  status: 'idle' | 'pending' | 'success' | 'error'
}

type DeviceListState = {
  audioInputDevices: MediaDeviceInfo[] | null
  error: unknown | null
  status: 'idle' | 'pending' | 'success' | 'error'
}

export default function useAudioDevices(): {
  microphonePermissionQuery: MicrophonePermissionState
  audioInputDevicesQuery: DeviceListState
} {
  const log = useLogger()
  const [microphonePermissionQuery, setMicrophonePermission] = useState<MicrophonePermissionState>({
    isPermissionGranted: null,
    error: null,
    status: 'idle',
  })
  const [audioInputDevicesQuery, setAudioInputDevices] = useState<DeviceListState>({
    audioInputDevices: null,
    error: null,
    status: 'idle',
  })

  useEffect(() => {
    let current = true

    async function handlePermissionsAndDevices(): Promise<void> {
      setMicrophonePermission({isPermissionGranted: null, error: null, status: 'pending'})
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true})
        closeMediaStream(stream)
        if (!current) return
        setMicrophonePermission({isPermissionGranted: true, error: null, status: 'success'})
        setAudioInputDevices({audioInputDevices: null, error: null, status: 'pending'})
        const devices = await navigator.mediaDevices.enumerateDevices()
        if (!current) return
        const deviceList = devices.filter((device) => device.kind === 'audioinput')
        setAudioInputDevices({audioInputDevices: deviceList, error: null, status: 'success'})
      } catch (error) {
        if (!current) return
        setMicrophonePermission({isPermissionGranted: false, error, status: 'error'})
        setAudioInputDevices({audioInputDevices: null, error, status: 'error'})
        log.error(error as Error)
      }
    }

    handlePermissionsAndDevices()

    return () => {
      current = false
    }
  }, [log])

  return {
    microphonePermissionQuery,
    audioInputDevicesQuery,
  }
}
