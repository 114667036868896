import {useOutletContext, useParams} from 'react-router'

import BasePage from '../components/BasePage'
import {
  BatchMachineTranscriptionConfiguration,
  Mode,
  TranscriptionOutletContext,
} from '../types/types'
import BatchConfigForm from '../core/transcription/transcriptionConfig/BatchConfigForm'
import RealtimeConfigForm from '../core/transcription/transcriptionConfig/RealtimeConfigForm'

export default function TranscriptionConfigPage(): React.ReactNode {
  const {
    mediaFile,
    setMediaFile,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    audioInputDeviceId,
    setAudioInputDeviceId,
  } = useOutletContext<TranscriptionOutletContext>()
  const {mode = 'batch'} = useParams<{mode: Mode}>()

  return (
    <BasePage footer={false}>
      <div className="mx-auto mt-10 h-full w-[750px] max-w-full overflow-auto px-6 py-5">
        {mode === 'batch' && (
          <>
            <BatchConfigForm
              mediaFile={mediaFile}
              setMediaFile={setMediaFile}
              setTranscriptionConfiguration={setTranscriptionConfiguration}
              transcriptionConfiguration={
                transcriptionConfiguration as BatchMachineTranscriptionConfiguration
              }
            />
          </>
        )}
        {mode === 'realtime' && (
          <RealtimeConfigForm
            onAudioInputDeviceChange={setAudioInputDeviceId}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
            transcriptionConfiguration={transcriptionConfiguration}
            audioInputDeviceId={audioInputDeviceId}
          />
        )}
      </div>
    </BasePage>
  )
}
