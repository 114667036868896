import {useLogger} from '@kensho/lumberjack'
import {Button, Icon, Popover, Tooltip} from '@kensho/neo'
import {use, useState} from 'react'

import {APITranscript, SpeakerInfo} from '../../../../../types/types'
import getSpeakerColor from '../../../../../utils/getSpeakerColor'
import {produceEditorAction} from '../../../../../utils/transcriptRevisionUtils'
import {TranscriptPermissionsContext} from '../../../TranscriptPermissionsProvider'
import {useDispatchEditorAction} from '../../DispatchEditorActionProvider'

import SpeakerEditor from './SpeakerEditor'

interface Props {
  id: number
  speaker: SpeakerInfo
  transcript: APITranscript
  tagged?: boolean
}

export default function SpeakerItem(props: Props): React.ReactNode {
  const {id, speaker, transcript, tagged} = props
  const {dispatchEditorAction} = useDispatchEditorAction()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const log = useLogger()
  const {transcriptPermissions} = use(TranscriptPermissionsContext)

  const isUnknownSpeaker = id === -1

  const deleteButton = !tagged ? (
    <Tooltip content="Remove Speaker">
      <Button
        icon="TrashIcon"
        size="small"
        aria-label="Remove Speaker"
        minimal
        disabled={!transcriptPermissions.edit}
        onClick={() => {
          const editorAction = produceEditorAction({
            action: {type: 'delete-speaker', speakerId: id},
            transcript,
            transcriptSelection: null,
            log,
          })
          if (!editorAction) return

          dispatchEditorAction(editorAction)
        }}
      />
    </Tooltip>
  ) : (
    <></>
  )
  if (isUnknownSpeaker) {
    return (
      <li className="group mb-2 h-14 rounded-sm p-1 pl-2 transition-all hover:bg-slate-100">
        <div className="flex items-start">
          <div
            className="mt-1 mr-3 h-3 w-3 flex-none rounded-full"
            style={{backgroundColor: getSpeakerColor(id)}}
          />
          <div className="flex flex-auto items-center overflow-hidden">
            <div className="overflow-hidden text-nowrap text-ellipsis">{speaker.name}</div>
            {tagged && (
              <div className="ml-2">
                <Tooltip content="Scribe failed to detect certain speakers. Please go back to the transcript and verify them.">
                  <Icon icon="InformationCircleIcon" size="small" />
                </Tooltip>
              </div>
            )}
          </div>
          {transcriptPermissions.edit && (
            <div className="ml-1 flex items-center gap-1 opacity-0 transition-all group-hover:opacity-100">
              {deleteButton}
            </div>
          )}
        </div>
      </li>
    )
  }

  return (
    <Popover
      content={
        <SpeakerEditor
          id={id}
          speaker={speaker}
          transcript={transcript}
          onClose={() => setIsPopoverOpen(false)}
        />
      }
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      position="bottom-end"
    >
      <li
        className="group mb-2 h-14 rounded-sm p-1 pl-2 transition-all hover:bg-slate-100"
        data-clarity-mask="True"
        data-testid="sidebar-speaker"
      >
        <div className="flex items-start">
          <div
            className="mt-2 mr-3 h-3 w-3 flex-none rounded-full"
            style={{backgroundColor: getSpeakerColor(id)}}
          />
          <div className="flex-auto items-center overflow-hidden">
            <div className="overflow-hidden text-nowrap text-ellipsis">{speaker.name}</div>
            <div className="overflow-hidden text-nowrap text-ellipsis text-gray-500">
              {speaker.role || 'Unassigned role'}
            </div>
          </div>
          {transcriptPermissions.edit && (
            <div className="ml-1 flex items-center gap-1 opacity-0 transition-all group-hover:opacity-100">
              {deleteButton}
              <Button icon="PencilIcon" size="small" minimal aria-label="Edit speaker" />
            </div>
          )}
        </div>
      </li>
    </Popover>
  )
}
