import {useState} from 'react'
import {Icon, Switch} from '@kensho/neo'

interface CookieOptionProps {
  title: string
  expandedContent: React.ReactNode
  checked: boolean
  onChange?: (setCookie: boolean) => void
}

export default function CookieOption({
  title,
  expandedContent,
  checked,
  onChange,
}: CookieOptionProps): React.ReactNode {
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      <header className="flex items-center justify-between pr-2">
        <button
          className="flex items-center gap-3 p-3 text-sm font-semibold"
          type="button"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon icon={expanded ? 'MinusIcon' : 'PlusIcon'} />
          {title}
        </button>
        {onChange ? (
          <Switch
            aria-label={title}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
          />
        ) : (
          <p className="text-xs font-bold whitespace-nowrap text-brand-700">Always active</p>
        )}
      </header>

      {expanded && <div className="bg-gray-50 px-4 py-3 text-xs">{expandedContent}</div>}
    </div>
  )
}
