import clsx from 'clsx'
import {useCallback, useEffect, useRef, useState} from 'react'

import assertNever from '../utils/assertNever'

interface Props {
  children?: React.ReactNode
  initialWidth?: number
  initialHeight?: number
  initialTop?: number
  initialLeft?: number
  minHeight?: number
  minWidth?: number
  className?: string
}

type ResizeDirection =
  | 'north'
  | 'northeast'
  | 'east'
  | 'southeast'
  | 'south'
  | 'southwest'
  | 'west'
  | 'northwest'

/**
 * Wraps its children in a floating container that can be resized and dragged
 *
 * The container is positioned absolutely and resizing is anchored from the opposite corner/edge.
 * So for example, if you resize the north edge, the height AND the top will change so that
 * the bottom edge of the container stays in the same place
 */
export default function FloatingContainer(props: Props): React.ReactNode {
  const {
    className,
    children,
    initialWidth = 0,
    initialHeight = 0,
    initialTop = 0,
    initialLeft = 0,
    minHeight = 0,
    minWidth = 0,
  } = props
  const [width, setWidth] = useState(initialWidth)
  const [height, setHeight] = useState(initialHeight)
  const [top, setTop] = useState(initialTop)
  const [left, setLeft] = useState(initialLeft)
  const [isDragging, setIsDragging] = useState(false)
  const [resizeDirection, setResizeDirection] = useState<ResizeDirection | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const startingCursorOffsetRef = useRef({left: 0, top: 0})

  const onDragMouseDown = useCallback((event: React.MouseEvent): void => {
    if (!containerRef.current) return

    event.preventDefault()
    event.stopPropagation()

    // record the starting offset of the drag so that we can later calculate the new values
    // based on the mouse position during mousemove
    const clientRect = containerRef.current.getBoundingClientRect()
    startingCursorOffsetRef.current = {
      left: event.clientX - clientRect.x,
      top: event.clientY - clientRect.y,
    }

    setIsDragging(true)
  }, [])

  useEffect(() => {
    if (!isDragging) return undefined

    const onMouseMove = (event: MouseEvent): void => {
      if (!containerRef.current) return

      setLeft(event.clientX - startingCursorOffsetRef.current.left)
      setTop(event.clientY - startingCursorOffsetRef.current.top)
    }

    const onMouseUp = (event: MouseEvent): void => {
      event.preventDefault()
      event.stopPropagation()

      setIsDragging(false)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [isDragging])

  const onResizeMouseDown = useCallback(
    (event: React.MouseEvent, nextResizeDirection: ResizeDirection): void => {
      event.preventDefault()
      event.stopPropagation()

      setResizeDirection(nextResizeDirection)

      startingCursorOffsetRef.current = {left: event.clientX, top: event.clientY}
    },
    [],
  )

  useEffect(() => {
    if (!resizeDirection) return undefined

    const onMouseMove = (event: MouseEvent): void => {
      if (!containerRef.current) return

      const clientRect = containerRef.current.getBoundingClientRect()
      let nextHeight = clientRect.height
      let nextWidth = clientRect.width

      switch (resizeDirection) {
        case 'north':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)
          if (nextHeight !== clientRect.height) setTop(event.clientY)
          break
        case 'northeast':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)
          if (nextHeight !== clientRect.height) setTop(event.clientY)

          nextWidth = Math.max(
            minWidth,
            clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
          )
          setWidth(nextWidth)
          break
        case 'east':
          setWidth(
            Math.max(
              minWidth,
              clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
            ),
          )
          break
        case 'southeast':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )

          setWidth(
            Math.max(
              minWidth,
              clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
            ),
          )
          break
        case 'south':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )
          break
        case 'southwest':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )

          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          if (nextWidth !== clientRect.width) setLeft(event.clientX)
          break
        case 'west':
          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          if (nextWidth !== clientRect.width) setLeft(event.clientX)
          break
        case 'northwest':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)
          if (nextHeight !== clientRect.height) setTop(event.clientY)

          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          if (nextWidth !== clientRect.width) setLeft(event.clientX)
          break
        default:
          assertNever(resizeDirection)
      }
    }

    const onMouseUp = (event: MouseEvent): void => {
      event.preventDefault()
      event.stopPropagation()

      setResizeDirection(null)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [resizeDirection, minHeight, minWidth])

  return (
    <div
      ref={containerRef}
      className={clsx('absolute z-10 overflow-hidden', className)}
      style={{height, width, left, top}}
      onMouseDown={onDragMouseDown}
    >
      {children}

      {/* resize handles */}
      <div
        className="absolute top-0 right-0 left-0 h-1 cursor-n-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'north')}
      />
      <div
        className="absolute top-0 right-0 z-20 h-2 w-2 cursor-ne-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'northeast')}
      />
      <div
        className="absolute top-0 right-0 bottom-0 w-1 cursor-e-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'east')}
      />
      <div
        className="absolute right-0 bottom-0 z-20 h-2 w-2 cursor-se-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'southeast')}
      />
      <div
        className="absolute right-0 bottom-0 left-0 h-1 cursor-s-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'south')}
      />
      <div
        className="absolute bottom-0 left-0 z-20 h-2 w-2 cursor-sw-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'southwest')}
      />
      <div
        className="absolute top-0 bottom-0 left-0 w-1 cursor-w-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'west')}
      />
      <div
        className="absolute top-0 left-0 z-20 h-2 w-2 cursor-nw-resize"
        onMouseDown={(e) => onResizeMouseDown(e, 'northwest')}
      />
    </div>
  )
}
