import {useCallback, use} from 'react'
import {cloneDeep} from 'lodash-es'

import {API_ENDPOINT} from '../constants'
import {TranscriptMetadata} from '../types/types'
import parseError from '../utils/parseError'
import UserContext from '../providers/UserContext'

export default function useGetTranscriptMetadata(): (
  transcriptId: string,
  signal?: AbortSignal,
) => Promise<TranscriptMetadata> {
  const {user} = use(UserContext)

  const getTranscriptMetadata = useCallback(
    (transcriptId: string, signal?: AbortSignal): Promise<TranscriptMetadata> => {
      const url = `${API_ENDPOINT}/transcription/${transcriptId}/metadata`

      const init: RequestInit = cloneDeep({
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
        signal,
      })

      return fetch(url, init)
        .then((response) => {
          if (!response.ok) throw parseError(response.status)
          return response.json() as Promise<TranscriptMetadata>
        })
        .catch((error: Error | DOMException) => {
          const clientError = error instanceof DOMException ? {type: 'unknown'} : parseError(error)
          throw clientError
        })
    },
    [user],
  )

  return getTranscriptMetadata
}
