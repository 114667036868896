import {useLogger} from '@kensho/lumberjack'
import {Button, Icon, Tooltip} from '@kensho/neo'
import {useCallback, use, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {isEqual} from 'lodash-es'

import useAlignTokens from '../../../api/useAlignTokens'
import TranscriptContext from '../../../providers/TranscriptContext'
import {AsyncStatus, TranscriptSelection} from '../../../types/types'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {useDispatchEditorAction} from '../transcript/DispatchEditorActionProvider'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

interface Props {
  transcriptId: string
  transcriptSelection: TranscriptSelection | null
  updateTranscriptSelection: UpdateTranscriptSelectionType
}

export default function AlignSelectedButton(props: Props): React.ReactNode {
  const {transcriptSelection, updateTranscriptSelection, transcriptId} = props
  const {transcript} = use(TranscriptContext)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {dispatchEditorAction} = useDispatchEditorAction()
  const alignTokens = useAlignTokens()
  const log = useLogger()
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const transcriptSelectionRef = useRef<TranscriptSelection | null>(transcriptSelection)

  useEffect(() => {
    transcriptSelectionRef.current = transcriptSelection
  }, [transcriptSelection])

  // reset status back to idle after a short delay
  useEffect(() => {
    if (status !== 'success' && status !== 'error') return undefined
    const timeoutId = window.setTimeout(() => {
      setStatus('idle')
    }, 750)
    return () => {
      window.clearTimeout(timeoutId)
    }
  })

  const alignSelected = useCallback(async () => {
    if (
      !transcriptPermissions.edit ||
      transcriptSelection?.type !== 'Range' ||
      !transcriptSelection.start ||
      !transcriptSelection.end
    )
      return

    const prevTranscriptSelection = transcriptSelection
    setStatus('pending')
    try {
      const range = {
        startToken: `/slice_meta/${transcriptSelection.start.sliceIndex}/token_meta/${transcriptSelection.start.tokenIndex}`,
        endToken: `/slice_meta/${transcriptSelection.end.sliceIndex}/token_meta/${transcriptSelection.end.tokenIndex}`,
      }
      const response = await alignTokens(transcriptId, range)

      if (!response) {
        setStatus('idle')
        return
      }

      // update the transcript with the new alignmentResponse
      const editorAction = produceEditorAction({
        action: {
          type: 'align-tokens',
          ranges: [{range, tokens: response}],
        },
        transcript,
        transcriptSelection: null,
        log,
      })

      if (!editorAction) {
        setStatus('idle')
        return
      }

      editorAction.selectionChangeDisabled = true
      editorAction.undoable = false
      dispatchEditorAction(editorAction)

      setStatus('success')
    } catch (e) {
      log.error(e as Error)
      setStatus('error')
    } finally {
      // remove this after transcript highlights when we can preserve transcript selection state
      if (isEqual(prevTranscriptSelection, transcriptSelectionRef.current)) {
        updateTranscriptSelection(prevTranscriptSelection, false, true)
      }
    }
  }, [
    transcriptId,
    transcript,
    transcriptPermissions,
    transcriptSelection,
    updateTranscriptSelection,
    alignTokens,
    dispatchEditorAction,
    log,
  ])

  if (status === 'idle') {
    return (
      <Tooltip content="Align selected" position="bottom">
        <Button
          disabled={
            !transcript || !transcriptPermissions.edit || transcriptSelection?.type !== 'Range'
          }
          onClick={alignSelected}
          icon="ArrowsRightLeftIcon"
          minimal
          intent="primary"
          aria-label="Align selected"
        />
      </Tooltip>
    )
  }

  return (
    <div
      className={clsx(
        'flex items-center gap-1 pl-2 text-sm text-ellipsis whitespace-nowrap text-brand-700',
        status === 'error' && 'text-red-600',
      )}
    >
      {(() => {
        switch (status) {
          case 'success':
            return (
              <>
                <Icon icon="CheckCircleIcon" />
                Aligning done!
              </>
            )

          case 'error':
            return (
              <>
                <Icon icon="XCircleIcon" />
                Aligning failed
              </>
            )
          case 'pending':
          default:
            return (
              <>
                <div className="animate-spin">
                  <Icon icon="ArrowPathIcon" />
                </div>
                Aligning selected section…
              </>
            )
        }
      })()}
    </div>
  )
}
