import {ButtonLink} from '@kensho/neo'

export default function UnknownErrorPage(): React.ReactNode {
  return (
    <main className="grid h-screen place-items-center px-8 py-32">
      <div className="mb-32 text-center">
        <p className="text-base font-semibold text-brand-700">Unknown error</p>
        <h1 className="mt-4 text-6xl font-bold tracking-tight text-gray-900">The page crashed</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Please refresh and try again.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <ButtonLink href="/" intent="primary">
            Return home
          </ButtonLink>
        </div>
      </div>
    </main>
  )
}
