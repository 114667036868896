import {produce} from 'immer'
import {takeRight} from 'lodash-es'
import {useMemo, useReducer} from 'react'

import {APITranscript} from '../types/types'
import applyRevision from '../utils/applyRevision'

import TranscriptContext, {
  TranscriptAction,
  TranscriptContextValue,
  TranscriptState,
} from './TranscriptContext'

function reducer(state: TranscriptState, action: TranscriptAction): TranscriptState {
  switch (action.type) {
    case 'revision':
      return {
        ...state,
        transcript: produce(state.transcript, (draftTranscript) =>
          applyRevision(action.editorAction.revision, draftTranscript),
        ),
        ...(action.editorAction.undoable || action.editorAction.undoable === undefined
          ? {
              undos: takeRight([...state.undos, action.editorAction], 50),
              redos: [],
            }
          : {}),
      }
    case 'undo':
      return {
        ...state,
        transcript: produce(state.transcript, (draftTranscript) =>
          applyRevision(action.editorAction.inverseRevision, draftTranscript),
        ),
        redos: takeRight([...state.redos, action.editorAction], 50),
        undos: state.undos.slice(0, -1),
      }
    case 'redo':
      return {
        ...state,
        transcript: produce(state.transcript, (draftTranscript) =>
          applyRevision(action.editorAction.revision, draftTranscript),
        ),
        undos: takeRight([...state.undos, action.editorAction], 50),
        redos: state.redos.slice(0, -1),
      }
    default:
      return state
  }
}

export default function TranscriptProvider(props: {
  children: React.ReactNode
  initialTranscript: APITranscript
}): React.ReactNode {
  const {children, initialTranscript} = props
  const [state, dispatch] = useReducer(reducer, {
    transcript: initialTranscript,
    undos: [],
    redos: [],
  })

  const value = useMemo<TranscriptContextValue>(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  )

  return <TranscriptContext value={value}>{children}</TranscriptContext>
}
