import {useState, useMemo, Dispatch, SetStateAction, useCallback} from 'react'

import {APITranscript, APITranscriptToken} from '../../types/types'
import {
  HighlightClickHandler,
  KHighlight,
  NeedsReviewHighlight,
  NonNullTranscriptSelection,
} from '../../highlights/types'
import {getTextFromTranscriptSelection} from '../../utils/transcriptUtils'

export function useNeedsReviewHighlights(transcript: APITranscript): {
  needsReviewHighlights: NeedsReviewHighlight[]
  needsReviewState: {
    activeHighlightId: string | undefined
    setActiveHighlightId: Dispatch<SetStateAction<string | undefined>>
  }
  needsReviewClickHandler: HighlightClickHandler
} {
  const [activeHighlightId, setActiveHighlightId] = useState<string>()
  const needsReviewHighlights = useMemo(() => {
    if (!transcript.annotations) return []
    const tokensByAnnotation = new Map<
      string,
      {sliceIndex: number; tokenIndex: number; token: APITranscriptToken}[]
    >()

    transcript.sliceMeta.forEach((slice, sliceIndex) => {
      slice.tokenMeta.forEach((token, tokenIndex) => {
        token.annotations?.forEach((annotationReference) => {
          const annotation =
            transcript.annotations && transcript.annotations[annotationReference.id]
          if (!annotation || annotation.label !== 'needs-review-highlight') return
          if (!tokensByAnnotation.has(annotationReference.id)) {
            tokensByAnnotation.set(annotationReference.id, [])
          }
          tokensByAnnotation.get(annotationReference.id)?.push({sliceIndex, tokenIndex, token})
        })
      })
    })

    const highlights: NeedsReviewHighlight[] = []
    Object.values(transcript.annotations).forEach((annotation) => {
      const tokens = tokensByAnnotation.get(annotation.id)
      if (tokens && tokens.length > 0) {
        const startToken = tokens[0]
        const endToken = tokens[tokens.length - 1]
        const range: NonNullTranscriptSelection = {
          start: {
            sliceIndex: startToken.sliceIndex,
            textOffset: 0,
            tokenIndex: startToken.tokenIndex,
            type: 'token',
          },
          end: {
            sliceIndex: endToken.sliceIndex,
            tokenIndex: endToken.tokenIndex,
            textOffset: endToken.token.transcript.length,
            type: 'token',
          },
          type: 'Range',
        }

        const kh: NeedsReviewHighlight = {
          type: 'needs-review',
          style: {
            backgroundColor:
              activeHighlightId === annotation.id
                ? 'rgba(246, 200, 90, 1)'
                : 'rgba(246, 200, 90, 0.3)',
          },
          range,
          annotation,
          text: getTextFromTranscriptSelection(transcript, range),
        }
        highlights.push(kh)
      }
    })

    return highlights
  }, [transcript, activeHighlightId])

  const needsReviewClickHandler: HighlightClickHandler = useCallback(
    (clickedHighlights: KHighlight[]) => {
      if (clickedHighlights.length === 0) return
      const clickedNeedsReviewHighlights = clickedHighlights.filter(
        (x) => x.type === 'needs-review',
      )
      if (clickedNeedsReviewHighlights.length > 0) {
        const smallestClickedHighlight = clickedNeedsReviewHighlights
          .map((highlight) => ({
            highlight,
            length: highlight.text.length,
          }))
          .sort((a, b) => a.length - b.length)[0].highlight
        setActiveHighlightId(smallestClickedHighlight.annotation?.id)
      }
    },
    [],
  )

  return {
    needsReviewHighlights,
    needsReviewState: {activeHighlightId, setActiveHighlightId},
    needsReviewClickHandler,
  }
}
