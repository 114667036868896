import {useMemo, useReducer} from 'react'

import {Stage, TranscriptMetadata} from '../types/types'

import TranscriptionContext, {
  TranscriptionAction,
  TranscriptionContextValue,
  TranscriptionState,
} from './TranscriptionContext'

function reducer(state: TranscriptionState, action: TranscriptionAction): TranscriptionState {
  switch (action.type) {
    case 'saved':
      return {
        ...state,
        lastSaved: action.date,
      }
    case 'setStage': {
      return {...state, stage: action.stage}
    }
    default:
      return state
  }
}

export default function TranscriptionProvider(props: {
  children: React.ReactNode
  initialMetadata: TranscriptMetadata | undefined
  initialStage: Stage
}): React.ReactNode {
  const {children, initialMetadata, initialStage} = props
  const [state, dispatch] = useReducer(reducer, {
    metadata: initialMetadata,
    stage: initialStage,
  })

  const value = useMemo<TranscriptionContextValue>(
    () => ({
      ...state,
      dispatch,
    }),
    [state, dispatch],
  )

  return <TranscriptionContext.Provider value={value}>{children}</TranscriptionContext.Provider>
}
