import {useCallback, use, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router'
import nspell from 'nspell'
import {useLogger} from '@kensho/lumberjack'

import useLocalStorageState from '../hooks/useLocalStorageState'
import {
  CUSTOM_DICTIONARY_STORE_KEY,
  DEFAULT_CUSTOM_DICTIONARY,
  DEFAULT_DICTIONARY,
  DICTIONARIES,
  DICTIONARY_STORE_KEY,
  SpellCheckDictionary,
  defaultDictionary,
} from '../core/account/dictionaries'

import SpellCheckContext from './SpellCheckContext'
import SiteAnalyticsContext from './SiteAnalyticsContext'
import TranscriptionContext from './TranscriptionContext'

/**
 * NOTE: this implementation is specific to English. It will not work for other languages.
 *
 * hunspell affix + dictionary files are built for "pure" words.
 * Things like grammatical punctuation, numbers, email addresses, etc. will return false positives.
 * So we need to do some pre-processing to filter out those and other edge cases.
 *
 * Compare this behavior to Word spellcheck
 */
export function checkNormalizedWord(
  word = '',
  dictionary?: SpellCheckDictionary,
): {correct: boolean; normalizedWord: string} {
  const value = {
    correct: true,
    normalizedWord: word,
  }

  if (!dictionary) return value

  // ignore whitespace
  value.normalizedWord = word.trim()
  if (!value.normalizedWord) return value

  // if the word is an acronym, assume it's correct
  // U.S.A. USA S&P
  if (/^[A-Z.&0-9]+$/.test(value.normalizedWord)) return value

  // if the word is alphanumeric with hyphens/brackets, assume it's correct
  // 10-K 1099(c) 401(k) 2b 29m
  if (/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9\-()[\]{}]+$/.test(value.normalizedWord)) return value

  // if the word is all letters, check it as-is
  if (!/[^a-zA-Z_]/.test(value.normalizedWord)) {
    if (value.normalizedWord.length > 1) {
      value.correct = dictionary.check(value.normalizedWord)
      return value
    }
    value.correct = value.normalizedWord === 'a'
    return value
  }

  // if the word is numerical or only symbols assume it's correct
  // 123 3.14 1,000 1-800-123-4567 8:00 127:0:0:1 ...?!
  if (/^[\d\W]*$/.test(value.normalizedWord)) return value

  // if the word is a URL, assume it's correct
  // https://www.google.com
  if (value.normalizedWord.startsWith('http')) return value

  // if the word is an email address, assume it's correct
  // email@test.com
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value.normalizedWord,
    )
  )
    return value

  // remove outer punctuation
  // (dog) Mr. cat. cat's cat?
  value.normalizedWord = value.normalizedWord
    .replace(/^[^a-zA-Z0-9]+/, '')
    .replace(/[^a-zA-Z0-9]+$/, '')

  if (dictionary.check(value.normalizedWord)) return value

  // if the word is a compound word, check each part separately
  // year-end sell-side
  if (value.normalizedWord.includes('-')) {
    const parts = value.normalizedWord.split('-')
    value.correct = parts.every((part) => dictionary.check(part))
    return value
  }

  value.correct = dictionary.check(value.normalizedWord)
  return value
}

type CheckedWordsCache = Record<string, boolean>
type SuggestedWordsCache = Record<string, string[]>

let checkedWordsCache: CheckedWordsCache = {}
let suggestedWordsCache: SuggestedWordsCache = {}

/**
 * While the native spellcheck does work for contentEditable elements, it is not perfect and often requires explicit user focus.
 * We need more granular control than that to ensure immediate feedback to an editor.
 * Unfortunately, native spellcheck is not exposed to JS, so we have to use a library.
 */
export default function SpellCheckProvider(props: {children: React.ReactNode}): React.ReactNode {
  const {children} = props
  const {metadata} = use(TranscriptionContext)
  const [dictionary, setDictionary] = useState<SpellCheckDictionary>(defaultDictionary)
  const {transcriptId} = useParams()
  const [dictionaryLanguage] = useLocalStorageState(DICTIONARY_STORE_KEY, DEFAULT_DICTIONARY)
  const [customDictionary, setCustomDictionary] = useLocalStorageState(
    CUSTOM_DICTIONARY_STORE_KEY,
    DEFAULT_CUSTOM_DICTIONARY,
  )
  const analytics = use(SiteAnalyticsContext)
  const log = useLogger()

  useEffect(() => {
    let current = true

    try {
      const targetLanguage = metadata?.locale || dictionaryLanguage
      const dictionaryResource = DICTIONARIES.find((d) => d.langCode === targetLanguage)
      if (!dictionaryResource) {
        throw new Error(`No dictionary found for ${targetLanguage}`)
      }

      Promise.all([
        fetch(dictionaryResource.affixFileURL).then((response) => response.text()),
        fetch(dictionaryResource.dictionaryFileURL).then((response) => response.text()),
      ]).then(([aff, dic]) => {
        if (!current) return
        const nspellDictionary = nspell({aff, dic})
        customDictionary.forEach((word) => nspellDictionary.add(word))

        setDictionary({
          langCode: dictionaryResource.langCode,
          check: (word: string) => nspellDictionary.spell(word).correct,
          suggest: (word: string) => nspellDictionary.suggest(word),
          addWord: (word: string) => nspellDictionary.add(word),
        })
      })
    } catch (e) {
      log.error(e as Error)
      setDictionary(defaultDictionary)
    }

    return () => {
      current = false
    }
  }, [dictionaryLanguage, customDictionary, log, metadata?.locale])

  const check = useCallback(
    (word = ''): boolean => {
      if (word in checkedWordsCache) return checkedWordsCache[word]
      // extracted to separate function for easier testing
      const result = checkNormalizedWord(word, dictionary).correct
      checkedWordsCache[word] = result
      return result
    },
    [dictionary],
  )

  const suggest = useCallback(
    (word = ''): string[] => {
      if (word in suggestedWordsCache) return suggestedWordsCache[word]
      const result = dictionary.suggest(word).slice(0, 3)
      suggestedWordsCache[word] = result
      return result
    },
    [dictionary],
  )

  const addWord = useCallback(
    (word = ''): void => {
      if (!word) return
      const {normalizedWord} = checkNormalizedWord(word, dictionary)
      setCustomDictionary([...customDictionary, normalizedWord])
      checkedWordsCache[normalizedWord] = true
      dictionary.addWord(normalizedWord)
      analytics.sendEvent('custom dictionary word added', {dictionary: dictionary.langCode, word})
    },
    [dictionary, setCustomDictionary, customDictionary, analytics],
  )

  // reset when transcriptId changes so we don't infinitely grow the cache
  // reset when dictionary changes in case we switch languages or regional dialects
  useEffect(() => {
    checkedWordsCache = {}
    suggestedWordsCache = {}
  }, [transcriptId, dictionary])

  const value = useMemo(
    () => ({
      langCode: dictionary?.langCode || null,
      check,
      suggest,
      addWord,
    }),
    [dictionary, check, suggest, addWord],
  )

  return <SpellCheckContext value={value}>{children}</SpellCheckContext>
}
