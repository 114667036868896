import {use} from 'react'
import {ButtonLink, HeadlessLink} from '@kensho/neo'

import scribeLogo from '../assets/scribe-logo.svg'
import SiteAnalyticsContext from '../providers/SiteAnalyticsContext'

import UserInfo from './UserInfo'

export default function Header(): React.ReactNode {
  const analytics = use(SiteAnalyticsContext)

  return (
    <header className="sticky top-0 z-10 flex h-20 items-center justify-between bg-blue-50 px-11">
      <HeadlessLink href="/transcriptions">
        <img src={scribeLogo} alt="Kensho Scribe" className="h-12" />
      </HeadlessLink>
      <nav className="flex gap-4">
        <ButtonLink
          href="https://docs.kensho.com/scribe/v2/faq"
          minimal
          onClick={() => analytics.sendEvent('view_support')}
        >
          Contact support
        </ButtonLink>
        <UserInfo />
      </nav>
    </header>
  )
}
