import {Button, TextLink} from '@kensho/neo'

import CookieOption from './CookieOption'

export type CookieConsentOptions = {
  functional: boolean
  targeting: boolean
  performance: boolean
  isSet: boolean
}

interface CookieConsentDialogContentsProps {
  consentState: Omit<CookieConsentOptions, 'isSet'>
  changeConsent: (cookieUpdate: Partial<CookieConsentOptions>) => void
  onClose: () => void
}

export default function CookieConsentDialogContents({
  consentState: {functional, targeting, performance},
  changeConsent,
  onClose,
}: CookieConsentDialogContentsProps): React.ReactNode {
  return (
    <>
      <div>
        <h3 className="mb-2 text-lg font-bold">Privacy Preference Center</h3>
        <div className="flex flex-col gap-1 text-xs">
          <p>
            When you visit any web site, it may store or retrieve information on your browser,
            mostly in the form of cookies. This information might be about you, your preferences or
            your device and is mostly used to make the site work as you expect it to. The
            information does not usually directly identify you, but it can give you a more
            personalized web experience. Because we respect your right to privacy, you can choose
            not to allow some types of cookies. Click on the different category headings to find out
            more and change our default settings. However, blocking some types of cookies may impact
            your experience of the site and the services we are able to offer.
          </p>
          <TextLink href="https://cookiepedia.co.uk/giving-consent-to-cookies" target="_blank">
            More information
          </TextLink>
        </div>
        <div className="my-4">
          <Button
            intent="primary"
            onClick={() => {
              changeConsent({functional: true, targeting: true, performance: true})
            }}
          >
            Allow all
          </Button>
        </div>
        <h3 className="mb-4 text-lg font-bold">Manage Consent Preferences</h3>
        <div className="divide-y overflow-hidden rounded-lg border border-gray-200">
          <CookieOption
            title="Functional cookies"
            expandedContent={
              <p>
                These cookies enable the website to provide enhanced functionality and
                personalisation. They may be set by us or by third party providers whose services we
                have added to our pages. If you do not allow these cookies then some or all of these
                services may not function properly.
              </p>
            }
            checked={functional}
            onChange={(setCookie) => changeConsent({functional: setCookie})}
          />
          <CookieOption
            title="Targeting cookies"
            expandedContent={
              <p>
                These cookies may be set through our site by our advertising partners. They may be
                used by those companies to build a profile of your interests and show you relevant
                adverts on other sites. They do not store directly personal information, but are
                based on uniquely identifying your browser and internet device. If you do not allow
                these cookies, you will experience less targeted advertising.
              </p>
            }
            checked={targeting}
            onChange={(setCookie) => changeConsent({targeting: setCookie})}
          />
          <CookieOption
            title="Performance cookies"
            expandedContent={
              <p>
                These cookies allow us to count visits and traffic sources so we can measure and
                improve the performance of our site. They help us to know which pages are the most
                and least popular and see how visitors move around the site. All information these
                cookies collect is aggregated and therefore anonymous. If you do not allow these
                cookies we will not know when you have visited our site, and will not be able to
                monitor its performance.
              </p>
            }
            checked={performance}
            onChange={(setCookie) => changeConsent({performance: setCookie})}
          />
          <CookieOption
            title="Strictly necessary cookies"
            expandedContent={
              <p>
                These cookies are necessary for the website to function and cannot be switched off
                in our systems. They are usually only set in response to actions made by you which
                amount to a request for services, such as setting your privacy preferences, logging
                in or filling in forms. You can set your browser to block or alert you about these
                cookies, but some parts of the site will not then work. These cookies do not store
                any personally identifiable information.
              </p>
            }
            checked
          />
        </div>
      </div>
      <div className="mt-5 flex justify-end">
        <Button
          intent="primary"
          onClick={() => {
            onClose()
            changeConsent({isSet: true})
          }}
        >
          Save settings
        </Button>
      </div>
    </>
  )
}
