import './styles.css'
import {createRoot} from 'react-dom/client'
import {enablePatches} from 'immer'
import {RouterProvider} from 'react-router/dom'
import {StrictMode} from 'react'

import {handleReactError, wrappedCreateBrowserRouter} from './sentry'
import routes from './routes'

const router = wrappedCreateBrowserRouter(routes)

const app = (
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
)

enablePatches()

const rootElement = document.createElement('div')
document.body.appendChild(rootElement)

const root = createRoot(rootElement, {
  onCaughtError: handleReactError,
  onRecoverableError: handleReactError,
  onUncaughtError: handleReactError,
})

root.render(app)
