import {use} from 'react'
import {InputGroup, TextLink} from '@kensho/neo'

import BasePage from '../components/BasePage'
import CopyableValue from '../components/CopyableValue'
import UserContext from '../providers/UserContext'
import DictionaryPreference from '../core/account/DictionaryPreference'

export default function AccountPage(): React.ReactNode {
  const {user} = use(UserContext)

  if (!user) throw new Error('Unauthenticated')

  return (
    <BasePage>
      <main className="mx-auto my-0 flex w-full max-w-[900px] flex-col gap-4 p-7 pb-0">
        <h1 className="text-2xl text-gray-800">Account</h1>

        {user.email && (
          <div className="max-w-80">
            <InputGroup value={user.email} label="Email Address" readOnly />
          </div>
        )}

        <CopyableValue
          value={user.refreshToken}
          label="Refresh Token"
          description="Used to generate new temporary Access Tokens"
        />
        {user.token && (
          <CopyableValue
            value={user.token}
            label="Access Token"
            description="Used to programmatically access the API"
          />
        )}

        <div>
          See the{' '}
          <TextLink href="https://docs.kensho.com/authentication/personal-token">
            Personal Token Authentication Guide
          </TextLink>{' '}
          for information on token usage.
        </div>

        <DictionaryPreference />
      </main>
    </BasePage>
  )
}
