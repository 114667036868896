import {useEffect, useId, useRef} from 'react'
import clsx from 'clsx'

function clamp(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value))
}

function portion(value: number, min: number, max: number): number {
  if (min === max) return 0

  const clamped = clamp(value, min, max)

  return (clamped - min) / (max - min)
}

function snap(value: number, step: number, offset: number): number {
  return Math.round((value - offset) / step) * step + offset
}

export interface SliderProps {
  disabled?: boolean
  fill?: boolean
  min: number
  max: number
  onChange?: (value: number) => void
  step?: number
  value: number
}

export default function VolumeSlider(props: SliderProps): React.ReactNode {
  const {disabled = false, fill = true, min, max, step, onChange, value} = props

  const controlsRef = useRef<HTMLDivElement>(null)

  const thumbRef = useRef<HTMLDivElement>(null)

  const id = useId()
  const sliderId = `${id}-slider`

  const clampedValue = clamp(value, min, max)
  const percent = (100 * (clampedValue - min)) / (max - min)

  useEffect(() => {
    if (thumbRef.current) {
      thumbRef.current.focus()
    }
  }, [])

  if (max <= min) {
    throw new Error('`max` must be greater than `min`')
  }

  const thumbStyle = {
    bottom: `${percent}%`,
  }
  const trackFillStyle = {
    height: `${percent}%`,
  }

  function focusThumb(): void {
    thumbRef.current?.focus({preventScroll: true})
  }

  function handlePointerDown(event: React.PointerEvent): void {
    if (disabled) return
    if (event.button !== 0) return

    const {pointerId} = event

    event.preventDefault()
    event.stopPropagation()

    function update(x: number): void {
      const controlsEl = controlsRef.current

      if (!controlsEl) return
      const {bottom, top} = controlsEl.getBoundingClientRect()

      const pct = portion(x, top, bottom)

      const scaledValue = min + pct * (max - min)

      const nextValue = step === undefined ? scaledValue : snap(scaledValue, step, min)

      onChange?.(max - nextValue)
    }

    function handlePointerMove(e: PointerEvent): void {
      if (e.pointerId !== pointerId) return
      update(e.clientY)
    }

    function handlePointerUp(e: PointerEvent): void {
      if (e.pointerId !== pointerId) return
      update(e.clientY)
      window.removeEventListener('pointermove', handlePointerMove)
      window.removeEventListener('pointerup', handlePointerUp)
      window.removeEventListener('pointercancel', handlePointerUp)
    }

    focusThumb()
    update(event.clientY)

    window.addEventListener('pointermove', handlePointerMove)
    window.addEventListener('pointerup', handlePointerUp)
    window.addEventListener('pointercancel', handlePointerUp)
  }

  function handleKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      const newValue = Math.round((value - (step ?? 1) + Number.EPSILON) * 100) / 100
      if (newValue < min) return
      onChange?.(newValue)
    } else if (event.key === 'ArrowUp') {
      const newValue = Math.round((value + (step ?? 1) + Number.EPSILON) * 100) / 100
      if (newValue > max) return
      onChange?.(newValue)
    }
  }

  return (
    <div role="group" id={id}>
      <div className="relative h-20" onPointerDown={handlePointerDown} ref={controlsRef}>
        <div className="absolute h-20 w-1 -translate-x-1/2 rounded-sm bg-gray-200" />
        {fill && (
          <div
            className={clsx(
              'absolute bottom-0 w-1 -translate-x-1/2 rounded-sm',
              disabled ? 'bg-gray-400' : 'bg-brand-700',
            )}
            style={trackFillStyle}
          />
        )}
        <div
          aria-orientation="vertical"
          aria-labelledby="Volume"
          aria-valuemax={max}
          aria-valuemin={min}
          aria-valuenow={clampedValue}
          className={clsx(
            'absolute h-3 w-3 -translate-x-1/2 cursor-pointer rounded-full ring-0 ring-transparent ring-offset-2 outline-none focus-visible:ring-2 focus-visible:ring-cyan-600',
            disabled ? 'bg-gray-400' : 'bg-brand-700',
          )}
          id={sliderId}
          onKeyDown={handleKeyDown}
          ref={thumbRef}
          role="slider"
          style={thumbStyle}
          tabIndex={disabled ? -1 : 0}
        />
      </div>
    </div>
  )
}
