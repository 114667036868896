import {useLogger} from '@kensho/lumberjack'
import {Button, Icon, Tooltip, useToaster} from '@kensho/neo'
import {useCallback, use, useEffect, useInsertionEffect, useRef, useState} from 'react'
import {ZodError} from 'zod'

import useTypedWebSocket from '../../../hooks/useTypedWebSocket'
import TranscriptContext from '../../../providers/TranscriptContext'
import UserContext from '../../../providers/UserContext'
import {
  RealtimeClientMessage,
  RealtimeClientMessageSchema,
  RealtimeServerMessage,
  RealtimeServerMessageSchema,
} from '../../../types/schemas'
import {ScribeError, Stage} from '../../../types/types'
import parseError from '../../../utils/parseError'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {REALTIME_SRC_MIME_TYPE} from '../constants'

import RecordingManager, {RecordingManagerState} from './RecordingManager'
import useRealtimeBuffer from './utils/useRealtimeBuffer'

export type RealtimeStatus = 'starting' | 'transcribing' | 'finalizing' | 'ended' | 'offline'

interface RealtimeTranscriberProps {
  stage: Stage
  transcriptId: string
  audioInputDeviceId?: string
  resetRealtimeData: () => void
  onTranscribingComplete: () => void
  onAudioFileAvailable: (file: File) => void
  onAudioUnsupported: () => void
  onProcessAudio: (audioData: number[], totalDuration: number) => void
  onError: (error?: ScribeError) => void
}

const FINALIZE_TIMEOUT_MS = 30 * 1000
const SERVER_ADD_DATA_THRESHOLD = 10

function useEventEffect<R, A extends unknown[]>(callback: (...args: A) => R): (...args: A) => R {
  const ref = useRef(callback)

  useInsertionEffect(() => {
    ref.current = callback
  }, [callback])

  return useCallback((...args) => ref.current(...args), [])
}

export default function RealtimeTranscriber(props: RealtimeTranscriberProps): React.ReactNode {
  const {
    stage,
    transcriptId,
    audioInputDeviceId,
    resetRealtimeData,
    onTranscribingComplete,
    onAudioFileAvailable,
    onAudioUnsupported,
    onProcessAudio,
    onError,
  } = props
  const log = useLogger()
  const {user} = use(UserContext)
  const {dispatch: transcriptContextDispatch, transcript} = use(TranscriptContext)
  const toaster = useToaster()
  const [realtimeStatus, setRealtimeStatus] = useState<RealtimeStatus>('starting')
  const [shouldConnect, setShouldConnect] = useState(true)
  const [recordingManagerState, setRecordingManagerState] =
    useState<RecordingManagerState>('uninitialized')
  const [recordingManager, setRecordingManager] = useState<RecordingManager>()
  const [pendingAddDataIds, setPendingAddDataIds] = useState<number[]>([])

  const endTranscription = useCallback(() => {
    recordingManager?.close()
    setRealtimeStatus('ended')
    onTranscribingComplete()
    // create audio file
    if (recordingManager)
      onAudioFileAvailable(
        new File([recordingManager.getRecording()], 'recording.wav', {
          type: REALTIME_SRC_MIME_TYPE,
        }),
      )
    setShouldConnect(false)
  }, [setRealtimeStatus, onTranscribingComplete, recordingManager, onAudioFileAvailable])

  const handleError = useCallback(
    (error: ScribeError) => {
      // end transcription even on error so user can download/edit whatever progress was made
      endTranscription()
      onError(error)
    },
    [onError, endTranscription],
  )

  const {sendMessage} = useTypedWebSocket({
    url: `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/ws`,
    clientMessageSchema: RealtimeClientMessageSchema,
    serverMessageSchema: RealtimeServerMessageSchema,

    onValidationError: (error: ZodError, message: unknown, isClientMessage: boolean): void => {
      log.error(error, {message: `${JSON.stringify(message)}`, isClientMessage})
      handleError({type: 'realtimeError'})
      setShouldConnect(false)
    },

    onOpen: (send: (message: RealtimeClientMessage) => void): void => {
      if (!user?.token) {
        handleError({type: 'unauthenticated'})
        return
      }
      send({
        message: 'ResumeTranscription',
        requestId: transcriptId,
        token: user.token,
      })
    },

    onMessage: (message: RealtimeServerMessage): boolean => {
      switch (message.message) {
        case 'TranscriptionResumed':
          recordingManager?.resume()
          resetRealtimeData()
          setRealtimeStatus('transcribing')
          break
        case 'AddTranscript': {
          const editorAction = produceEditorAction({
            action: {type: 'append-slice', slice: message.transcript},
            transcript,
            transcriptSelection: null,
            log,
          })
          if (editorAction) {
            transcriptContextDispatch({
              type: 'revision',
              // revisions originating from the server are not undoable
              editorAction: {...editorAction, undoable: false, selectionChangeDisabled: true},
            })
          }
          break
        }
        case 'EndOfTranscript':
          endTranscription()
          break
        case 'DataAdded': {
          setPendingAddDataIds((ids) => ids.filter((id) => id !== message.sequenceNumber))
          if (realtimeStatus === 'offline' && recordingManagerState === 'paused') {
            recordingManager?.resume()
            setRealtimeStatus('transcribing')
            toaster.show({label: 'You are back online. Recording resumed', intent: 'primary'})
          }
          break
        }
        case 'Error':
          handleError(parseError(message))
          break
        default:
          break
      }
      return false
    },

    onClose: (e: CloseEvent): boolean => {
      // code 1000 is a clean connection close, all else are connection errors
      if (e.code !== 1000) {
        log.error('Realtime connection closed abnormally', {code: e.code, reason: e.reason})
        handleError({type: 'realtimeError'})
      }
      return false
    },
    shouldConnect,
  })

  const realtimeBuffer = useRealtimeBuffer(sendMessage, (sequenceValue) => {
    setPendingAddDataIds((ids) => [...ids, sequenceValue])
  })

  // different than endTranscription method above
  // this flushes remaining data to server and waits for server to acknowledge with "EndOfTranscript"
  const finalizeTranscription = useCallback(() => {
    // Stop recording audio
    recordingManager?.close()
    setRealtimeStatus('finalizing')

    // notify server that the transcript has ended
    sendMessage({
      message: 'EndOfStream',
      // force flush any remaining data
      lastSequenceNumber: realtimeBuffer.flush(true),
    })
  }, [recordingManager, realtimeBuffer, sendMessage])

  const handleAudioUnsupported = useEventEffect(onAudioUnsupported)
  const handleProcessAudio = useEventEffect((audioData: number[]) => {
    if (realtimeStatus === 'transcribing') realtimeBuffer.push(audioData)
    onProcessAudio(audioData, recordingManager?.duration || 0)
  })
  const stableResetRealtimeData = useEventEffect(resetRealtimeData)

  // create recording manager (only when audioInputDeviceId changes)
  useEffect(() => {
    let nextRecordingManager: RecordingManager
    if (audioInputDeviceId) {
      nextRecordingManager = new RecordingManager(audioInputDeviceId, log)
      nextRecordingManager.onStateChange = setRecordingManagerState
      nextRecordingManager.onError = handleAudioUnsupported
      nextRecordingManager.onProcessAudio = handleProcessAudio
      nextRecordingManager.open()
      setRecordingManager(nextRecordingManager)
    }

    return () => {
      nextRecordingManager?.close()
      setRecordingManager(undefined)
      stableResetRealtimeData()
    }
  }, [audioInputDeviceId, stableResetRealtimeData, handleAudioUnsupported, handleProcessAudio, log])

  // server should ack AddData messages immediately upon receiving and before processing
  // so if a queue is building up it means something is wrong
  // switch to offline mode so user can take action (i.e. restarting)
  const serverNotResponding = pendingAddDataIds.length >= SERVER_ADD_DATA_THRESHOLD
  useEffect(() => {
    if (serverNotResponding) {
      recordingManager?.pause()
      setPendingAddDataIds([])
      setRealtimeStatus('offline')
      toaster.show({
        label: 'Network connection lost. Recording paused.',
        intent: 'danger',
      })
    }
  }, [recordingManager, serverNotResponding, toaster, setRealtimeStatus])

  // set timeout so transcript can be exposed if server doesn't respond with "EndOfTranscript"
  // in a reasonable amount of time, otherwise all the work will be lost stuck in transcribing state
  useEffect(() => {
    let timeoutId: number
    if (realtimeStatus === 'finalizing') {
      timeoutId = window.setTimeout(() => endTranscription(), FINALIZE_TIMEOUT_MS)
    }
    return () => {
      if (timeoutId) window.clearTimeout(timeoutId)
    }
  }, [realtimeStatus, endTranscription])

  // hide controls, but keep mounted for transcript data received from server after user finishes recording
  if (stage === 'POST_TRANSCRIPTION') return null

  return (
    <div className="flex items-center gap-10">
      {realtimeStatus === 'offline' && (
        <Tooltip content="You are offline. The current transcription has been paused and will resume when you reconnect.">
          <Icon icon="ExclamationCircleIcon" />
        </Tooltip>
      )}
      {realtimeStatus === 'transcribing' && (
        <>
          <Tooltip
            position="top"
            content={
              recordingManagerState === 'recording' ? 'Pause recording' : 'Continue recording'
            }
          >
            <Button
              icon={recordingManagerState === 'recording' ? 'PauseIcon' : 'PlayIcon'}
              intent="primary"
              rounded
              size="small"
              aria-label={
                recordingManagerState === 'recording' ? 'Pause recording' : 'Continue recording'
              }
              onClick={
                recordingManagerState === 'recording'
                  ? () => recordingManager?.pause()
                  : () => recordingManager?.resume()
              }
            />
          </Tooltip>
        </>
      )}
      {(realtimeStatus === 'transcribing' ||
        realtimeStatus === 'finalizing' ||
        realtimeStatus === 'offline') && (
        <Tooltip
          content={realtimeStatus === 'finalizing' ? 'Finalizing…' : 'Finalize recording'}
          position="top"
        >
          <Button
            size="small"
            rounded
            intent="danger"
            icon="StopIcon"
            disabled={realtimeStatus === 'finalizing'}
            aria-label="Finalize recording"
            onClick={finalizeTranscription}
          />
        </Tooltip>
      )}
    </div>
  )
}
