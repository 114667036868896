import {useWindowSize} from '@kensho/tacklebox'

import useClickOutside from '../../../hooks/useClickOutside'
import {prettyShortcutKey} from '../../../shortcuts/ShortcutSection'

import {TokenLocation} from './types'

interface Props {
  x: number
  y: number
  selectedTokens: TokenLocation[]
  jumpToCurrentTime: () => void
  jumpToNextUnalignedToken: () => void
  setIsAltDragMode: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

const MENU_SIZE = {
  width: 288,
  height: 128,
}

export default function ContextMenu(props: Props): React.ReactNode {
  const {
    x,
    selectedTokens,
    jumpToNextUnalignedToken,
    jumpToCurrentTime,
    onClose,
    setIsAltDragMode,
  } = props
  const {width} = useWindowSize()

  const clickOutsideRef = useClickOutside(() => onClose())

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      ref={clickOutsideRef}
      className="absolute list-none overflow-hidden rounded-md border border-gray-200 bg-white py-1 shadow-lg select-none"
      // doing simplified positioning with a fixed width + height
      // TODO: replace this with a proper autosized + autopositioned neo context menu overlay component when available
      style={{
        width: `${MENU_SIZE.width}px`,
        height: `${MENU_SIZE.height}px`,
        left: `${Math.max(0, Math.min(width - 20 - MENU_SIZE.width, x))}px`,
        bottom: '50px',
      }}
      onClick={() => onClose()}
    >
      <button
        type="button"
        className="flex w-full items-center justify-between px-4 py-2 text-gray-700 focus:bg-gray-100 focus:text-gray-900 enabled:cursor-pointer enabled:hover:bg-gray-50 enabled:active:bg-gray-100 disabled:text-gray-400"
        onClick={jumpToCurrentTime}
      >
        <span>Jump to current time</span>
        <span>K</span>
      </button>
      <button
        type="button"
        className="flex w-full items-center justify-between px-4 py-2 text-gray-700 focus:bg-gray-100 focus:text-gray-900 enabled:cursor-pointer enabled:hover:bg-gray-50 enabled:active:bg-gray-100 disabled:text-gray-400"
        onClick={() => {
          jumpToNextUnalignedToken()
        }}
      >
        <span>Jump to next unaligned token</span>
        <span>N</span>
      </button>
      <button
        type="button"
        disabled={selectedTokens.length !== 1}
        className="flex w-full items-center justify-between px-4 py-2 text-gray-700 focus:bg-gray-100 focus:text-gray-900 enabled:cursor-pointer enabled:hover:bg-gray-50 enabled:active:bg-gray-100 disabled:text-gray-400"
        onClick={() => {
          setIsAltDragMode(true)
        }}
      >
        <span>Hold and drag to set time</span>
        <span className="flex items-center gap-1">
          {prettyShortcutKey('Alt')}
          {prettyShortcutKey('Left-click')}
        </span>
      </button>
    </div>
  )
  /* eslint-enable jsx-a11y/click-events-have-key-events */
}
