import {useLogger} from '@kensho/lumberjack'
import {Button, ButtonLink} from '@kensho/neo'
import {ReactNode, use, useState} from 'react'
import {useNavigate} from 'react-router'
import {ZodError} from 'zod'

import ErrorDialog from '../../../components/ErrorDialog'
import useTypedWebSocket from '../../../hooks/useTypedWebSocket'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import UserContext from '../../../providers/UserContext'
import {
  RealtimeClientMessage,
  RealtimeClientMessageSchema,
  RealtimeServerMessage,
  RealtimeServerMessageSchema,
} from '../../../types/schemas'
import {
  RealtimeTranscriptionConfiguration,
  ScribeError,
  TranscriptionConfiguration,
} from '../../../types/types'
import parseError from '../../../utils/parseError'
import {REALTIME_NUM_CHANNELS, REALTIME_TRANSCRIPTION_SAMPLE_RATE} from '../constants'
import MicrophoneAudioVisualization from '../../../components/MicrophoneAudioVisualization'
import AudioInputDeviceSelect from '../../../components/AudioInputDeviceSelect'

import {FormState} from './FormState'
import PartialTranscripts from './PartialTranscripts'
import TranscriptName from './TranscriptName'

type RealtimeSubmissionStatus = 'idle' | 'starting'

interface RealtimeConfigFormProps {
  transcriptionConfiguration: RealtimeTranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
}

export default function RealtimeConfigForm(props: RealtimeConfigFormProps): ReactNode {
  const {
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    audioInputDeviceId,
    onAudioInputDeviceChange,
  } = props

  const defaultHotwords =
    'hotwords' in transcriptionConfiguration ? transcriptionConfiguration.hotwords : []
  const [formState, setFormState] = useState<FormState>({
    transcriptName:
      transcriptionConfiguration.name ||
      new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'short'}).format(new Date()),
    hotwords: new Set<string>(defaultHotwords),
    errors: {},
  })
  const log = useLogger()
  const navigate = useNavigate()
  const analytics = use(SiteAnalyticsContext)
  const {user} = use(UserContext)
  const [scribeError, setScribeError] = useState<ScribeError>()
  const [status, setStatus] = useState<RealtimeSubmissionStatus>('idle')

  // when user submits form open a realtime websocket connection and start the transcription
  // once the transcription id is received, close the connection and proceed to next stage where
  // RealtimeTranscriber will reconnect and resume with that transcription id
  useTypedWebSocket({
    url: `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/ws`,
    clientMessageSchema: RealtimeClientMessageSchema,
    serverMessageSchema: RealtimeServerMessageSchema,
    onValidationError: (error: ZodError, message: unknown, isClientMessage: boolean): void => {
      log.error(error, {message: `${JSON.stringify(message)}`, isClientMessage})
      setScribeError({type: 'realtimeStartError'})
    },
    onMessage: (
      message: RealtimeServerMessage,
      sendMessage: (message: RealtimeClientMessage) => void,
    ): boolean => {
      switch (message.message) {
        case 'Authenticated':
          sendMessage({
            message: 'StartTranscription',
            audioFormat: {
              type: 'RAW',
              encoding: 'pcm_s16le',
              sampleRateHz: REALTIME_TRANSCRIPTION_SAMPLE_RATE,
              numChannels: REALTIME_NUM_CHANNELS,
            },
            hotwords: [...(transcriptionConfiguration.hotwords || [])],
            features: {allowPartials: !!transcriptionConfiguration.partialTranscripts},
          })
          break
        case 'TranscriptionStarted':
          navigate(`/transcriptions/${message.requestId}/realtime`)
          break
        case 'Error':
          setScribeError(parseError(message))
          setStatus('idle')
          break
        default:
          break
      }
      return false
    },
    onClose: (e: CloseEvent): boolean => {
      // code 1000 is a clean connection close, all else are connection errors
      if (e.code !== 1000) {
        log.error('Realtime start connection error', {code: e.code, reason: e.reason})
        setScribeError({type: 'realtimeStartError'})
      }
      setStatus('idle')
      return false
    },
    onOpen: (sendMessage: (message: RealtimeClientMessage) => void): void => {
      if (!user?.token) {
        setScribeError({type: 'unauthenticated'})
        return
      }
      sendMessage({message: 'Authenticate', token: user?.token})
    },
    shouldConnect: status === 'starting',
  })

  return (
    <div className="flex flex-col gap-10">
      <ErrorDialog
        isOpen={!!scribeError}
        error={scribeError}
        onClose={() => setScribeError(undefined)}
      />
      <div className="text-lg font-semibold">Start a new recording</div>
      <div className="grid grid-cols-10 gap-x-2 gap-y-8">
        <div className="col-span-9">
          <TranscriptName
            error={formState.errors.transcriptName}
            setFormState={setFormState}
            transcriptName={formState.transcriptName}
          />
        </div>
        <div className="col-span-9">
          <AudioInputDeviceSelect
            audioInputDeviceId={audioInputDeviceId}
            onAudioInputDeviceChange={onAudioInputDeviceChange}
          />
        </div>
        <div className="col-span-1 col-start-10 mb-1 flex items-end">
          <MicrophoneAudioVisualization audioInputDeviceId={audioInputDeviceId} />
        </div>
        <div className="col-span-9">
          <PartialTranscripts
            transcriptionConfiguration={transcriptionConfiguration}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
          />
        </div>
        <div className="col-span-5 col-end-10">
          <div className="flex justify-end gap-4">
            <ButtonLink href="/transcriptions">Cancel</ButtonLink>
            <Button
              intent="primary"
              disabled={
                !!scribeError ||
                Object.values(formState.errors).some((error) => error) ||
                audioInputDeviceId === undefined
              }
              onClick={() => {
                setTranscriptionConfiguration({
                  ...transcriptionConfiguration,
                  name: formState.transcriptName,
                  hotwords: formState.hotwords,
                })
                analytics.sendEvent('start_realtime_transcription')
                setStatus('starting')
              }}
            >
              {status === 'idle' ? 'Start Recording' : 'Starting…'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
