import {sortBy} from 'lodash-es'
import {use, useMemo, useState} from 'react'
import {Button, Popover} from '@kensho/neo'

import {APITranscript} from '../../../../../types/types'
import {TranscriptPermissionsContext} from '../../../TranscriptPermissionsProvider'

import SpeakerItem from './SpeakerItem'
import SpeakerEditor from './SpeakerEditor'

interface Props {
  transcript: APITranscript
}

export default function SpeakerList(props: Props): React.ReactNode {
  const {transcript} = props
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)

  const {taggedSpeakers, untaggedSpeakers} = useMemo(() => {
    const untaggedSpeakerIds = new Set(Object.keys(transcript.speakers).map(Number))
    const taggedSpeakerIds: number[] = []

    let i = 0
    while (untaggedSpeakerIds.size > 0 && i <= transcript.sliceMeta.length - 1) {
      const {speakerId} = transcript.sliceMeta[i]
      if (untaggedSpeakerIds.has(speakerId)) {
        taggedSpeakerIds.push(speakerId)
        untaggedSpeakerIds.delete(speakerId)
      }
      i += 1
    }

    return {
      taggedSpeakers: sortBy(taggedSpeakerIds, (id) => transcript.speakers[id].name.toLowerCase()),
      untaggedSpeakers: sortBy([...untaggedSpeakerIds], (id) =>
        transcript.speakers[id].name.toLowerCase(),
      ),
    }
  }, [transcript])

  return (
    <div>
      <div className="mb-6 border-b border-gray-200 pb-6">
        <h4 className="mb-2 text-sm text-gray-500">Total Speakers</h4>
        <p className="text-lg font-semibold">{taggedSpeakers.length}</p>
      </div>
      {transcriptPermissions.edit && (
        <div className="mb-4">
          <Popover
            content={
              <SpeakerEditor transcript={transcript} onClose={() => setIsPopoverOpen(false)} />
            }
            isOpen={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
          >
            <Button icon="PlusCircleIcon" minimal intent="primary">
              Add a new speaker
            </Button>
          </Popover>
        </div>
      )}
      <ul className="list-none">
        {taggedSpeakers.map((id) => (
          <SpeakerItem
            key={id}
            id={id}
            tagged
            speaker={transcript.speakers[id]}
            transcript={transcript}
          />
        ))}
      </ul>

      {untaggedSpeakers.length > 0 && (
        <>
          <h4 className="mt-8 mb-2 text-sm text-gray-500">Untagged Speakers</h4>
          <ul className="list-none">
            {untaggedSpeakers.map((id) => (
              <SpeakerItem
                key={id}
                id={id}
                speaker={transcript.speakers[id]}
                transcript={transcript}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
