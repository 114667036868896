import {useToaster} from '@kensho/neo'
import {useDebouncedValue} from '@kensho/tacklebox'
import {useCallback, useEffect, useMemo} from 'react'
import {useSearchParams} from 'react-router'

import useDeleteTranscript from '../../../api/useDeleteTranscript'
import useTranscriptsMetadataQuery from '../../../hooks/useTranscriptsMetadataQuery'
import {HistoricalTranscriptSearchParamSchema} from '../../../types/schemas'
import {TranscriptMetadata} from '../../../types/types'
import assertNever from '../../../utils/assertNever'

import ActionBar from './ActionBar'
import PaginationBar from './PaginationBar'
import {TRANSCRIPTS_PER_PAGE} from './shared'
import TranscriptMetadataTable from './TranscriptMetadataTable'
import {OptionsAction} from './types'

export default function HistoricalTranscriptsTable(): React.ReactNode {
  const [searchParams, setSearchParams] = useSearchParams()
  const validatedParams = useMemo(() => {
    const searchParamsObject = Object.fromEntries(searchParams)

    const withDefaults = {
      search: '',
      sort: 'updated_on',
      direction: 'descending',
      page: '0',
      ...searchParamsObject,
    }

    return HistoricalTranscriptSearchParamSchema.parse(withDefaults)
  }, [searchParams])

  const optionsDispatch = useCallback(
    (action: OptionsAction): void => {
      switch (action.type) {
        case 'search':
          setSearchParams({...validatedParams, search: action.payload, page: '0'})
          break
        case 'sort':
          setSearchParams({
            ...validatedParams,
            sort: action.payload.sort || '',
            direction: action.payload.direction || '',
          })
          break
        case 'page':
          setSearchParams({
            ...validatedParams,
            page: `${action.payload}`,
          })
          break
        default:
          assertNever(action)
      }
    },
    [validatedParams, setSearchParams],
  )
  const {search, direction, sort} = validatedParams
  const debouncedParams = useDebouncedValue(validatedParams, 200)
  const nPage = window.parseInt(debouncedParams.page, 10)

  const {data, status, refetch} = useTranscriptsMetadataQuery({
    page_size: `${TRANSCRIPTS_PER_PAGE}`,
    name: debouncedParams.search,
    page: debouncedParams.page,
    sort,
    direction,
  })
  const transcriptMetadatas = data?.json
  const contentRange = data?.contentRange

  const deleteTranscriptMutation = useDeleteTranscript()
  const toaster = useToaster()

  useEffect(() => {
    if (status === 'error') {
      // We have requested an invalid page, so go back to the first page which is always safe
      if (
        transcriptMetadatas &&
        'msg_code' in transcriptMetadatas &&
        transcriptMetadatas.msg_code === 'S2T-TR-0001'
      ) {
        optionsDispatch({type: 'page', payload: 0})
        return
      }
      throw new Error(
        `HistoricalTranscriptsTable, error was: ${JSON.stringify(transcriptMetadatas)}`,
      )
    }
  }, [transcriptMetadatas, status, optionsDispatch])

  async function deleteTranscript(transcript: TranscriptMetadata): Promise<void> {
    try {
      const resp = await deleteTranscriptMutation(transcript.id)
      if (!resp.ok) throw new Error()
      if (contentRange) {
        const {startIndex, endIndex} = contentRange
        // if the current page is empty after deleting the transcript, go to the previous page if it exists
        if (startIndex === endIndex && nPage > 0) {
          optionsDispatch({type: 'page', payload: nPage - 1})
        } else {
          // TODO: Refactor the delete logic to use react query mutations
          await refetch()
        }
      }
    } catch (err) {
      toaster.show({
        label: `Error deleting transcript ${transcript.name}`,
        intent: 'danger',
      })
    }
  }

  const transcripts =
    transcriptMetadatas && !('msg_code' in transcriptMetadatas) ? transcriptMetadatas : null

  const isPending = status === 'pending'
  return (
    <div className="mt-12">
      <div className="flex max-h-[calc(100vh-160px)] w-[768px] max-w-[100vw] flex-col items-center">
        <div className="mb-11 w-full">
          <ActionBar search={search} optionsDispatch={optionsDispatch} />
        </div>
        <TranscriptMetadataTable
          deleteTranscript={deleteTranscript}
          isPending={isPending}
          transcripts={transcripts}
          sort={sort}
          direction={direction}
          optionsDispatch={optionsDispatch}
          refreshData={() => refetch()}
        />
        {!isPending && transcripts && transcripts.length === 0 && (
          <div className="p-4 text-center">
            {search ? (
              <>No results matched your search</>
            ) : (
              <>{`You don't have transcripts at the moment.`}</>
            )}
          </div>
        )}

        <PaginationBar
          page={nPage}
          optionsDispatch={optionsDispatch}
          isPending={isPending}
          contentRange={contentRange}
        />
      </div>
    </div>
  )
}
