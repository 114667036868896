import {isRouteErrorResponse, useNavigate, useRouteError} from 'react-router'
import {NeoProvider} from '@kensho/neo'

import NotFoundPage from './NotFoundPage'
import UnknownErrorPage from './UnknownErrorPage'

export default function ErrorPage(): React.ReactNode {
  const error = useRouteError()
  const navigate = useNavigate()

  return (
    <NeoProvider navigate={navigate}>
      {isRouteErrorResponse(error) && error.status === 404 ? (
        <NotFoundPage />
      ) : (
        <UnknownErrorPage />
      )}
    </NeoProvider>
  )
}
