import {createContext, useContext, ReactNode} from 'react'

import {EditorAction} from '../../../types/types'

type DispatchEditorActionType = (editorAction: EditorAction) => void

interface EditorActionProviderProps {
  children: ReactNode
  dispatchEditorAction: DispatchEditorActionType
}

const DispatchEditorActionContext = createContext<DispatchEditorActionType | null>(null)

export function DispatchEditorActionProvider({
  children,
  dispatchEditorAction,
}: EditorActionProviderProps): ReactNode {
  return (
    <DispatchEditorActionContext.Provider value={dispatchEditorAction}>
      {children}
    </DispatchEditorActionContext.Provider>
  )
}

export const useDispatchEditorAction = (): {
  dispatchEditorAction: DispatchEditorActionType
} => {
  const dispatchEditorAction = useContext(DispatchEditorActionContext)
  if (dispatchEditorAction === null) {
    throw new Error('useDispatchEditorAction must be used within a EditorActionProvider')
  }
  return {dispatchEditorAction}
}
