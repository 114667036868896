import {Logger} from '@kensho/lumberjack'

/** Copies the provided text to clipboard using modern Clipboard API */
export default async function copyToClipboard(text: string, log?: Logger): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (e) {
    log?.error(new Error('Copy to Clipboard failed', {cause: e}))
  }
  return false
}
