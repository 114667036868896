import {useLogger} from '@kensho/lumberjack'
import {useEffect, useState} from 'react'

interface MicrophoneVolumeProps {
  audioInputDeviceId?: string
}

function getVolume(dataArray: Uint8Array): number {
  const sum = dataArray.reduce((acc, value) => acc + value, 0)
  const average = sum / dataArray.length

  // frequency data is 0 to 255
  return average / 255
}

export default function useMicrophoneVolume({audioInputDeviceId}: MicrophoneVolumeProps): number {
  const [volume, setVolume] = useState<number>(0)
  const log = useLogger()

  useEffect(() => {
    const audioCtx = new AudioContext()
    const analyser = audioCtx.createAnalyser()
    analyser.fftSize = 32

    let source: MediaStreamAudioSourceNode | null = null

    // Request microphone access and connect the stream to the analyser.
    navigator.mediaDevices
      .getUserMedia({
        audio: {deviceId: audioInputDeviceId},
      })
      .then((stream) => {
        source = audioCtx.createMediaStreamSource(stream)
        source.connect(analyser)
      })
      .catch((error) => {
        log.error(new Error('Error accessing microphone', {cause: error}))
      })

    const dataArray = new Uint8Array(analyser.frequencyBinCount)

    // Sample the volume level every 200ms.
    const intervalId = setInterval(() => {
      analyser.getByteFrequencyData(dataArray)
      const newVolume = getVolume(dataArray)
      setVolume(newVolume)
    }, 200)

    return () => {
      clearInterval(intervalId)
      if (source) {
        source.disconnect(analyser)
      }
      analyser.disconnect()
      audioCtx.close()
    }
  }, [audioInputDeviceId, log])

  return volume
}
