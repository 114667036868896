import {useOutletContext, useParams} from 'react-router'

import BasePage from '../components/BasePage'
import {Mode, TranscriptionOutletContext} from '../types/types'
import {TranscriptionWithContext} from '../core/transcription/TranscriptionWithContext'

export default function TranscriptionPage(): React.ReactNode {
  const {
    mediaFile,
    setMediaFile,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    audioInputDeviceId,
    reset,
  } = useOutletContext<TranscriptionOutletContext>()
  const {transcriptId, mode = 'batch'} = useParams<{transcriptId: string; mode: Mode}>()

  if (!transcriptId) throw new Error('No transcriptId found')

  return (
    <BasePage footer={false}>
      <TranscriptionWithContext
        mode={mode}
        transcriptId={transcriptId}
        mediaFile={mediaFile}
        setMediaFile={setMediaFile}
        transcriptionConfiguration={transcriptionConfiguration}
        setTranscriptionConfiguration={setTranscriptionConfiguration}
        audioInputDeviceId={audioInputDeviceId}
        reset={reset}
      />
    </BasePage>
  )
}
