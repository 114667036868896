import {createContext} from 'react'

import {Stage, TranscriptMetadata} from '../types/types'

export type TranscriptionAction = {type: 'saved'; date: Date} | {type: 'setStage'; stage: Stage}

export interface TranscriptionState {
  metadata?: TranscriptMetadata
  stage: Stage
  lastSaved?: Date
}

export type TranscriptionContextValue = TranscriptionState & {
  dispatch: React.Dispatch<TranscriptionAction>
}

export default createContext<TranscriptionContextValue>({
  dispatch: () =>
    /* eslint-disable-next-line no-console */
    console.warn('You must wrap this component in TranscriptionProvider before using this method.'),
  metadata: {} as TranscriptMetadata,
  stage: 'TRANSCRIPTION',
})
