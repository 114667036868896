import {Patch} from 'immer'
import {reverse, snakeCase} from 'lodash-es'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import useMultiplayerContext from '../../../hooks/useMultiplayerContext'
import {JSONPatch, MultiplayerServerMessage, Operation, Revision} from '../../../types/schemas'
import {ScribeError, TranscriptMetadata} from '../../../types/types'
import isPrimitive from '../../../utils/isPrimitive'
import snakeCaseDeep from '../../../utils/snakeCaseDeep'
import {convertRevisionToPatches} from '../../../utils/revisionPatchConverter'

const SAVE_DELAY_MS = 1000

export interface TranscriptEditQueue {
  push: (revision: Revision) => void
}

/**
 * Keeps a buffer of transcript revisions and flushes them to the server every SAVE_DELAY_MS
 */
export default function useTranscriptEditQueue(options: {
  onError: (err: ScribeError) => void
  transcriptId: string
  /* whether revisions are flushed to the server */
  disabled?: boolean
  protocol: TranscriptMetadata['protocol']
}): TranscriptEditQueue {
  const {onError, transcriptId, disabled = false, protocol = 'v1'} = options
  const [prevTranscriptId, setPrevTranscriptId] = useState(transcriptId)
  const [queue, setQueue] = useState<Revision[]>([])
  const {authenticated, error, sessionId, sendMessage} = useMultiplayerContext(
    (message: MultiplayerServerMessage): void => {
      if (
        message.type !== 'error' ||
        (message.payload.code !== 'S2T-EDT-002' && message.payload.code !== 'S2T-EDT-003')
      )
        return

      onError({
        type:
          message.payload.code === 'S2T-EDT-002' ? 'saveTranscriptError' : 'saveTranscriptConflict',
        title: message.payload.title,
        detail: message.payload.detail,
      })
    },
  )

  // clear queue if transcriptId changes to avoid flushing revisions to the wrong transcript
  if (transcriptId !== prevTranscriptId) {
    setPrevTranscriptId(transcriptId)
    setQueue([])
  }

  const flushQueue = useCallback(() => {
    if (error || disabled || !sessionId || !authenticated || queue.length === 0) return

    if (protocol === 'v2') {
      queue.forEach((revision) => {
        // Two differences to transform for revisions stored on the client vs what the server expects:
        // 1. The parts of operation paths need to be converted to snake_case
        // 2. The shape, including non-primitive values, needs to be converted to snake_case
        const transformedRevision = {
          ...revision,
          operations: revision.operations.map((operation) => {
            if ('path' in operation) {
              return {
                ...operation,
                path: operation.path.map((part) => (!Number(part) ? snakeCase(part) : part)),
              } as Operation
            }
            return operation
          }),
        }
        sendMessage({
          type: 'revision',
          payload: {
            operations: transformedRevision.operations,
            version: transformedRevision.version,
          },
        })
      })
      setQueue([])
      return
    }

    const immerPatches = queue.reduce<Patch[]>((acc, revision) => {
      acc.push(...convertRevisionToPatches(revision))
      return acc
    }, [])
    setQueue([])

    // Optimize patches sent over the wire, by removing intermediary "replace" patches for the same path
    // WARNING - this optimization is only safe because of 2 guarantees:
    // 1) Immer restricts patches to a subset of what the JSONPatch spec allows
    //    which does not include the "move" or "copy" operations.
    // 2) The transcript object does not have any paths that could be either a primitive or non-primitive value

    // reverse so it's easier to keep the last patch
    reverse(immerPatches)
    const replacedPaths: Record<string, boolean> = {}
    const optimizedPatches = immerPatches.reduce<Patch[]>((acc, patch) => {
      const path = patch.path.join('/')

      if (replacedPaths[path]) return acc

      if (patch.op === 'replace' && !replacedPaths[path] && isPrimitive(patch.value)) {
        replacedPaths[path] = true
      }

      acc.push(patch)
      return acc
    }, [])
    // reverse the patches back so that they will be applied in the correct order
    reverse(optimizedPatches)

    // three differences that we need to transform for patches stored on the client vs what the server expects:
    // 1. path keys need to be converted to snake_case
    // 2. paths need to be converted to JSON pointer strings instead of arrays
    // 3. values that are objects/arrays need to be converted to snake_case
    const optimizedJSONPatches = optimizedPatches.map((patch) =>
      snakeCaseDeep({
        ...patch,
        path: Array.isArray(patch.path)
          ? `/${patch.path
              .map((part) => (!Number(part) ? snakeCase(`${part}`) : `${part}`))
              .join('/')}`
          : patch.path,
      }),
    ) as JSONPatch[]

    sendMessage({
      type: 'patch',
      payload: {
        patches: optimizedJSONPatches,
        sessionId,
      },
    })
  }, [authenticated, disabled, error, queue, sendMessage, sessionId, protocol])

  const flushRef = useRef<() => void>(flushQueue)
  useEffect(() => {
    flushRef.current = flushQueue
  }, [flushQueue])

  // periodically flush the queue
  useEffect(() => {
    if (disabled) return undefined

    const intervalId = window.setInterval(() => {
      flushRef.current()
    }, SAVE_DELAY_MS)

    return () => {
      window.clearInterval(intervalId)
      flushRef.current()
    }
  }, [disabled])

  return useMemo(
    () => ({
      push: (revision: Revision): void => {
        setQueue((prev) => [...prev, revision])
      },
    }),
    [],
  )
}
