import {RectSegment, Rect, RectAnnotationStyle} from './types'

export default function drawSegmentInBlock(
  ctx: CanvasRenderingContext2D,
  segment: RectSegment,
  style: RectAnnotationStyle,
  block: Rect,
): void {
  if (style.backgroundColor) {
    ctx.fillStyle = style.backgroundColor
    ctx.fillRect(segment.x - block.x, segment.y - block.y, segment.width, segment.height)
  }
}
