import {useLogger} from '@kensho/lumberjack'
import {memo, useCallback, use, useEffect} from 'react'
import {Icon} from '@kensho/neo'

import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import {APITranscript, APITranscriptToken, Stage, TranscriptSelection} from '../../../types/types'
import copyText from '../../../utils/copyText'
import formatCase from '../../../utils/formatCase'
import {produceEditorAction, TranscriptEditAction} from '../../../utils/transcriptRevisionUtils'
import {
  getTextFromTranscriptSelection,
  getTokenRange,
  getTranscriptSelection,
  isTimeInSlice,
} from '../../../utils/transcriptUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'
import {TOTAL_HEIGHT} from '../../../highlights/needs-review/constants'

import {useDispatchEditorAction} from './DispatchEditorActionProvider'
import TranscriptSlice from './TranscriptSlice'

interface InputEvent<T> extends React.FormEvent<T> {
  data: string
}

interface TranscriptProps {
  showPlayControls: boolean
  transcript: APITranscript
  stage: Stage
  currentTimeMs?: number
  paused: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  undo: () => void
  redo: () => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  transcriptSelection: TranscriptSelection | null
  onEditOperationError: (error: Error, action: TranscriptEditAction) => void
  isTranscriptFocused: boolean
  setIsTranscriptFocused: React.Dispatch<React.SetStateAction<boolean>>
  transcriptSizeRef: (node: HTMLElement | null) => void
  visibleBatches: Record<string, boolean>
  transcriptRef: React.RefObject<HTMLDivElement | null>
  handleTranscriptHighlightClick: (currentTranscriptSelection: TranscriptSelection) => void
}

function Transcript(props: TranscriptProps): React.ReactNode {
  const {
    transcript,
    onEditOperationError,
    stage,
    currentTimeMs = 0,
    onClickToken,
    seekMedia,
    showPlayControls,
    undo,
    redo,
    transcriptSelection,
    updateTranscriptSelection,
    setIsTranscriptFocused,
    transcriptSizeRef,
    visibleBatches,
    transcriptRef,
    handleTranscriptHighlightClick,
  } = props
  const log = useLogger()
  const {dispatchEditorAction} = useDispatchEditorAction()
  const {transcriptPermissions} = use(TranscriptPermissionsContext)

  useKeyboardShortcut(SHORTCUTS.Edit.undo.keys, () => {
    if (!transcriptPermissions.edit) return
    undo()
  })

  useKeyboardShortcut(SHORTCUTS.Edit.redo.keys, () => {
    if (!transcriptPermissions.edit) return
    redo()
  })

  useKeyboardShortcut(
    SHORTCUTS.Edit.toggleCapitalization.keys,
    () => {
      if (!transcriptPermissions.edit) return
      if (!transcriptSelection) return
      const {start, end} = transcriptSelection
      if (!start || !end) return

      const startSliceIndex = start.sliceIndex
      const endSliceIndex = end.sliceIndex
      let {tokenIndex: startTokenIndex} = start
      let {tokenIndex: endTokenIndex} = end

      // noop
      // foo[ ]bar
      if (
        start.type === 'token-space' &&
        end.type === 'token-space' &&
        start.tokenIndex === end.tokenIndex &&
        start.textOffset === 0 &&
        end.textOffset === 1
      ) {
        return
      }

      if (start.type === 'token-space' && start.textOffset > 0) {
        if (startTokenIndex + 1 < transcript.sliceMeta[startSliceIndex].tokenMeta.length) {
          // if the selection starts on a space adjust the token index to the next natural token
          // because the token-space index points to the preceding token index
          // foo |bar -> foo |Bar
          startTokenIndex += 1
          endTokenIndex += 1
        } else if (endSliceIndex === startSliceIndex && endTokenIndex === startTokenIndex) {
          // the selection is after the end of a slice which has no following natural token
          return
        }
      } else if (
        start.type === 'token-space' &&
        start.textOffset === 0 &&
        end.type !== 'token-space'
      ) {
        // foo[ b]ar -> foo[ B]ar
        startTokenIndex += 1
      }

      // if the selection ends on a space adjust the token index to the next natural token
      // because the token-space index points to the preceding token index
      // foo |bar -> foo |Bar
      if (
        end.type === 'token-space' &&
        end.textOffset > 0 &&
        (startSliceIndex !== endSliceIndex || startTokenIndex !== endTokenIndex) &&
        endTokenIndex + 1 < transcript.sliceMeta[endSliceIndex].tokenMeta.length
      ) {
        endTokenIndex += 1
      }

      const action: TranscriptEditAction = {
        type: 'replace-tokens',
        data: getTokenRange(transcript, {
          startToken: {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex},
          endToken: {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex},
        }),
      }

      const shouldCapitalize = /^[a-z]/.test(action.data[0].token.transcript)

      action.data = action.data.map((indexedToken) => ({
        ...indexedToken,
        token: {
          ...indexedToken.token,
          transcript: formatCase(indexedToken.token.transcript, shouldCapitalize),
        },
      }))

      const editorAction = produceEditorAction({
        action,
        transcript,
        transcriptSelection: getTranscriptSelection(),
        onError: onEditOperationError,
        log,
      })
      if (!editorAction) return

      dispatchEditorAction(editorAction)
    },
    {preventDefault: true},
  )

  const populateEmptyTranscript = useCallback(() => {
    if (!transcriptPermissions.edit) return

    const editorAction = produceEditorAction({
      action: {type: 'insert-text', data: ' '},
      transcript,
      transcriptSelection: {
        type: 'Caret',
        start: null,
        end: null,
      },
      log,
    })
    if (!editorAction) return

    dispatchEditorAction(editorAction)
  }, [transcript, transcriptPermissions, log, dispatchEditorAction])

  // keep transcript selection state in sync when window selection changes
  useEffect(() => {
    const handleSelection = (): void => {
      const selection = getTranscriptSelection()
      updateTranscriptSelection(selection, false, false)
    }
    document.addEventListener('selectionchange', handleSelection)
    return () => {
      document.removeEventListener('selectionchange', handleSelection)
    }
  }, [updateTranscriptSelection])

  // keep isTranscriptFocused state in sync
  useEffect(() => {
    const transcriptEle = transcriptRef.current

    const onFocusIn = (): void => setIsTranscriptFocused(true)
    const onFocusOut = (): void => setIsTranscriptFocused(false)

    transcriptEle?.addEventListener('focusin', onFocusIn)
    transcriptEle?.addEventListener('focusout', onFocusOut)

    return () => {
      transcriptEle?.removeEventListener('focusin', onFocusIn)
      transcriptEle?.removeEventListener('focusout', onFocusOut)
    }
  }, [setIsTranscriptFocused, transcriptRef])

  return (
    <div
      className="whitespace-pre-wrap"
      ref={(r) => {
        transcriptRef.current = r
        transcriptSizeRef(r)
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        data-clarity-mask="True"
        className="min-w-[752px] outline-none"
        style={{marginBottom: `${TOTAL_HEIGHT}px`}}
        data-testid="transcript"
        contentEditable
        suppressContentEditableWarning
        spellCheck={false}
        onClick={() => {
          const currentTranscriptSelection = getTranscriptSelection()
          if (!currentTranscriptSelection) return
          handleTranscriptHighlightClick(currentTranscriptSelection)
        }}
        onKeyDown={(event) => {
          if (!transcriptPermissions.edit) {
            event.preventDefault()
            return
          }
          if (
            event.key === 'a' &&
            event.metaKey &&
            !event.shiftKey &&
            !event.altKey &&
            transcript
          ) {
            const lastSliceIndex = Math.max(transcript.sliceMeta.length - 1, 0)
            const lastTokenIndex = Math.max(
              transcript.sliceMeta[lastSliceIndex].tokenMeta.length - 1,
              0,
            )
            updateTranscriptSelection(
              {
                type: 'Range',
                start: {type: 'token', sliceIndex: 0, tokenIndex: 0, textOffset: 0},
                end: {
                  type: 'token-space',
                  sliceIndex: lastSliceIndex,
                  tokenIndex: lastTokenIndex,
                  textOffset: 0,
                },
              },
              false,
              // explicitly setting force = false, because we don't render offscreen tokens that are not part of selection
              // so have to wait for effect to update selection and render those, otherwise we try too early
              // and return null because elements don't exist in DOM
              false,
            )
          }

          if (['Backspace', 'Delete', 'Enter'].includes(event.key)) {
            event.preventDefault()

            let action: TranscriptEditAction | undefined
            if (event.key === 'Backspace' || event.key === 'Delete') {
              action = {
                type: 'delete-text',
                direction: event.key === 'Backspace' ? 'backward' : 'forward',
              }
            }
            if (event.key === 'Enter') {
              action = {type: 'split-slice'}
            }
            if (!action) return

            const editorAction = produceEditorAction({
              action,
              transcript,
              transcriptSelection: getTranscriptSelection(),
              onError: onEditOperationError,
              log,
            })
            if (!editorAction) return

            dispatchEditorAction(editorAction)
          }
        }}
        onBeforeInput={(event) => {
          event.preventDefault()

          if (!transcriptPermissions.edit) return
          if (!transcriptSelection) return

          const editorAction = produceEditorAction({
            action: {type: 'insert-text', data: (event as InputEvent<HTMLDivElement>).data},
            transcript,
            transcriptSelection,
            onError: onEditOperationError,
            log,
          })
          if (!editorAction) return

          dispatchEditorAction(editorAction)
        }}
        onCopy={(event) => {
          event.preventDefault()

          if (!transcriptSelection) return

          // update the transcript selection first
          // in case window.getSelection() is not synced with transcriptSelection
          // this is only expected to happen after cmd+a due to our LOD token rendering
          // since copyText is giong to use window.getSelection() to restore
          updateTranscriptSelection(transcriptSelection, false, true)
          const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
          copyText(text, log)
        }}
        onCut={(event) => {
          event.preventDefault()

          if (!transcriptPermissions.edit) return
          if (!transcriptSelection) return

          // manually copy cut text to clipboard because we don't want cut event to modify dom
          const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
          copyText(text)

          const editorAction = produceEditorAction({
            action: {type: 'delete-text'},
            transcript,
            transcriptSelection,
            onError: onEditOperationError,
            log,
          })
          if (!editorAction) return

          dispatchEditorAction(editorAction)
        }}
        onPaste={(event) => {
          event.preventDefault()

          if (!transcriptPermissions.edit) return
          if (!transcriptSelection) return

          const editorAction = produceEditorAction({
            action: {type: 'insert-text', data: event.clipboardData.getData('text/plain')},
            transcript,
            transcriptSelection,
            onError: onEditOperationError,
            log,
          })
          if (!editorAction) return

          dispatchEditorAction(editorAction)
        }}
      >
        {stage === 'POST_TRANSCRIPTION' && transcript.sliceMeta.length === 0 ? (
          <div
            className="flex items-center justify-center gap-2 text-gray-400 italic"
            onClick={() => {
              if (!transcriptPermissions.edit) return
              populateEmptyTranscript()
            }}
            onKeyDown={(event) => {
              if (!transcriptPermissions.edit) return
              if (event.key === 'Enter') {
                populateEmptyTranscript()
              }
            }}
          >
            <Icon icon="PencilSquareIcon" />
            Transcript is empty. {transcriptPermissions.edit ? 'Click to add.' : ''}
          </div>
        ) : (
          (transcript.sliceMeta || []).map((slice, i) => {
            const hasSelectionBoundary =
              i === transcriptSelection?.start?.sliceIndex ||
              i === transcriptSelection?.end?.sliceIndex

            return (
              <TranscriptSlice
                hasSelectionBoundary={hasSelectionBoundary}
                showPlayControls={showPlayControls}
                key={`${slice.startMs}:${slice.durationMs}:${slice.accuracy}`}
                stage={stage}
                slice={slice}
                sliceIndex={i}
                currentTimeMs={
                  stage === 'POST_TRANSCRIPTION' &&
                  currentTimeMs !== 0 &&
                  isTimeInSlice(currentTimeMs, slice)
                    ? currentTimeMs
                    : undefined
                }
                onClickToken={onClickToken}
                seekMedia={seekMedia}
                visibleBatches={visibleBatches}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

export default memo(Transcript)
