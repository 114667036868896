import {use} from 'react'
import {camelCase} from 'lodash-es'
import {Patch} from 'immer'

import useMultiplayerContext from '../../../hooks/useMultiplayerContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import camelCaseDeep from '../../../utils/camelCaseDeep'
import {Operation, Revision} from '../../../types/schemas'
import {convertPatchesToRevision} from '../../../utils/revisionPatchConverter'

export default function useRemoteTranscriptChanges(): void {
  const {dispatch} = use(TranscriptContext)
  const {sessionId} = useMultiplayerContext((message) => {
    if (message.type !== 'patch' && message.type !== 'revision') return
    if (message.type === 'patch' && message.payload.sessionId === sessionId) return

    const revision: Revision =
      message.type === 'revision'
        ? {operations: message.payload.operations, version: message.payload.version}
        : convertPatchesToRevision(
            // Three differences that we need to transform for patches on the server vs what the client expects:
            // 1. path keys need to be converted to camelCase
            // 2. paths need to be converted to arrays instead of JSON pointer strings
            // 3. non-primitive values need to be converted to camelCase
            message.payload.patches.map(
              (patch) =>
                camelCaseDeep({
                  ...patch,
                  path: patch.path
                    .split('/')
                    .slice(1)
                    .map((part) => (!Number(part) ? camelCase(part) : part)),
                }) as Patch,
            ),
          )

    // Two differences to transform for revisions from the server vs what the client expects:
    // 1. The parts of operation paths need to be converted to camelCase
    // 2. The shape, including non-primitive values, needs to be converted to camelCase
    const transformedRevision = {
      ...revision,
      operations: revision.operations.map((operation) => {
        if ('path' in operation) {
          return {
            ...operation,
            path: operation.path.map((part) => (!Number(part) ? camelCase(part) : part)),
          } as Operation
        }
        return operation
      }),
    }

    dispatch({
      type: 'revision',
      editorAction: {
        beforeSelection: null,
        afterSelection: null,
        revision: transformedRevision,
        inverseRevision: transformedRevision,
        selectionChangeDisabled: true,
        undoable: false,
      },
    })
  })
}
