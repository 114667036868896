/* eslint-disable no-param-reassign */
import {cloneDeep, get, set, unset} from 'lodash-es'

import {DURATION_PER_CHAR_MS, DURATION_TOKEN_GAP_MS} from '../core/transcription/constants'
import {Operation, Revision, TextPosition} from '../types/schemas'
import {APITranscript} from '../types/types'

import assertNever from './assertNever'
import {calcSliceTimes, mergeSlices, mergeTokens} from './transcriptRevisionUtils'

function applyObjectSet(
  {path, value}: Extract<Operation, {type: 'object-set'}>,
  transcript: APITranscript,
): APITranscript {
  if (path.length === 0) return cloneDeep(value)
  set(transcript, path, cloneDeep(value))
  return transcript
}

function applyObjectDelete(
  {path}: Extract<Operation, {type: 'object-delete'}>,
  transcript: APITranscript,
): APITranscript {
  unset(transcript, path)
  return transcript
}

function applyArrayInsert(
  {path, index, values}: Extract<Operation, {type: 'array-insert'}>,
  transcript: APITranscript,
): APITranscript {
  const arr = get(transcript, path)
  if (index > arr.length || index < 0)
    throw new Error(`array-insert: index ${index} out of bounds for array length ${arr.length}`)

  arr.splice(index, 0, ...cloneDeep(values))
  return transcript
}

function applyArrayDelete(
  {path, startIndex, endIndex}: Extract<Operation, {type: 'array-delete'}>,
  transcript: APITranscript,
): APITranscript {
  const arr = get(transcript, path)
  if (startIndex > endIndex)
    throw new Error(
      `array-delete: startIndex (${startIndex}) must be less than or equal to endIndex (${endIndex})`,
    )
  if (startIndex > arr.length || startIndex < 0 || endIndex > arr.length || endIndex < 0)
    throw new Error(
      `array-insert: startIndex ${startIndex} or endIndex ${endIndex} out of bounds for array length ${arr.length}`,
    )

  arr.splice(startIndex, endIndex - startIndex + 1)
  return transcript
}

function applyStringInsert(
  {path, startPos, string}: Extract<Operation, {type: 'string-insert'}>,
  transcript: APITranscript,
): APITranscript {
  const prevText = get(transcript, path)
  if (startPos > prevText.length || startPos < 0)
    throw new Error(
      `string-insert: startPos ${startPos} out of bounds for string length ${prevText.length}`,
    )

  set(transcript, path, prevText.slice(0, startPos) + string + prevText.slice(startPos))
  return transcript
}

function applyStringDelete(
  {path, startPos, endPos}: Extract<Operation, {type: 'string-delete'}>,
  transcript: APITranscript,
): APITranscript {
  const prevText = get(transcript, path)
  if (startPos > endPos)
    throw new Error(
      `array-delete: startPos (${startPos}) must be less than or equal to endPos (${endPos})`,
    )
  if (startPos > prevText.length || startPos < 0 || endPos > prevText.length || endPos < 0)
    throw new Error(
      `string-insert: startPos ${startPos} or endPos ${endPos} out of bounds for string length ${prevText.length}`,
    )

  set(transcript, path, prevText.slice(0, startPos) + prevText.slice(endPos))

  return transcript
}

function applyRangeAddAnnotation(
  {annotationId, start, end}: Extract<Operation, {type: 'annotation-add'}>,
  transcript: APITranscript,
): APITranscript {
  const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = start
  const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = end

  if (startSliceIndex > endSliceIndex)
    throw new Error(
      `annotation-add: start sliceIndex (${startSliceIndex}) must be less than or equal to end sliceIndex (${endSliceIndex})`,
    )

  for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
    const slice = transcript.sliceMeta[sliceIndex]

    if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
      const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
      const endTokenIndexInSlice =
        sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

      for (
        let tokenIndex = startTokenIndexInSlice;
        tokenIndex <= endTokenIndexInSlice;
        tokenIndex += 1
      ) {
        const token = slice.tokenMeta[tokenIndex]
        // add token annotations container if it doesn't exist
        if (!token.annotations) {
          token.annotations = []
        }

        // add annotation id to token annotations, if not already included
        if (!token.annotations.find((a) => a.id === annotationId)) {
          token.annotations.push({id: annotationId})
        }
      }
    } else {
      // add slice annotations container if it doesn't exist
      if (!slice.annotations) {
        slice.annotations = []
      }

      // add annotation id to slice annotations, if not already included
      if (!slice.annotations.find((a) => a.id === annotationId)) {
        slice.annotations.push({id: annotationId})
      }
    }
  }
  return transcript
}

function applyRangeRemoveAnnotation(
  {annotationId, start, end}: Extract<Operation, {type: 'annotation-remove'}>,
  transcript: APITranscript,
): APITranscript {
  const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = start
  const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = end

  if (startSliceIndex > endSliceIndex)
    throw new Error(
      `annotation-add: start sliceIndex (${startSliceIndex}) must be less than or equal to end sliceIndex (${endSliceIndex})`,
    )

  for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
    const slice = transcript.sliceMeta[sliceIndex]

    if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
      const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
      const endTokenIndexInSlice =
        sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

      for (
        let tokenIndex = startTokenIndexInSlice;
        tokenIndex <= endTokenIndexInSlice;
        tokenIndex += 1
      ) {
        // remove annotation pointer from each token within the current slice
        const token = slice.tokenMeta[tokenIndex]
        if (token.annotations) {
          token.annotations = token.annotations.filter((a) => a.id !== annotationId)
        }
      }
    } else if (slice.annotations) {
      // remove annotation pointer from current slice
      slice.annotations = slice.annotations.filter((a) => a.id !== annotationId)
    }
  }

  return transcript
}

function applyTextDelete(
  {start: originalStart, end: originalEnd}: Extract<Operation, {type: 'text-delete'}>,
  transcript: APITranscript,
): APITranscript {
  // delete at slice level, then token level, then character level

  let start = {...originalStart}
  let end = {...originalEnd}

  // merge and/or delete at slice level
  if (start.sliceIndex !== end.sliceIndex) {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex, pos: startPos} = start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex, pos: endPos} = end
    const isPartialStartSlice = startTokenIndex > 0 || startPos > 0
    const isPartialEndSlice =
      endTokenIndex < transcript.sliceMeta[endSliceIndex].tokenMeta.length - 1 ||
      endPos < transcript.sliceMeta[endSliceIndex].tokenMeta[endTokenIndex].transcript.length

    if (isPartialStartSlice && isPartialEndSlice) {
      // remove all slices and insert a merge of both end slices
      // slice[1 slice2 slice]3 -> slice[1slice]3

      const leftSlice = transcript.sliceMeta[startSliceIndex]
      const rightSlice = transcript.sliceMeta[endSliceIndex]

      // remove all slices from the selection (inclusive)
      transcript.sliceMeta.splice(startSliceIndex, endSliceIndex - startSliceIndex + 1)

      // insert the merged slice
      const mergedSlice = mergeSlices(leftSlice, rightSlice)
      transcript.sliceMeta.splice(startSliceIndex, 0, mergedSlice)

      // update the selection
      end.sliceIndex = startSliceIndex
      end.tokenIndex += leftSlice.tokenMeta.length
    } else if (isPartialEndSlice) {
      // remove all slices except last
      // [slice1 slice]2 -> [slice]2
      transcript.sliceMeta.splice(startSliceIndex, endSliceIndex - startSliceIndex)

      // update the selection
      start.tokenIndex = 0
      start.pos = 0
      end.sliceIndex = start.sliceIndex
    } else {
      // remove all slices except first
      // slice[1 slice2] -> slice[1]
      // [slice1 slice2] -> [slice1]

      transcript.sliceMeta.splice(startSliceIndex + 1, endSliceIndex - startSliceIndex)

      // update the selection
      end.sliceIndex = startSliceIndex
      end.tokenIndex = transcript.sliceMeta[startSliceIndex].tokenMeta.length - 1
      end.pos =
        transcript.sliceMeta[startSliceIndex].tokenMeta[
          transcript.sliceMeta[startSliceIndex].tokenMeta.length - 1
        ].transcript.length
    }
  }

  // range to be deleted is within a single slice at this point
  // merge and/or delete at token level
  if (start.tokenIndex !== end.tokenIndex) {
    const {tokenIndex: startTokenIndex, pos: startPos} = start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex, pos: endPos} = end
    const slice = transcript.sliceMeta[endSliceIndex]
    const startTokenLength = slice.tokenMeta[startTokenIndex].transcript.length
    // f[oo or foo[ not [foo
    const isPartialStartToken = startPos > 0 && startTokenLength > 0
    const endTokenLength = slice.tokenMeta[endTokenIndex].transcript.length
    // ]bar or ba]r not bar]
    const isPartialEndToken = endPos < slice.tokenMeta[endTokenIndex].transcript.length

    if (
      (isPartialStartToken && isPartialEndToken) ||
      (!isPartialStartToken && !isPartialEndToken)
    ) {
      // remove all tokens and insert a merge of both end tokens
      // f[oo ba]r -> f[ooba]r
      // foo[ bar b]az -> foo[b]az
      // f[oo bar ]baz -> f[oo]baz
      // foo[ bar ]baz -> foo|baz
      // lorem [foo bar] baz -> lorem [foobar] baz

      const leftToken = slice.tokenMeta[startTokenIndex]
      const rightToken = slice.tokenMeta[endTokenIndex]

      // remove all tokens from the selection (inclusive)
      slice.tokenMeta.splice(startTokenIndex, endTokenIndex - startTokenIndex + 1)

      // insert the merged token
      slice.tokenMeta.splice(startTokenIndex, 0, mergeTokens(leftToken, rightToken))

      // update the selection
      if (
        startPos ===
          transcript.sliceMeta[endSliceIndex].tokenMeta[startTokenIndex].transcript.length &&
        endPos === 0
      ) {
        // foo[ bar ]baz -> foo|baz
        start = {
          sliceIndex: endSliceIndex,
          tokenIndex: startTokenIndex,
          pos: 0,
        }
        end = cloneDeep(start)
      } else {
        end.tokenIndex = startTokenIndex
        end.pos += leftToken.transcript.length
      }
    } else if (isPartialEndToken) {
      // delete all tokens except last
      // [foo b]ar -> [b]ar
      // [foo ]bar -> |bar

      // remove all tokens from the selection except last
      slice.tokenMeta.splice(startTokenIndex, endTokenIndex - startTokenIndex)

      // update the selection
      if (startTokenLength === 0 || endPos === 0) {
        // [foo ]bar -> |bar
        end = cloneDeep(start)
      } else {
        start.tokenIndex = startTokenIndex
        start.pos = 0
      }
    } else {
      // delete all tokens except first
      // [foo bar ]baz -> [foo] baz
      // f[oo bar] baz -> f[oo] baz

      // remove all tokens from the selection except first
      slice.tokenMeta.splice(startTokenIndex + 1, endTokenIndex - startTokenIndex)

      // update the selection
      if (endTokenLength === 0) {
        // foo[ ] baz -> foo| baz
        end = cloneDeep(start)
      } else {
        end.tokenIndex = startTokenIndex
        end.pos = transcript.sliceMeta[endSliceIndex].tokenMeta[startTokenIndex].transcript.length
      }
    }
  }

  // range to be deleted is within a single token at this point
  // delete the characters
  if (start.pos !== end.pos) {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex, pos: startPos} = start
    const {pos: endPos} = end
    const token = transcript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex]
    token.transcript = `${token.transcript.substring(0, startPos)}${token.transcript.substring(endPos)}`
  }

  // update selection after all deletions have been made
  end = cloneDeep(start)

  // if the resulting token was touched by the delete then update it
  // the token is not considered to be touched if the selection was:
  // foo [bar ]baz -> foo |baz
  // foo[ bar] baz -> foo| baz
  // foo [\n]bar -> foo |bar (forwards delete at the of slice)
  if (
    !(
      (originalStart.tokenIndex !== originalEnd.tokenIndex ||
        originalStart.sliceIndex !== originalEnd.sliceIndex) &&
      (start.pos === 0 ||
        start.pos ===
          transcript.sliceMeta[end.sliceIndex].tokenMeta[end.tokenIndex].transcript.length) &&
      end.pos !== transcript.sliceMeta[end.sliceIndex].tokenMeta[end.tokenIndex].transcript.length
    )
  ) {
    const token = transcript.sliceMeta[end.sliceIndex].tokenMeta[end.tokenIndex]

    // update the token accuracy to 100%
    token.accuracy = 1

    // update the token duration if two tokens were merged together
    if (
      originalStart.tokenIndex !== originalEnd.tokenIndex ||
      originalStart.sliceIndex !== originalEnd.sliceIndex
    ) {
      token.durationMs = token.transcript.length * DURATION_PER_CHAR_MS
    }
  }

  // update the slice duration if two slices were merged together
  if (originalStart.sliceIndex !== originalEnd.sliceIndex) {
    const slice = transcript.sliceMeta[start.sliceIndex]
    const {startMs, durationMs} = calcSliceTimes(slice)
    slice.startMs = startMs
    slice.durationMs = durationMs
  }

  return transcript
}

export function splitSlice(textPosition: TextPosition, transcript: APITranscript): void {
  const {sliceIndex, tokenIndex, pos} = textPosition

  const leftSlice = transcript.sliceMeta[sliceIndex]
  const rightSlice = {...leftSlice}

  const secondTokens = leftSlice.tokenMeta.splice(tokenIndex + 1)
  rightSlice.tokenMeta = secondTokens
  transcript.sliceMeta.splice(sliceIndex + 1, 0, rightSlice)

  const tokenLength = transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.length
  if (pos > 0 && pos < tokenLength) {
    // if the caret was in the middle of the token split the token and append both appropriately
    const firstToken = transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
    const secondToken = {...firstToken}

    // split the timestamp of the token proportional to character offset
    const originalDuration = firstToken.durationMs
    firstToken.durationMs = Math.floor(firstToken.durationMs * (pos / tokenLength))
    secondToken.startMs = firstToken.startMs + originalDuration
    secondToken.durationMs = originalDuration - firstToken.durationMs

    firstToken.transcript = firstToken.transcript.slice(0, pos)
    firstToken.accuracy = 1
    secondToken.transcript = secondToken.transcript.slice(pos)
    secondToken.accuracy = 1

    rightSlice.tokenMeta.unshift(secondToken)
  } else if (pos === 0) {
    // if the caret was at the start of the token move the token to the next slice
    const leftSliceLastToken = transcript.sliceMeta[sliceIndex].tokenMeta.splice(tokenIndex, 1)[0]
    rightSlice.tokenMeta.unshift(leftSliceLastToken)
  }

  // put token in left slice if it became empty
  if (!leftSlice.tokenMeta.length) {
    leftSlice.tokenMeta.push({
      transcript: ' ',
      startMs: leftSlice.startMs,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    })
  }

  const leftSliceTimings = calcSliceTimes(leftSlice)
  leftSlice.startMs = leftSliceTimings.startMs
  leftSlice.durationMs = leftSliceTimings.durationMs

  // put token in right slice if it is empty
  if (!rightSlice.tokenMeta.length) {
    rightSlice.tokenMeta.push({
      transcript: '',
      startMs: leftSlice.startMs + leftSlice.durationMs + DURATION_TOKEN_GAP_MS,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    })
  }

  const rightSliceTimings = calcSliceTimes(rightSlice)
  rightSlice.startMs = rightSliceTimings.startMs
  rightSlice.durationMs = rightSliceTimings.durationMs

  // hack: add a salt to slice.accuracy to use as a psuedo-unique key
  // this is necessary to prevent react key collisions, because slices don't have unique IDs
  // can get into this situation when creating multiple empty slices in a row
  leftSlice.accuracy = Number((leftSlice.accuracy || 0).toFixed(2)) + Math.random() / 100
  rightSlice.accuracy = Number((leftSlice.accuracy || 0).toFixed(2)) + Math.random() / 100
}

export function splitToken(textPosition: TextPosition, transcript: APITranscript): void {
  const {sliceIndex, tokenIndex, pos} = textPosition

  const originalToken = transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
  const leftToken = cloneDeep(originalToken)
  const rightToken = cloneDeep(originalToken)

  leftToken.transcript = leftToken.transcript.slice(0, pos)
  leftToken.accuracy = 1
  leftToken.durationMs = leftToken.transcript.length * DURATION_PER_CHAR_MS
  leftToken.aligned = false

  rightToken.transcript = rightToken.transcript.slice(pos)
  rightToken.accuracy = 1
  rightToken.startMs = leftToken.startMs + leftToken.durationMs + DURATION_TOKEN_GAP_MS
  rightToken.durationMs = !rightToken.transcript.trim()
    ? 0
    : rightToken.transcript.length * DURATION_PER_CHAR_MS
  rightToken.aligned = false

  // delete original token and add split tokens
  transcript.sliceMeta[sliceIndex].tokenMeta.splice(tokenIndex, 1, leftToken, rightToken)
}

function applyTextInsert(
  {start, text}: Extract<Operation, {type: 'text-insert'}>,
  transcript: APITranscript,
): APITranscript {
  let {sliceIndex, tokenIndex, pos} = start
  text.split('').forEach((character, i) => {
    if (character === ' ') {
      // ignore repeating spaces and newlines
      if (text[i - 1] === ' ' || text[i - 1] === '\n') return
      splitToken({sliceIndex, tokenIndex, pos}, transcript)
      tokenIndex += 1
      pos = 0
    } else if (character === '\n') {
      // ignore repeating newlines
      if (text[i - 1] === '\n') return
      splitSlice({sliceIndex, tokenIndex, pos}, transcript)
      sliceIndex += 1
      tokenIndex = 0
      pos = 0
    } else {
      // add character to current token at offset
      transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].accuracy = 1
      transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript =
        `${transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.slice(0, pos)}${character}${transcript.sliceMeta[
          sliceIndex
        ].tokenMeta[tokenIndex].transcript.slice(pos)}`
      pos += 1
    }
  })

  return transcript
}

/** Applies the operation, mutating the transcript. */
export function applyOperation(
  operation: Operation | null,
  transcript: APITranscript,
): APITranscript {
  if (!operation) return transcript

  switch (operation.type) {
    case 'object-set':
      return applyObjectSet(operation, transcript)
    case 'object-delete':
      return applyObjectDelete(operation, transcript)
    case 'array-insert':
      return applyArrayInsert(operation, transcript)
    case 'array-delete':
      return applyArrayDelete(operation, transcript)
    case 'string-insert':
      return applyStringInsert(operation, transcript)
    case 'string-delete':
      return applyStringDelete(operation, transcript)
    case 'annotation-add':
      return applyRangeAddAnnotation(operation, transcript)
    case 'annotation-remove':
      return applyRangeRemoveAnnotation(operation, transcript)
    case 'text-delete':
      return applyTextDelete(operation, transcript)
    case 'text-insert':
      return applyTextInsert(operation, transcript)
    default:
      assertNever(operation, 'Unhandled operation type')
  }

  return transcript
}

/** Applies the revision, mutating the transcript. */
export default function applyRevision(
  revision: Revision | null,
  transcript: APITranscript,
): APITranscript {
  if (!revision) return transcript
  return revision.operations.reduce((acc, operation) => applyOperation(operation, acc), transcript)
}
