import {useCallback, useEffect, useMemo, useState} from 'react'
import {HeadlessButton, Popover} from '@kensho/neo'
import {isEqual} from 'lodash-es'

import {ConnectedUser} from '../../../providers/MultiplayerProvider'
import useClickOutside from '../../../hooks/useClickOutside'
import UserPresence from '../../../components/UserPresence'
import {APITranscript, TranscriptSelection} from '../../../types/types'

interface UserPresenceListProps {
  connectedUsers: ConnectedUser[]
  currentUserSessionId: string | null
  scrollTranscriptToSelection: (
    selection: TranscriptSelection,
    options?: {top?: number; bottom?: number},
  ) => Promise<void>
  transcript: APITranscript
}

export default function UserPresenceList(props: UserPresenceListProps): React.ReactNode {
  const {connectedUsers, currentUserSessionId, scrollTranscriptToSelection, transcript} = props
  const [followedUser, setFollowedUser] = useState<string | null>(null)
  const [showAllUsers, setShowAllUsers] = useState(false)

  const sortedUsers = useMemo(() => {
    const sorted = []
    const sortedConnectedUsers = [...connectedUsers].sort((a, b) =>
      a.clientName < b.clientName ? -1 : 1,
    )
    for (const connectedUser of sortedConnectedUsers) {
      if (connectedUser.sessionId === currentUserSessionId) {
        // put this session first
        sorted.splice(0, 0, connectedUser)
      } else if (followedUser && followedUser === connectedUser.sessionId) {
        // put followed session second
        sorted.splice(
          sorted[0] && sorted[0].sessionId === currentUserSessionId ? 1 : 0,
          0,
          connectedUser,
        )
      } else {
        // append the rest
        sorted.push(connectedUser)
      }
    }
    return sorted
  }, [connectedUsers, currentUserSessionId, followedUser])

  // mousedown (instead of click) because closing the popover sets showAllUsers to false before this callback is called
  const clickOutsideRef = useClickOutside(
    () => {
      if (!showAllUsers) setFollowedUser(null)
    },
    {eventType: 'mousedown'},
  )

  const onUserClick = useCallback(
    (sessionId: string): void => {
      if (sessionId === currentUserSessionId) return
      setFollowedUser(sessionId === followedUser ? null : sessionId)
    },
    [currentUserSessionId, followedUser],
  )

  useEffect(() => {
    if (!followedUser) return

    const fUser = connectedUsers.find((user) => user.sessionId === followedUser)
    if (!fUser || !fUser.cursorPosition || !fUser.cursorPosition.start) return

    // check if it is a caret or a range and then build the TranscriptSelection
    const sel: TranscriptSelection = {
      type: isEqual(fUser.cursorPosition.start, fUser.cursorPosition.end) ? 'Caret' : 'Range',
      start: fUser.cursorPosition.start,
      end: fUser.cursorPosition.end,
    }

    scrollTranscriptToSelection(sel, {top: 128, bottom: 24})
  }, [connectedUsers, scrollTranscriptToSelection, followedUser, transcript])

  return (
    <div className="flex gap-1" ref={clickOutsideRef}>
      {sortedUsers.slice(0, 3).map((user: ConnectedUser) => (
        <HeadlessButton
          key={user.sessionId}
          type="button"
          className="relative"
          onClick={() => onUserClick(user.sessionId)}
        >
          <div className="flex items-center gap-2">
            <UserPresence
              user={user}
              isFollowing={user.sessionId === followedUser}
              isCurrentUser={currentUserSessionId === user.sessionId}
            />
          </div>
        </HeadlessButton>
      ))}

      {sortedUsers.length > 3 && (
        <Popover
          content={
            <div className="flex flex-col gap-2 p-4">
              {sortedUsers.map((user: ConnectedUser) => (
                <HeadlessButton
                  key={user.sessionId}
                  type="button"
                  onClick={() => onUserClick(user.sessionId)}
                >
                  <UserPresence
                    user={user}
                    isDetailed
                    isCurrentUser={currentUserSessionId === user.sessionId}
                    isFollowing={user.sessionId === followedUser}
                    enablePopover={false}
                  />

                  {user.sessionId === currentUserSessionId && (
                    <hr className="my-1 border-t border-gray-400" />
                  )}
                </HeadlessButton>
              ))}
            </div>
          }
          isOpen={showAllUsers}
          onOpenChange={setShowAllUsers}
        >
          <HeadlessButton className="relative">
            <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-200">
              {sortedUsers.length - 3}
            </div>
          </HeadlessButton>
        </Popover>
      )}
    </div>
  )
}
