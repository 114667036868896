import {Navigate, useParams} from 'react-router'

export default function RedirectTranscriptionPage(): React.ReactNode {
  const {transcriptId} = useParams()

  return (
    <Navigate
      to={transcriptId !== undefined ? `/transcriptions/${transcriptId}` : '/transcriptions'}
      replace
    />
  )
}
