import {useLogger} from '@kensho/lumberjack'
import {Button, Tooltip} from '@kensho/neo'
import {useElementSize, usePrevious} from '@kensho/tacklebox'
import clsx from 'clsx'
import {Axis, axisBottom} from 'd3-axis'
import {ScaleLinear, scaleLinear} from 'd3-scale'
import {select, selectAll} from 'd3-selection'
import {D3ZoomEvent, ZoomBehavior, zoom as d3Zoom, zoomIdentity, zoomTransform} from 'd3-zoom'
import {cloneDeep, isEqual, last, uniqBy} from 'lodash-es'
import {useCallback, use, useEffect, useMemo, useRef, useState} from 'react'

import {APITranscript, APITranscriptToken} from '../../../types/types'
import assertNever from '../../../utils/assertNever'
import isMac from '../../../utils/isMac'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {getDuration, getTokenRange, indexFromPath} from '../../../utils/transcriptUtils'
import {useDispatchEditorAction} from '../transcript/DispatchEditorActionProvider'
import useFocusWithin from '../../../hooks/useFocusWithin'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import MouseHoverLine from './MouseHoverLine'
import PlaybackBrush from './PlaybackBrush'
import TimestampGraphToken from './TimestampGraphToken'
import {AXIS_HEIGHT, MIN_TOKEN_DURATION_MS} from './constants'
import {formatTimestamp} from './timestampUtils'
import ContextMenu from './ContextMenu'
import {TokenLocation} from './types'

interface Props {
  transcript: APITranscript
  currentTimeMs: number
  mediaEle: HTMLMediaElement | null
  paused: boolean
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
}

type AlignTokensAction = 'move' | 'resize-start' | 'resize-end' | 'set'

export interface DragState {
  x: number
  y: number
  dx: number
  dy: number
  isDragging: boolean
  /** the selection could contain multiple tokens but this is the selectioncnud contain multiple tokens but this is the specific one that was clicked */
  target: TokenLocation | null
  action?: 'move' | 'resize-start' | 'resize-end' | 'set'
}

function tokenLocationToPath({sliceIndex, tokenIndex}: TokenLocation): string {
  return `/sliceMeta/${sliceIndex}/tokenMeta/${tokenIndex}`
}

function getToken(
  transcript: APITranscript,
  {sliceIndex, tokenIndex}: TokenLocation,
): APITranscriptToken | null {
  return transcript.sliceMeta[sliceIndex]?.tokenMeta[tokenIndex] ?? null
}

export default function TimestampManager(props: Props): React.ReactNode {
  const {transcript, currentTimeMs, mediaEle, paused, seekMedia} = props
  const log = useLogger()
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {dispatchEditorAction} = useDispatchEditorAction()
  const [{width, height}, containerSizeRef] = useElementSize()
  const [isAltKey, setIsAltKey] = useState(false)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const zoomEleRef = useRef<SVGRectElement | null>(null)
  const zoomRef = useRef<ZoomBehavior<SVGRectElement, number> | null>(null)
  const axisElementRef = useRef<SVGGElement | null>(null)
  const playbackLineRef = useRef<SVGLineElement | null>(null)
  const [isFocusWithin, isFocusWithinRef] = useFocusWithin()
  const [isHovering, setIsHovering] = useState(false)
  const [drag, setDrag] = useState<DragState>({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    isDragging: false,
    target: null,
    action: 'move',
  })
  const [contextMenuPosition, setContextMenuPosition] = useState<{x: number; y: number} | null>(
    null,
  )
  const [isAltDragMode, setIsAltDragMode] = useState(false)
  const [xScale, setXScale] = useState<ScaleLinear<number, number, number> | null>(null)
  const [selectedTokens, setSelectedTokens] = useState<TokenLocation[]>([])
  const [boundPlayback, setBoundPlayback] = useState(false)
  const prevTimeMs = usePrevious(currentTimeMs)
  const [syncViewToTranscriptTime, setSyncViewToTranscriptTime] = useState(true)

  const transcriptDurationMs = useMemo(() => getDuration(transcript), [transcript])

  // create/connect D3 systems to chart elements
  useEffect(() => {
    if (!svgRef.current || !axisElementRef.current || !zoomEleRef.current) return

    const svg = select(svgRef.current)
    const axis = select(axisElementRef.current)
    const zoomSelection = select<SVGRectElement, number>(zoomEleRef.current)

    const scale = scaleLinear().domain([0, transcriptDurationMs]).range([0, width])
    setXScale(() => scale)

    /* @ts-ignore ts(2769) @types/d3-axis are incorrect */
    const xAxis = axisBottom(scale).tickFormat((value: number, _, ticks: SVGTextElement[]) =>
      value >= 0
        ? formatTimestamp(
            value,
            // if any tick has milliseconds, then display all ticks with milliseconds
            ticks &&
              selectAll<SVGTextElement, number>(ticks)
                .data()
                .some((d) => d % 1000 !== 0),
          )
        : '',
    ) as Axis<number>

    axis.call(xAxis)

    // instantiate D3 zoom behavior
    zoomRef.current = d3Zoom<SVGRectElement, number>()
      .extent([
        [0, 0],
        [width, height],
      ])
      .translateExtent([
        [-0.25, 0],
        [width + 0.25, height],
      ])
      .scaleExtent([
        transcriptDurationMs / width / (40000 / width), // 40 seconds
        transcriptDurationMs / width / (2000 / width), // 2 seconds
      ])
      .on('zoom', (event: D3ZoomEvent<SVGGElement, number>) => {
        // prevent zooming while setting token start/end
        if (event.sourceEvent?.type === 'mousemove' && event.sourceEvent?.altKey) return
        // override default wheel event handler to enable horizontal scroll
        if (event.sourceEvent?.type === 'wheel') return

        const newXScale = event.transform.rescaleX(scale)
        svg.select<SVGGElement>('.x-axis').call(xAxis.scale(newXScale))
        setXScale(() => newXScale)
      })

    if (!zoomRef.current) return

    const prevZoomTransform = zoomTransform(zoomEleRef.current)
    let nextZoomTransform = zoomIdentity
    if (isEqual(prevZoomTransform, zoomIdentity) || Number.isNaN(prevZoomTransform.k)) {
      // set to default if not yet zoomed/panned
      nextZoomTransform = zoomIdentity
        .translate(100, 0)
        .scale(transcriptDurationMs / width / (5000 / width))
    } else {
      // TODO: figure out how to make responsive when viewport or duration changes
      nextZoomTransform = prevZoomTransform
    }

    zoomSelection
      .call(zoomRef.current)
      // set initial transform to center the view
      .call(zoomRef.current.transform, nextZoomTransform)
      // override default wheel event handler to enable horizontal scroll to pan as well as vertical scroll to zoom
      .on('wheel.zoom', (event: WheelEvent) => {
        event.preventDefault()
        if (!zoomRef.current || !zoomEleRef.current) return

        if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
          event.stopImmediatePropagation()
          zoomRef.current.translateBy(zoomSelection, event.deltaX * -1, 0)
        } else if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) {
          zoomRef.current.scaleBy(
            zoomSelection,
            (zoomTransform(zoomEleRef.current).k / zoomTransform(zoomEleRef.current).k) *
              2 ** (-event.deltaY * 0.002),
          )
        }
      })
  }, [width, height, transcriptDurationMs])

  // keep playback line indicator in sync with media playback
  // this imperatively positions the playback line element outside of the render cycle
  useEffect(() => {
    if (paused) return undefined
    if (!playbackLineRef.current) return undefined
    if (!mediaEle) return undefined

    // if audio is playing, update playback line every frame
    const intervalId = window.setInterval(() => {
      if (!xScale) return
      const x = xScale(mediaEle.currentTime * 1000)
      playbackLineRef.current?.setAttribute('x1', String(x))
      playbackLineRef.current?.setAttribute('x2', String(x))
    }, 1000 / 60)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [mediaEle, paused, currentTimeMs, xScale])

  // clear alt keydown state when mouse leaves the container
  useEffect(() => {
    if (!isHovering) {
      setIsAltKey(false)
    }
  }, [isHovering])

  const flatTokens = useMemo<TokenLocation[]>(
    () =>
      transcript.sliceMeta.reduce(
        (acc, slice, sliceIndex) => [
          ...acc,
          ...slice.tokenMeta.map((_, tokenIndex) => ({
            sliceIndex,
            tokenIndex,
          })),
        ],
        [] as TokenLocation[],
      ),
    [transcript],
  )

  const filteredTokens = useMemo<TokenLocation[]>(() => {
    if (xScale == null) return []
    const domain = xScale.domain()

    return flatTokens.filter((tokenLocation) => {
      const token = getToken(transcript, tokenLocation)
      return token && token.startMs + token.durationMs >= domain[0] && token.startMs <= domain[1]
    })
  }, [flatTokens, xScale, transcript])

  const alignTokens = useCallback(
    (
      tokenLocations: TokenLocation[],
      action: AlignTokensAction,
      deltaOrStart: number, // milliseconds
      end = MIN_TOKEN_DURATION_MS, // milliseconds
    ): void => {
      if (!transcriptPermissions.edit) return

      /* eslint-disable no-param-reassign */
      deltaOrStart = Math.round(deltaOrStart)
      if (end !== undefined) end = Math.round(end)
      /* eslint-enable no-param-reassign */

      const editorAction = produceEditorAction({
        action: {
          type: 'align-tokens',
          ranges: tokenLocations.reduce<
            {
              range: {
                startToken: string
                endToken: string
              }
              tokens: APITranscriptToken[]
            }[]
          >((acc, {sliceIndex, tokenIndex}) => {
            const range = {
              startToken: `/slice_meta/${sliceIndex}/token_meta/${tokenIndex}`,
              endToken: `/slice_meta/${sliceIndex}/token_meta/${tokenIndex}`,
            }
            const nextTokens = getTokenRange(transcript, {
              startToken: indexFromPath(range.startToken),
              endToken: indexFromPath(range.endToken),
            }).map(({token: prev}) => {
              const token = cloneDeep(prev)

              switch (action) {
                case 'move':
                  token.startMs = Math.max(0, token.startMs + deltaOrStart)
                  break
                case 'resize-end':
                  token.durationMs = Math.max(
                    MIN_TOKEN_DURATION_MS,
                    token.durationMs + deltaOrStart,
                  )
                  break
                case 'resize-start':
                  token.startMs = Math.max(
                    0,
                    Math.min(
                      prev.startMs + deltaOrStart,
                      prev.startMs + prev.durationMs - MIN_TOKEN_DURATION_MS,
                    ),
                  )
                  token.durationMs = Math.max(prev.durationMs - deltaOrStart, MIN_TOKEN_DURATION_MS)
                  break
                case 'set':
                  token.startMs = Math.max(0, deltaOrStart)
                  token.durationMs = Math.max(end - deltaOrStart, MIN_TOKEN_DURATION_MS)
                  break
                default:
                  assertNever(action)
              }

              return token
            })

            return [...acc, {range, tokens: nextTokens}]
          }, []),
        },
        transcript,
        transcriptSelection: null,
        log,
      })
      if (!editorAction) return

      editorAction.selectionChangeDisabled = true
      dispatchEditorAction(editorAction)
    },
    [transcript, transcriptPermissions, log, dispatchEditorAction],
  )

  const convertDragAmountToTime = useCallback(
    (dragState: DragState): number => {
      if (!xScale) return 0

      return Math.round(
        xScale.invert(Math.abs(dragState.x + dragState.dx)) - xScale.invert(Math.abs(dragState.x)),
      )
    },
    [xScale],
  )

  /** translate the viewport so the time is 15% from the left of the screen, while maintaining current zoom level */
  const jumpToTime = useCallback(
    (timeMs: number, alsoSeekMedia = true): void => {
      if (zoomRef.current && zoomEleRef.current) {
        zoomRef.current.translateTo(
          select(zoomEleRef.current),
          scaleLinear().domain([0, transcriptDurationMs]).range([0, width])(timeMs),
          0,
          [width * 0.15, 0],
        )
      }
      if (alsoSeekMedia) seekMedia({timeSeconds: timeMs / 1000, play: !paused})
    },
    [transcriptDurationMs, width, seekMedia, paused],
  )

  useEffect(() => {
    if (
      syncViewToTranscriptTime &&
      currentTimeMs !== prevTimeMs &&
      xScale &&
      (xScale(currentTimeMs) < 0 || xScale(currentTimeMs) > width)
    ) {
      jumpToTime(currentTimeMs, false)
    }
  }, [currentTimeMs, syncViewToTranscriptTime, prevTimeMs, width, xScale, jumpToTime])

  useKeyboardShortcut(
    SHORTCUTS.Timeline.togglePlaybackLoop.keys,
    () => {
      setBoundPlayback((prev) => !prev)
    },
    {enabled: isFocusWithin},
  )

  useKeyboardShortcut(
    SHORTCUTS.Timeline.toggleSyncToTime.keys,
    () => {
      setSyncViewToTranscriptTime((prev) => !prev)
    },
    {enabled: isFocusWithin},
  )

  useKeyboardShortcut(
    SHORTCUTS.Timeline.jumpToCurrentTime.keys,
    () => {
      jumpToTime(currentTimeMs, false)
    },
    {enabled: isFocusWithin},
  )

  const jumpToNextUnalignedToken = useCallback(() => {
    const nextUnalignedTokenLocation = flatTokens.find(({sliceIndex, tokenIndex}) => {
      const token = transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
      return 'aligned' in token && !token.aligned
    })
    if (nextUnalignedTokenLocation) {
      const nextUnalignedToken =
        transcript.sliceMeta[nextUnalignedTokenLocation.sliceIndex].tokenMeta[
          nextUnalignedTokenLocation.tokenIndex
        ]
      jumpToTime(nextUnalignedToken.startMs + nextUnalignedToken.durationMs / 2, true)
    }
  }, [jumpToTime, transcript, flatTokens])

  useKeyboardShortcut(SHORTCUTS.Timeline.jumpToUnalignedToken.keys, jumpToNextUnalignedToken, {
    enabled: isFocusWithin,
  })

  return (
    <div
      data-testid="timestamp-manager"
      ref={(r) => {
        containerSizeRef(r)
        isFocusWithinRef(r)
      }}
      className="relative h-36 w-full border-t border-gray-200"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setDrag({x: 0, y: 0, dx: 0, dy: 0, isDragging: false, target: null})
        setIsHovering(false)
      }}
      onKeyDown={(event) => {
        if (event.code === 'AltLeft' || event.code === 'AltRight') {
          setIsAltKey(true)
          return
        }

        // resize selected token
        if (
          (event.code === 'BracketLeft' || event.code === 'BracketRight') &&
          selectedTokens.length === 1
        ) {
          alignTokens(
            selectedTokens,
            event.altKey ? 'resize-end' : 'resize-start',
            event.code === 'BracketLeft' ? -10 : 10,
          )
          return
        }

        // move token selection
        if (
          (event.code === 'ArrowLeft' || event.code === 'ArrowRight') &&
          (isMac() ? event.altKey : event.ctrlKey) &&
          selectedTokens.length > 0
        ) {
          const nextSelectedTokens = (() => {
            const currentIndex = flatTokens.findIndex((tokenLocation) =>
              isEqual(
                tokenLocation,
                event.code === 'ArrowLeft' ? selectedTokens[0] : last(selectedTokens),
              ),
            )
            if (!currentIndex) return selectedTokens
            const nextIndex = currentIndex + (event.code === 'ArrowLeft' ? -1 : 1)
            if (nextIndex < 0 || nextIndex >= flatTokens.length) return selectedTokens
            return [flatTokens[nextIndex]]
          })()
          if (nextSelectedTokens.length > 0) {
            const nextToken = getToken(transcript, nextSelectedTokens[0])
            // pan if token is out of view
            if (
              nextToken &&
              xScale &&
              (nextToken.startMs > xScale.invert(width) ||
                nextToken.startMs + nextToken.durationMs < xScale?.invert(0))
            ) {
              jumpToTime(nextToken.startMs, false)
            }
          }
          setSelectedTokens(nextSelectedTokens)
          return
        }

        // nudge selected token(s)
        if (
          (event.code === 'ArrowLeft' || event.code === 'ArrowRight') &&
          selectedTokens.length > 0
        ) {
          alignTokens(
            selectedTokens,
            'move',
            (event.shiftKey ? 50 : 10) * (event.code === 'ArrowLeft' ? -1 : 1),
          )
        }
      }}
      onKeyUp={(event) => {
        if (event.code === 'AltLeft' || event.code === 'AltRight') {
          setIsAltKey(false)
        }
      }}
      onContextMenu={(event) => {
        if (!svgRef.current) return

        event.preventDefault()
        const rect = svgRef.current.getBoundingClientRect()
        setContextMenuPosition({x: event.clientX - rect.x, y: event.clientY - rect.y})
      }}
    >
      <svg
        ref={svgRef}
        width={width}
        height={height}
        className={clsx(
          'overflow-visible focus:outline-none',
          drag.isDragging && drag.action === 'set' && 'cursor-grabbing',
        )}
        tabIndex={0}
        onMouseDown={(event) => {
          if (event.button !== 0) return
          if ((event.altKey || isAltDragMode) && selectedTokens.length === 1) {
            setDrag({
              x: event.clientX,
              y: event.clientY,
              dx: 0,
              dy: 0,
              isDragging: true,
              target: selectedTokens[0],
              action: 'set',
            })
          }
        }}
        onMouseMove={(event) => {
          if (!drag.isDragging) return
          setDrag((prev) => ({
            ...prev,
            dx: event.clientX - prev.x,
            dy: event.clientY - prev.y,
          }))
        }}
        onMouseUp={(event) => {
          if (isAltDragMode) setIsAltDragMode(false)

          if (drag.dx === 0 && drag.dy === 0 && drag.target && !event.metaKey && !event.shiftKey) {
            setSelectedTokens([drag.target])
          }

          if (xScale && drag.dx !== 0 && drag.target && drag.action) {
            if (drag.action === 'move') {
              alignTokens(selectedTokens, drag.action, convertDragAmountToTime(drag))
            } else if (drag.action === 'resize-start' || drag.action === 'resize-end') {
              alignTokens(selectedTokens, drag.action, convertDragAmountToTime(drag))
            } else if (drag.action === 'set') {
              alignTokens(
                selectedTokens,
                drag.action,
                drag.dx > 0 ? xScale.invert(drag.x) : xScale.invert(drag.x + drag.dx),
                drag.dx > 0 ? xScale.invert(drag.x + drag.dx) : xScale.invert(drag.x),
              )
            }
          }

          setDrag({x: 0, y: 0, dx: 0, dy: 0, isDragging: false, target: null})
        }}
      >
        <rect
          ref={zoomEleRef}
          // ignore pointer events when alt dragging, so that user can drag without panning
          className={clsx('fill-transparent', (isAltKey || isAltDragMode) && 'pointer-events-none')}
          width={width}
          height={height}
          onClick={(event) => {
            if (!xScale) return
            seekMedia({timeSeconds: xScale.invert(event.clientX) / 1000, play: !paused})
          }}
        />

        <g className="x-axis" ref={axisElementRef} transform="translate(0, 8)">
          <defs>
            <style>
              {`
                .x-axis .domain {
                  display: none;
                }

                .x-axis .tick > line {
                  display: none;
                }

                .x-axis .tick > text {
                  fill: rgba(107, 114, 128, 1);
                  font-size: 14px;
                  font-family: Proxima Nova;
                  pointer-events: none;
                  user-select: none;
                }
            `}
            </style>
          </defs>
        </g>

        {xScale && boundPlayback && (
          <PlaybackBrush
            xScale={xScale}
            seekMedia={seekMedia}
            currentTimeMs={currentTimeMs}
            paused={paused}
            width={width}
            height={height}
          />
        )}

        <g transform={`translate(0, ${AXIS_HEIGHT + 20})`}>
          {xScale &&
            filteredTokens.map((tokenLocation) => {
              const token = getToken(transcript, tokenLocation)
              if (!token) return null
              const selected = selectedTokens.some(
                (selectedToken) =>
                  selectedToken.sliceIndex === tokenLocation.sliceIndex &&
                  selectedToken.tokenIndex === tokenLocation.tokenIndex,
              )
              // hide original token(s) while dragging clones
              if (selected && drag.isDragging && (drag.dx !== 0 || drag.dy !== 0)) return null

              return (
                <TimestampGraphToken
                  key={tokenLocationToPath(tokenLocation)}
                  token={token}
                  selected={selected}
                  xScale={xScale}
                  onMouseDown={(event) => {
                    if (event.button !== 0) return

                    setSelectedTokens((prev) => {
                      if (event.shiftKey && prev.length) {
                        const lowerToken =
                          transcript.sliceMeta[(last(prev) as TokenLocation).sliceIndex].tokenMeta[
                            (last(prev) as TokenLocation).tokenIndex
                          ]
                        const upperToken =
                          transcript.sliceMeta[tokenLocation.sliceIndex].tokenMeta[
                            tokenLocation.tokenIndex
                          ]
                        const startTime = Math.min(lowerToken.startMs, upperToken.startMs)
                        const endTime = Math.max(
                          lowerToken.startMs + lowerToken.durationMs,
                          upperToken.startMs + upperToken.durationMs,
                        )

                        return uniqBy(
                          prev.concat(
                            flatTokens.filter(({sliceIndex, tokenIndex}) => {
                              const currentToken =
                                transcript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]
                              return (
                                currentToken.startMs >= startTime &&
                                currentToken.startMs + currentToken.durationMs <= endTime
                              )
                            }),
                          ),
                          ({sliceIndex, tokenIndex}) => `${sliceIndex}:${tokenIndex}`,
                        )
                      }

                      if (event.metaKey && selected) {
                        return prev.filter(
                          (selectedToken) =>
                            selectedToken.sliceIndex !== tokenLocation.sliceIndex ||
                            selectedToken.tokenIndex !== tokenLocation.tokenIndex,
                        )
                      }

                      if (event.metaKey && !selected) {
                        return prev.concat(tokenLocation)
                      }

                      if (!event.metaKey && selected) {
                        return prev
                      }

                      return [tokenLocation]
                    })

                    if (event.metaKey) return
                    if (event.altKey && selectedTokens.length !== 1) return

                    let action: DragState['action'] = 'move'
                    if (event.altKey) {
                      action = 'set'
                    } else if ((event.target as SVGElement).classList.contains('token-start')) {
                      action = 'resize-start'
                    } else if ((event.target as SVGElement).classList.contains('token-end')) {
                      action = 'resize-end'
                    }
                    setDrag({
                      x: event.clientX,
                      y: event.clientY,
                      dx: 0,
                      dy: 0,
                      isDragging: true,
                      target: tokenLocation,
                      action,
                    })
                  }}
                />
              )
            })}
        </g>

        {xScale && (
          <g>
            <defs>
              <marker id="playback-line-dot" viewBox="0 0 4 4" refX="2" refY="2">
                <circle className="fill-gray-500" cx="2" cy="2" r="2" />
              </marker>
            </defs>
            <line
              className="stroke-gray-500"
              ref={playbackLineRef}
              y1={0}
              y2={height}
              strokeWidth={1}
              x1={xScale(currentTimeMs)}
              x2={xScale(currentTimeMs)}
              markerStart="url(#playback-line-dot)"
            />
          </g>
        )}

        {isHovering && xScale && <MouseHoverLine height={height} xScale={xScale} />}

        {xScale && drag.isDragging && (drag.dx !== 0 || drag.dy !== 0) && (
          <g transform={`translate(0, ${AXIS_HEIGHT + 20})`}>
            {selectedTokens.map((tokenLocation) => {
              const token = getToken(transcript, tokenLocation)
              if (!token) return null

              const t = convertDragAmountToTime(drag)
              let {startMs, durationMs} = token

              if (drag.action === 'move') {
                startMs = Math.max(0, token.startMs + t)
              } else if (drag.action === 'resize-end' && isEqual(drag.target, tokenLocation)) {
                durationMs = Math.max(MIN_TOKEN_DURATION_MS, durationMs + t)
              } else if (drag.action === 'resize-start' && isEqual(drag.target, tokenLocation)) {
                startMs = Math.max(
                  0,
                  Math.min(
                    token.startMs + t,
                    token.startMs + token.durationMs - MIN_TOKEN_DURATION_MS,
                  ),
                )
                durationMs = Math.max(token.durationMs - t, MIN_TOKEN_DURATION_MS)
              } else if (drag.action === 'set') {
                if (drag.dx > 0) {
                  startMs = xScale.invert(drag.x)
                  durationMs = Math.max(0, xScale.invert(drag.x + drag.dx) - startMs)
                } else {
                  startMs = xScale.invert(drag.x + drag.dx)
                  durationMs = Math.max(0, xScale.invert(drag.x) - startMs)
                }
              }

              return (
                <TimestampGraphToken
                  key={tokenLocationToPath(tokenLocation)}
                  token={token}
                  startMs={startMs}
                  durationMs={durationMs}
                  selected
                  xScale={xScale}
                />
              )
            })}
          </g>
        )}
      </svg>

      <div className="sticky bottom-1 inline-flex gap-2 pl-1">
        <Tooltip content="Jump to current time  [K]" position="top">
          <Button
            icon="ViewfinderCircleIcon"
            rounded
            aria-label="Jump to current time"
            size="small"
            onClick={() => jumpToTime(currentTimeMs, false)}
          />
        </Tooltip>
        <Tooltip content="Toggle playback looping  [L]" position="top">
          <Button
            icon="ArrowPathRoundedSquareIcon"
            rounded
            intent={boundPlayback ? 'primary' : 'default'}
            aria-label="Toggle playback looping"
            size="small"
            onClick={() => setBoundPlayback((prev) => !prev)}
          />
        </Tooltip>
        <Tooltip content="Toggle current time sync [S]" position="top">
          <Button
            icon="ArrowRightStartOnRectangleIcon"
            rounded
            intent={syncViewToTranscriptTime ? 'primary' : 'default'}
            aria-label="Toggle current time sync"
            size="small"
            onClick={() => setSyncViewToTranscriptTime((prev) => !prev)}
          />
        </Tooltip>
      </div>

      {contextMenuPosition && (
        <ContextMenu
          {...contextMenuPosition}
          selectedTokens={selectedTokens}
          jumpToCurrentTime={() => jumpToTime(currentTimeMs, false)}
          jumpToNextUnalignedToken={jumpToNextUnalignedToken}
          setIsAltDragMode={setIsAltDragMode}
          onClose={() => setContextMenuPosition(null)}
        />
      )}
    </div>
  )
}
