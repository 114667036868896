/* eslint-disable no-param-reassign */
import {Logger} from '@kensho/lumberjack'
import Graphemer from 'graphemer'
import {Draft, current as immerCurrent, isDraft, original, produce} from 'immer'
import {cloneDeep, get, isEqual, last, set} from 'lodash-es'

import {DURATION_PER_CHAR_MS, DURATION_TOKEN_GAP_MS} from '../core/transcription/constants'
import {Operation} from '../types/schemas'
import {
  APITranscript,
  APITranscriptSlice,
  APITranscriptToken,
  EditorAction,
  TranscriptSelection,
  TranscriptSelectionNode,
  Annotation,
  SpeakerInfo,
} from '../types/types'

import assertNever from './assertNever'
import getUniqueId from './getUniqueId'
import {
  getTokenRange,
  indexFromPath,
  normalizeTranscriptSelectionNode,
  IndexedToken,
} from './transcriptUtils'

export interface InsertAction {
  type: 'insert-text'
  data: string
}

export interface DeleteAction {
  type: 'delete-text'
  direction?: 'forward' | 'backward'
}

export interface ReplaceTokensAction {
  type: 'replace-tokens'
  data: IndexedToken[]
}

export interface ReplaceTranscriptAction {
  type: 'replace-transcript'
  data: APITranscript
}

export interface SplitSliceAction {
  type: 'split-slice'
}

export interface UpdateSpeakerAction {
  type: 'update-speaker'
  speakerId: number
  data: Partial<SpeakerInfo>
}

export interface AddSpeakerAction {
  type: 'add-speaker'
  data: SpeakerInfo
}

export interface DeleteSpeakerAction {
  type: 'delete-speaker'
  speakerId: number
}

export interface ChangeSliceSpeakerAction {
  type: 'change-slice-speaker'
  speakerId: number | null
  data?: SpeakerInfo
  sliceIndex: number
}

export interface UpdateAccuracyAction {
  type: 'update-accuracy'
  startToken: {sliceIndex: number; tokenIndex: number}
  endToken: {sliceIndex: number; tokenIndex: number}
  accuracy: number
}

export interface AlignTokensAction {
  type: 'align-tokens'
  ranges: {
    range: {
      startMs?: number
      startToken?: string // JSON pointer
      endMs?: number
      endToken?: string // JSON pointer
    }
    tokens: APITranscriptToken[]
  }[]
}

export interface AppendSliceAction {
  type: 'append-slice'
  slice: APITranscriptSlice
}

/**
 * Annotations can have locations at the token or slice level.
 * For a slice-level annotation there will be no tokenIndex
 */
export interface AnnotationActionRange {
  start: {sliceIndex: number; tokenIndex?: number}
  end: {sliceIndex: number; tokenIndex?: number}
}

export interface AddAnnotationAction {
  type: 'add-annotation'
  annotation: Omit<Annotation, 'id'>
  annotationId?: string
  ranges?: AnnotationActionRange[]
}

export interface DeleteAnnotationAction {
  type: 'delete-annotation'
  annotationId: string
  ranges?: AnnotationActionRange[]
}

export type TranscriptEditAction =
  | InsertAction
  | DeleteAction
  | ReplaceTokensAction
  | ReplaceTranscriptAction
  | SplitSliceAction
  | UpdateSpeakerAction
  | AddSpeakerAction
  | DeleteSpeakerAction
  | ChangeSliceSpeakerAction
  | UpdateAccuracyAction
  | AlignTokensAction
  | AddAnnotationAction
  | DeleteAnnotationAction
  | AppendSliceAction

/** Wrap immer.current so that calls on an already non-draft value will return the value as-is  */
function current<T>(value: T): T {
  return isDraft(value) ? immerCurrent(value) : value
}

/** Returns the startMs and durationMs that will fit all of the slice's tokens */
export function calcSliceTimes(slice: APITranscriptSlice): {startMs: number; durationMs: number} {
  if (!slice.tokenMeta.length) return {startMs: Math.max(0, slice.startMs), durationMs: 0}

  // reduce over all tokens in case some are out of order with respect to time
  const times = slice.tokenMeta.reduce(
    (acc, token) => {
      if (token.startMs === 0 && token.durationMs === 0) return acc
      if (token.startMs < acc.startMs) acc.startMs = token.startMs
      if (token.startMs + token.durationMs > acc.startMs + acc.durationMs) {
        acc.durationMs = token.startMs + token.durationMs - acc.startMs
      }
      return acc
    },
    {startMs: slice.tokenMeta[0].startMs, durationMs: slice.tokenMeta[0].durationMs},
  )

  // ensure we're not returning negative values
  times.startMs = Math.max(0, times.startMs)
  times.durationMs = Math.max(0, times.durationMs)

  return times
}

export function mergeTokens(
  token1: APITranscriptToken,
  token2: APITranscriptToken,
): APITranscriptToken {
  const mergedToken = {
    ...token1,
    transcript: `${token1.transcript}${token2.transcript}`,
    aligned: false,
  }

  // approximate duration based on the length of the text, should get refined later by alignment
  mergedToken.durationMs =
    mergedToken.transcript.replace(/[^a-z0-9]+/gi, '').length * DURATION_PER_CHAR_MS

  return mergedToken
}

export function mergeSlices(
  slice1: APITranscriptSlice,
  slice2: APITranscriptSlice,
): APITranscriptSlice {
  const mergedSlice = {
    ...slice1,
    transcript: `${slice1.transcript} ${slice2.transcript}`,
    accuracy: (slice1.accuracy + slice2.accuracy) / 2,
    tokenMeta: [...slice1.tokenMeta, ...slice2.tokenMeta],
  }

  // update slice timings
  const mergedSliceTimings = calcSliceTimes(mergedSlice)
  mergedSlice.startMs = mergedSliceTimings.startMs
  mergedSlice.durationMs = mergedSliceTimings.durationMs

  return mergedSlice
}

export function deleteText(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action?: DeleteAction,
): void {
  if (!editorAction.afterSelection?.end || !editorAction.afterSelection?.start) return

  // caret is deleting after the space at the end of slice, move it backwards without deleting anything
  // foo bar | -> foo bar|
  if (
    editorAction.afterSelection.type === 'Caret' &&
    editorAction.afterSelection.end.type === 'token-space' &&
    editorAction.afterSelection.end.textOffset === 1 &&
    editorAction.afterSelection.end.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta.length - 1 &&
    action?.direction !== 'forward'
  ) {
    editorAction.afterSelection.end.textOffset = 0
    editorAction.afterSelection.start = cloneDeep(editorAction.afterSelection.end)
    return
  }

  // forward deleting at end of transcript, noop
  if (
    editorAction.afterSelection.type === 'Caret' &&
    editorAction.afterSelection.start.sliceIndex === draftTranscript.sliceMeta.length - 1 &&
    editorAction.afterSelection.start.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta.length -
        1 &&
    (editorAction.afterSelection.end.type === 'token-space' ||
      editorAction.afterSelection.start.textOffset ===
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
          editorAction.afterSelection.end.tokenIndex
        ].transcript.length) &&
    action?.direction === 'forward'
  ) {
    return
  }

  // deleting at beginning of transcript, noop
  if (
    editorAction.afterSelection.type === 'Caret' &&
    editorAction.afterSelection.start.sliceIndex === 0 &&
    editorAction.afterSelection.start.tokenIndex === 0 &&
    editorAction.afterSelection.start.textOffset === 0 &&
    action?.direction !== 'forward'
  )
    return

  // deleting entire transcript, replace sliceMeta with empty slice assigned to unknown speaker
  if (
    editorAction.afterSelection.type === 'Range' &&
    editorAction.afterSelection.start.sliceIndex === 0 &&
    editorAction.afterSelection.start.tokenIndex === 0 &&
    editorAction.afterSelection.start.textOffset === 0 &&
    editorAction.afterSelection.end.type === 'token-space' &&
    editorAction.afterSelection.end.sliceIndex === draftTranscript.sliceMeta.length - 1 &&
    editorAction.afterSelection.end.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta.length - 1 &&
    editorAction.afterSelection.end.textOffset === 1
  ) {
    const emptySliceMeta = [
      {
        speakerId: -1,
        speakerAccuracy: 1,
        accuracy: 1,
        startMs: 0,
        durationMs: 1,
        transcript: '',
        tokenMeta: [
          {
            transcript: '',
            startMs: 0,
            durationMs: 1,
            accuracy: 1,
            aligned: false,
          },
        ],
      },
    ]
    editorAction.revision.operations.push({
      type: 'object-set',
      path: ['sliceMeta'],
      value: cloneDeep(emptySliceMeta),
    })

    editorAction.inverseRevision.operations.push({
      type: 'object-set',
      path: ['sliceMeta'],
      value: cloneDeep(current(draftTranscript.sliceMeta)),
    })

    draftTranscript.sliceMeta = cloneDeep(emptySliceMeta)

    const unknownSpeaker = {
      name: 'Unknown speaker',
    }
    if (!draftTranscript.speakers[-1]) {
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['speakers', '-1'],
        value: cloneDeep(unknownSpeaker),
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-delete',
        path: ['speakers', '-1'],
      })

      draftTranscript.speakers[-1] = cloneDeep(unknownSpeaker)
    }

    editorAction.afterSelection.type = 'Caret'
    editorAction.afterSelection.start = {
      type: 'token',
      sliceIndex: 0,
      tokenIndex: 0,
      textOffset: 0,
    }
    editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)

    return
  }

  // normalize to omit token-space at end of slice if part of a range
  // f[oo bar ] -> f[oo bar]
  if (
    editorAction.afterSelection.type === 'Range' &&
    editorAction.afterSelection.end.type === 'token-space' &&
    editorAction.afterSelection.end.textOffset === 1 &&
    editorAction.afterSelection.end.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta.length - 1
  ) {
    editorAction.afterSelection.end = {
      ...editorAction.afterSelection.end,
      type: 'token',
      textOffset:
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
          editorAction.afterSelection.end.tokenIndex
        ].transcript.length,
    }
  }
  // normalize forwards direction to backward by shifting one place to the right
  if (action?.direction === 'forward' && editorAction.afterSelection.type === 'Caret') {
    if (
      editorAction.afterSelection.end.tokenIndex ===
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta.length -
          1 &&
      (editorAction.afterSelection.end.type === 'token-space' ||
        editorAction.afterSelection.end.textOffset ===
          draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
            editorAction.afterSelection.end.tokenIndex
          ].transcript.length)
    ) {
      // caret is deleting forwards at the end of slice, normalize to beginning of next slice for merge
      // foo| \nbar -> foo \n|bar
      // foo | \nbar -> foo \n|bar
      editorAction.afterSelection.start = {
        type: 'token',
        sliceIndex: editorAction.afterSelection.start.sliceIndex + 1,
        tokenIndex: 0,
        textOffset: 0,
      }
      editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
    } else if (
      editorAction.afterSelection.end.type === 'token-space' &&
      editorAction.afterSelection.end.textOffset === 0
    ) {
      // foo| bar -> foo |bar
      editorAction.afterSelection.start = {
        type: 'token',
        sliceIndex: editorAction.afterSelection.start.sliceIndex,
        tokenIndex: editorAction.afterSelection.start.tokenIndex + 1,
        textOffset: 0,
      }
      editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
    } else if (
      editorAction.afterSelection.end.type === 'token-space' &&
      editorAction.afterSelection.end.textOffset === 1
    ) {
      // foo |bar -> foo b|ar
      editorAction.afterSelection.start = {
        type: 'token',
        sliceIndex: editorAction.afterSelection.start.sliceIndex,
        tokenIndex: editorAction.afterSelection.start.tokenIndex + 1,
        textOffset: 1,
      }
      editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
    } else if (
      editorAction.afterSelection.end.type === 'token' &&
      editorAction.afterSelection.end.textOffset ===
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
          editorAction.afterSelection.end.tokenIndex
        ].transcript.length
    ) {
      // foo| bar -> foo |bar
      editorAction.afterSelection.start = {
        type: 'token',
        sliceIndex: editorAction.afterSelection.start.sliceIndex,
        tokenIndex: editorAction.afterSelection.start.tokenIndex + 1,
        textOffset: 0,
      }
      editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
    } else {
      // f|oo -> fo|o

      // figure out how many characters to move the caret by
      // this is necessary because some grapheme clusters are multiple characters long
      // because a visual "letter" can be made up of multiple Unicode characters
      // for example: "👩‍👩‍👧‍👦" is a single grapheme cluster but 11 characters long
      editorAction.afterSelection.start.textOffset +=
        new Graphemer().splitGraphemes(
          draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta[
            editorAction.afterSelection.start.tokenIndex
          ].transcript.slice(editorAction.afterSelection.start.textOffset),
        )[0].length || 1
      editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
    }
  }

  // normalize selection to tokens and not token-spaces
  editorAction.afterSelection.start = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.start,
    draftTranscript,
  )
  editorAction.afterSelection.end = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.end,
    draftTranscript,
  )

  // normalize to one character range if the selection is a caret
  if (editorAction.afterSelection.type === 'Caret') {
    editorAction.afterSelection.type = 'Range'

    if (
      editorAction.afterSelection.end.tokenIndex === 0 &&
      editorAction.afterSelection.end.textOffset === 0
    ) {
      // at the beginning of a slice; expand to end of previous slice
      // foo \n|bar -> foo[ \n]bar
      editorAction.afterSelection.start.sliceIndex -= 1
      editorAction.afterSelection.start.tokenIndex =
        draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta.length - 1
      editorAction.afterSelection.start.textOffset =
        draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta[
          editorAction.afterSelection.start.tokenIndex
        ].transcript.length
    } else if (editorAction.afterSelection.start.textOffset === 0) {
      // at the beginning of a token; expand to end of previous token
      // foo |bar -> foo[ ]bar
      editorAction.afterSelection.start.tokenIndex -= 1
      editorAction.afterSelection.start.textOffset =
        draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta[
          editorAction.afterSelection.start.tokenIndex
        ].transcript.length
    } else {
      // fo|o -> f[o]o backward
      editorAction.afterSelection.start.textOffset -=
        new Graphemer()
          .splitGraphemes(
            draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
              editorAction.afterSelection.end.tokenIndex
            ].transcript.slice(0, editorAction.afterSelection.end.textOffset),
          )
          .pop()?.length || 1
    }
  }

  const normalizedSelection = cloneDeep(
    editorAction.afterSelection,
  ) as Required<TranscriptSelection>

  // keep queue of operations instead of pushing directly to editorAction, because they might be optimized at the end
  const operations: Operation[] = []
  const inverseOperations: Operation[] = []

  // merge and/or delete at slice level
  if (editorAction.afterSelection.start.sliceIndex !== editorAction.afterSelection.end.sliceIndex) {
    const {
      sliceIndex: startSliceIndex,
      tokenIndex: startTokenIndex,
      textOffset: startTextOffset,
    } = editorAction.afterSelection.start
    const {
      sliceIndex: endSliceIndex,
      tokenIndex: endTokenIndex,
      textOffset: endTextOffset,
    } = editorAction.afterSelection.end
    const isPartialStartSlice = startTokenIndex > 0 || startTextOffset > 0
    const isPartialEndSlice =
      endTokenIndex < draftTranscript.sliceMeta[endSliceIndex].tokenMeta.length - 1 ||
      endTextOffset <
        draftTranscript.sliceMeta[endSliceIndex].tokenMeta[endTokenIndex].transcript.length

    if (isPartialStartSlice && isPartialEndSlice) {
      // remove all slices and insert a merge of both end slices
      // slice[1 slice2 slice]3 -> slice[1slice]3

      const leftSlice = draftTranscript.sliceMeta[startSliceIndex]
      const rightSlice = draftTranscript.sliceMeta[endSliceIndex]

      // remove all slices from the selection (inclusive)
      const originalSlices = []
      for (let i = startSliceIndex; i <= endSliceIndex; i += 1) {
        originalSlices.push(cloneDeep(current(draftTranscript.sliceMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta'],
        startIndex: startSliceIndex,
        endIndex: endSliceIndex,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta'],
        index: startSliceIndex,
        values: originalSlices,
      })
      draftTranscript.sliceMeta.splice(startSliceIndex, endSliceIndex - startSliceIndex + 1)

      // insert the merged slice
      const mergedSlice = mergeSlices(leftSlice, rightSlice)
      operations.push({
        type: 'array-insert',
        path: ['sliceMeta'],
        index: startSliceIndex,
        values: cloneDeep([current(mergedSlice)]),
      })
      inverseOperations.push({
        type: 'array-delete',
        path: ['sliceMeta'],
        startIndex: startSliceIndex,
        endIndex: startSliceIndex,
      })
      draftTranscript.sliceMeta.splice(startSliceIndex, 0, mergedSlice)

      // update the selection
      editorAction.afterSelection.end.sliceIndex = startSliceIndex
      editorAction.afterSelection.end.tokenIndex += leftSlice.tokenMeta.length
    } else if (isPartialEndSlice) {
      // remove all slices except last
      // [slice1 slice]2 -> [slice]2

      const originalSlices = []
      for (let i = startSliceIndex; i < endSliceIndex; i += 1) {
        originalSlices.push(cloneDeep(current(draftTranscript.sliceMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta'],
        startIndex: startSliceIndex,
        endIndex: endSliceIndex - 1,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta'],
        index: startSliceIndex,
        values: originalSlices,
      })
      draftTranscript.sliceMeta.splice(startSliceIndex, endSliceIndex - startSliceIndex)

      // update the selection
      editorAction.afterSelection.start.tokenIndex = 0
      editorAction.afterSelection.start.textOffset = 0
      editorAction.afterSelection.end.sliceIndex = editorAction.afterSelection.start.sliceIndex
    } else {
      // remove all slices except first
      // slice[1 slice2] -> slice[1]
      // [slice1 slice2] -> [slice1]

      const originalSlices = []
      for (let i = startSliceIndex + 1; i <= endSliceIndex; i += 1) {
        originalSlices.push(cloneDeep(current(draftTranscript.sliceMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta'],
        startIndex: startSliceIndex + 1,
        endIndex: endSliceIndex,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta'],
        index: startSliceIndex + 1,
        values: originalSlices,
      })
      draftTranscript.sliceMeta.splice(startSliceIndex + 1, endSliceIndex - startSliceIndex)

      // update the selection
      editorAction.afterSelection.end.sliceIndex = startSliceIndex
      editorAction.afterSelection.end.tokenIndex =
        draftTranscript.sliceMeta[startSliceIndex].tokenMeta.length - 1
      editorAction.afterSelection.end.textOffset =
        draftTranscript.sliceMeta[startSliceIndex].tokenMeta[
          draftTranscript.sliceMeta[startSliceIndex].tokenMeta.length - 1
        ].transcript.length
    }

    // original action was a caret forward delete after the last token-space
    // update the selection so that we merge the slices but not the tokens
    // foo |\nbar -> foo |bar
    if (
      action?.direction === 'forward' &&
      editorAction.beforeSelection?.start?.type === 'token-space' &&
      editorAction.beforeSelection?.start?.textOffset === 1
    ) {
      editorAction.afterSelection.type = 'Caret'
      editorAction.afterSelection.start = cloneDeep(editorAction.afterSelection.end)
    }
  }

  // range to be deleted is within a single slice at this point
  // merge and/or delete at token level
  if (editorAction.afterSelection.start.tokenIndex !== editorAction.afterSelection.end.tokenIndex) {
    const {tokenIndex: startTokenIndex, textOffset: startTextOffset} =
      editorAction.afterSelection.start
    const {
      sliceIndex: endSliceIndex,
      tokenIndex: endTokenIndex,
      textOffset: endTextOffset,
    } = editorAction.afterSelection.end
    const slice = draftTranscript.sliceMeta[endSliceIndex]
    const startTokenLength = slice.tokenMeta[startTokenIndex].transcript.length
    // f[oo or foo[ not [foo
    const isPartialStartToken = startTextOffset > 0 && startTokenLength > 0
    const endTokenLength = slice.tokenMeta[endTokenIndex].transcript.length
    // ]bar or ba]r not bar]
    const isPartialEndToken = endTextOffset < slice.tokenMeta[endTokenIndex].transcript.length

    if (
      (isPartialStartToken && isPartialEndToken) ||
      (!isPartialStartToken && !isPartialEndToken)
    ) {
      // remove all tokens and insert a merge of both end tokens
      // f[oo ba]r -> f[ooba]r
      // foo[ bar b]az -> foo[b]az
      // f[oo bar ]baz -> f[oo]baz
      // foo[ bar ]baz -> foo|baz
      // lorem [foo bar] baz -> lorem [foobar] baz

      const leftToken = slice.tokenMeta[startTokenIndex]
      const rightToken = slice.tokenMeta[endTokenIndex]

      // remove all tokens from the selection (inclusive)
      const originalTokens = []
      for (let i = startTokenIndex; i <= endTokenIndex; i += 1) {
        originalTokens.push(cloneDeep(current(slice.tokenMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        startIndex: startTokenIndex,
        endIndex: endTokenIndex,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        index: startTokenIndex,
        values: originalTokens,
      })
      slice.tokenMeta.splice(startTokenIndex, endTokenIndex - startTokenIndex + 1)

      // insert the merged token
      const mergedToken = mergeTokens(leftToken, rightToken)
      operations.push({
        type: 'array-insert',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        index: startTokenIndex,
        values: cloneDeep([current(mergedToken)]),
      })
      inverseOperations.push({
        type: 'array-delete',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        startIndex: startTokenIndex,
        endIndex: startTokenIndex,
      })
      slice.tokenMeta.splice(startTokenIndex, 0, mergedToken)

      // update the selection
      if (
        startTextOffset ===
          draftTranscript.sliceMeta[endSliceIndex].tokenMeta[startTokenIndex].transcript.length &&
        endTextOffset === 0
      ) {
        // foo[ bar ]baz -> foo|baz
        editorAction.afterSelection.type = 'Caret'
        editorAction.afterSelection.start = {
          type: 'token',
          sliceIndex: endSliceIndex,
          tokenIndex: startTokenIndex,
          textOffset: 0,
        }
        editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
      } else {
        editorAction.afterSelection.end.tokenIndex = startTokenIndex
        editorAction.afterSelection.end.textOffset += leftToken.transcript.length
      }
    } else if (isPartialEndToken) {
      // delete all tokens except last
      // [foo b]ar -> [b]ar
      // [foo ]bar -> |bar

      // remove all tokens from the selection except last
      const originalTokens = []
      for (let i = startTokenIndex; i < endTokenIndex; i += 1) {
        originalTokens.push(cloneDeep(current(slice.tokenMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        startIndex: startTokenIndex,
        endIndex: endTokenIndex - 1,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        index: startTokenIndex,
        values: originalTokens,
      })
      slice.tokenMeta.splice(startTokenIndex, endTokenIndex - startTokenIndex)

      // update the selection
      if (startTokenLength === 0 || endTextOffset === 0) {
        // [foo ]bar -> |bar
        editorAction.afterSelection.type = 'Caret'
        editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
      } else {
        editorAction.afterSelection.start.tokenIndex = startTokenIndex
        editorAction.afterSelection.start.textOffset = 0
      }
    } else {
      // delete all tokens except first
      // [foo bar ]baz -> [foo] baz
      // f[oo bar] baz -> f[oo] baz

      // remove all tokens from the selection except first
      const originalTokens = []
      for (let i = startTokenIndex + 1; i <= endTokenIndex; i += 1) {
        originalTokens.push(cloneDeep(current(slice.tokenMeta[i])))
      }
      operations.push({
        type: 'array-delete',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        startIndex: startTokenIndex + 1,
        endIndex: endTokenIndex,
      })
      inverseOperations.push({
        type: 'array-insert',
        path: ['sliceMeta', `${endSliceIndex}`, 'tokenMeta'],
        index: startTokenIndex + 1,
        values: originalTokens,
      })
      slice.tokenMeta.splice(startTokenIndex + 1, endTokenIndex - startTokenIndex)

      // update the selection
      if (endTokenLength === 0) {
        // foo[ ] baz -> foo| baz
        editorAction.afterSelection.type = 'Caret'
        editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)
      } else {
        editorAction.afterSelection.end.tokenIndex = startTokenIndex
        editorAction.afterSelection.end.textOffset =
          draftTranscript.sliceMeta[endSliceIndex].tokenMeta[startTokenIndex].transcript.length
      }
    }
  }

  // range to be deleted is within a single token at this point
  // delete the characters
  if (editorAction.afterSelection.type === 'Range') {
    const {
      sliceIndex: startSliceIndex,
      tokenIndex: startTokenIndex,
      textOffset: startTextOffset,
    } = editorAction.afterSelection.start
    const {textOffset: endTextOffset} = editorAction.afterSelection.end
    const token = draftTranscript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex]

    const nextText = `${token.transcript.substring(0, startTextOffset)}${token.transcript.substring(endTextOffset)}`
    // update token text
    // TODO convert this to TextDeleteOperation
    // once transcript supports applying revisions directly instead of translating to patches
    operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${startSliceIndex}`, 'tokenMeta', `${startTokenIndex}`, 'transcript'],
      value: nextText,
    })
    inverseOperations.push({
      type: 'object-set',
      path: ['sliceMeta', `${startSliceIndex}`, 'tokenMeta', `${startTokenIndex}`, 'transcript'],
      value: token.transcript,
    })
    token.transcript = nextText
  }

  // update selection
  editorAction.afterSelection.type = 'Caret'
  editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)

  // TODO if startSliceIndex and endSliceIndex were different, replace operation queue with SliceMerge operation
  // once transcript supports applying revisions directly instead of translating to patches

  // if the resulting token was touched by the delete then update it
  // the token is not considered to be touched if the selection was:
  // foo [bar ]baz -> foo |baz
  // foo[ bar] baz -> foo| baz
  // foo [\n]bar -> foo |bar (forwards delete at the of slice)
  if (
    !(
      ((normalizedSelection.start as TranscriptSelectionNode).tokenIndex !==
        (normalizedSelection.end as TranscriptSelectionNode).tokenIndex ||
        (normalizedSelection.start as TranscriptSelectionNode).sliceIndex !==
          (normalizedSelection.end as TranscriptSelectionNode).sliceIndex) &&
      (editorAction.afterSelection.start.textOffset === 0 ||
        editorAction.afterSelection.start.textOffset ===
          draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
            editorAction.afterSelection.end.tokenIndex
          ].transcript.length) &&
      editorAction.afterSelection.end.textOffset !==
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
          editorAction.afterSelection.end.tokenIndex
        ].transcript.length
    )
  ) {
    const token =
      draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
        editorAction.afterSelection.end.tokenIndex
      ]

    // update the token accuracy to 100%
    operations.push({
      type: 'object-set',
      path: [
        'sliceMeta',
        `${editorAction.afterSelection.start.sliceIndex}`,
        'tokenMeta',
        `${editorAction.afterSelection.start.tokenIndex}`,
        'accuracy',
      ],
      value: 1,
    })
    inverseOperations.push({
      type: 'object-set',
      path: [
        'sliceMeta',
        `${editorAction.afterSelection.start.sliceIndex}`,
        'tokenMeta',
        `${editorAction.afterSelection.start.tokenIndex}`,
        'accuracy',
      ],
      value: token.accuracy,
    })
    token.accuracy = 1

    // update the token duration if two tokens were merged together
    if (
      (normalizedSelection.start as TranscriptSelectionNode).tokenIndex !==
        (normalizedSelection.end as TranscriptSelectionNode).tokenIndex ||
      (normalizedSelection.start as TranscriptSelectionNode).sliceIndex !==
        (normalizedSelection.end as TranscriptSelectionNode).sliceIndex
    ) {
      const estimatedDurationMs = token.transcript.length * DURATION_PER_CHAR_MS
      operations.push({
        type: 'object-set',
        path: [
          'sliceMeta',
          `${editorAction.afterSelection.start.sliceIndex}`,
          'tokenMeta',
          `${editorAction.afterSelection.start.tokenIndex}`,
          'durationMs',
        ],
        value: estimatedDurationMs,
      })
      inverseOperations.push({
        type: 'object-set',
        path: [
          'sliceMeta',
          `${editorAction.afterSelection.start.sliceIndex}`,
          'tokenMeta',
          `${editorAction.afterSelection.start.tokenIndex}`,
          'durationMs',
        ],
        value: token.durationMs,
      })
      token.durationMs = token.transcript.length * DURATION_PER_CHAR_MS
    }
  }

  // update the slice duration if two slices were merged together
  if (
    (normalizedSelection.start as TranscriptSelectionNode).sliceIndex !==
    (normalizedSelection.end as TranscriptSelectionNode).sliceIndex
  ) {
    const slice = draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex]
    const {startMs, durationMs} = calcSliceTimes(slice)
    operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'startMs'],
      value: startMs,
    })
    inverseOperations.push({
      type: 'object-set',
      path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'startMs'],
      value: slice.startMs,
    })
    slice.startMs = startMs
    operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'durationMs'],
      value: durationMs,
    })

    inverseOperations.push({
      type: 'object-set',
      path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'durationMs'],
      value: slice.durationMs,
    })
    slice.durationMs = durationMs
  }

  // push operations to editorAction
  editorAction.revision.operations.push(...operations)
  editorAction.inverseRevision.operations.push(...inverseOperations)
}

export function splitToken(editorAction: EditorAction, draftTranscript: APITranscript): void {
  if (!editorAction.afterSelection?.end || !editorAction.afterSelection?.start) return

  const {sliceIndex, tokenIndex, textOffset} = editorAction.afterSelection.end

  const originalToken = current(draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex])
  const leftToken = cloneDeep(originalToken)
  const rightToken = cloneDeep(originalToken)

  leftToken.transcript = leftToken.transcript.slice(0, textOffset)
  leftToken.accuracy = 1
  leftToken.durationMs = leftToken.transcript.length * DURATION_PER_CHAR_MS
  leftToken.aligned = false

  rightToken.transcript = rightToken.transcript.slice(textOffset)
  rightToken.accuracy = 1
  rightToken.startMs = leftToken.startMs + leftToken.durationMs + DURATION_TOKEN_GAP_MS
  rightToken.durationMs = !rightToken.transcript.trim()
    ? 0
    : rightToken.transcript.length * DURATION_PER_CHAR_MS
  rightToken.aligned = false

  // delete original token
  editorAction.revision.operations.push({
    type: 'array-delete',
    path: ['sliceMeta', `${sliceIndex}`, `tokenMeta`],
    startIndex: tokenIndex,
    endIndex: tokenIndex,
  })
  editorAction.inverseRevision.operations.push({
    type: 'array-insert',
    path: ['sliceMeta', `${sliceIndex}`, `tokenMeta`],
    values: [cloneDeep(originalToken)],
    index: tokenIndex,
  })
  draftTranscript.sliceMeta[sliceIndex].tokenMeta.splice(tokenIndex, 1)

  // add split tokens
  editorAction.revision.operations.push({
    type: 'array-insert',
    path: ['sliceMeta', `${sliceIndex}`, `tokenMeta`],
    values: [leftToken, rightToken],
    index: tokenIndex,
  })
  editorAction.inverseRevision.operations.push({
    type: 'array-delete',
    path: ['sliceMeta', `${sliceIndex}`, `tokenMeta`],
    startIndex: tokenIndex,
    endIndex: tokenIndex + 1,
  })
  draftTranscript.sliceMeta[sliceIndex].tokenMeta.splice(tokenIndex, 0, leftToken, rightToken)

  // update the selection to start of right token
  editorAction.afterSelection.end.tokenIndex += 1
  editorAction.afterSelection.end.textOffset = 0
  editorAction.afterSelection.start.tokenIndex += 1
  editorAction.afterSelection.start.textOffset = 0
}

export function splitSlice(editorAction: EditorAction, draftTranscript: APITranscript): void {
  // if transcript is empty seed a new slice and set selection to the beginning
  if (!draftTranscript.sliceMeta.length) {
    // add new slice
    const newSlice = {
      speakerId: -1,
      speakerAccuracy: 1,
      accuracy: 1,
      startMs: 0,
      durationMs: 1,
      transcript: '',
      tokenMeta: [
        {
          transcript: '',
          startMs: 0,
          durationMs: 1,
          accuracy: 1,
          aligned: false,
        },
      ],
    }
    editorAction.revision.operations.push({
      type: 'array-insert',
      path: ['sliceMeta'],
      index: 0,
      values: [cloneDeep(newSlice)],
    })
    editorAction.inverseRevision.operations.push({
      type: 'array-delete',
      path: ['sliceMeta'],
      startIndex: 0,
      endIndex: 0,
    })
    draftTranscript.sliceMeta.push(newSlice)

    // add unknown speaker if it doesn't exist already
    if (!draftTranscript.speakers[-1]) {
      const unknownSpeaker = {
        name: 'Unknown speaker',
      }
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['speakers', '-1'],
        value: cloneDeep(unknownSpeaker),
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-delete',
        path: ['speakers', '-1'],
      })
      draftTranscript.speakers[-1] = unknownSpeaker
    }

    // update the selection
    editorAction.afterSelection = {
      type: 'Caret',
      start: {
        type: 'token',
        sliceIndex: 0,
        tokenIndex: 0,
        textOffset: 0,
      },
      end: {
        type: 'token',
        sliceIndex: 0,
        tokenIndex: 0,
        textOffset: 0,
      },
    }

    return
  }

  if (!editorAction.afterSelection?.end || !editorAction.afterSelection?.start) return

  if (editorAction.afterSelection.type === 'Range') {
    deleteText(editorAction, draftTranscript)
  }

  // shift caret to end of last token if at end of last space
  // foo bar | -> foo bar|
  if (
    editorAction.afterSelection.end.type === 'token-space' &&
    editorAction.afterSelection.end.textOffset === 1 &&
    editorAction.afterSelection.end.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta.length - 1
  ) {
    editorAction.afterSelection.end = {
      type: 'token',
      sliceIndex: editorAction.afterSelection.end.sliceIndex,
      tokenIndex: editorAction.afterSelection.end.tokenIndex,
      textOffset:
        draftTranscript.sliceMeta[editorAction.afterSelection.end.sliceIndex].tokenMeta[
          editorAction.afterSelection.end.tokenIndex
        ].transcript.length,
    }
    editorAction.afterSelection.type = 'Caret'
    editorAction.afterSelection.start = cloneDeep(editorAction.afterSelection.end)
  }

  // normalize token-space to token
  editorAction.afterSelection.end = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.end,
    draftTranscript,
  )
  editorAction.afterSelection.start = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.start,
    draftTranscript,
  )

  // keep queue of operations instead of pushing directly to editorAction, because they might be optimized at the end
  const operations: Operation[] = []
  const inverseOperations: Operation[] = []

  const {sliceIndex, tokenIndex, textOffset} = editorAction.afterSelection.end
  const leftSlice = draftTranscript.sliceMeta[sliceIndex]
  const rightSlice = cloneDeep(current(draftTranscript.sliceMeta[sliceIndex]))
  rightSlice.tokenMeta = cloneDeep(leftSlice.tokenMeta.slice(tokenIndex + 1))

  // remove tokens after caret from left slice
  if (tokenIndex < leftSlice.tokenMeta.length - 1) {
    const originalTokens = []
    for (let i = tokenIndex + 1; i < leftSlice.tokenMeta.length; i += 1) {
      originalTokens.push(cloneDeep(current(leftSlice.tokenMeta[i])))
    }
    operations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex + 1,
      endIndex: leftSlice.tokenMeta.length - 1,
    })
    inverseOperations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex + 1,
      values: originalTokens,
    })
    leftSlice.tokenMeta.splice(tokenIndex + 1)
  }

  const originalToken = cloneDeep(
    current(draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]),
  )
  if (textOffset > 0 && textOffset < originalToken.transcript.length) {
    // if the caret was in the middle of the token split the token and append both appropriately

    const leftToken = cloneDeep(originalToken)
    const rightToken = cloneDeep(originalToken)

    // split the timestamp of the token proportional to character offset
    leftToken.durationMs = Math.floor(
      leftToken.durationMs * (textOffset / originalToken.transcript.length),
    )
    rightToken.startMs = leftToken.startMs + originalToken.durationMs
    rightToken.durationMs = originalToken.durationMs - leftToken.durationMs

    // split the transcript into both tokens
    leftToken.transcript = leftToken.transcript.slice(0, textOffset)
    rightToken.transcript = rightToken.transcript.slice(textOffset)
    // set accuracy to 100%
    leftToken.accuracy = 1
    rightToken.accuracy = 1

    // add right token to start of right slice
    rightSlice.tokenMeta.unshift(rightToken)

    // replace original token with split left token
    operations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex,
      endIndex: tokenIndex,
    })
    inverseOperations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex,
      values: [cloneDeep(originalToken)],
    })
    operations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex,
      values: [cloneDeep(leftToken)],
    })
    inverseOperations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex,
      endIndex: tokenIndex,
    })
    draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex] = leftToken
  } else if (textOffset === 0) {
    // if the caret was at the start of the token move it to the right slice

    rightSlice.tokenMeta.unshift(originalToken)

    // remove original token from left slice
    operations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex,
      endIndex: tokenIndex,
    })
    inverseOperations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex,
      values: [cloneDeep(originalToken)],
    })
    draftTranscript.sliceMeta[sliceIndex].tokenMeta.pop()
  }

  // put token in left slice if it became empty
  if (!leftSlice.tokenMeta.length) {
    const emptyToken = {
      transcript: '',
      startMs: leftSlice.startMs,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    }

    // remove original token from left slice
    operations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: 0,
      values: [cloneDeep(emptyToken)],
    })
    inverseOperations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: 0,
      endIndex: 0,
    })
    leftSlice.tokenMeta.push(emptyToken)
  }

  // put token in right slice if it is empty
  if (!rightSlice.tokenMeta.length) {
    rightSlice.tokenMeta.push({
      transcript: '',
      startMs: leftSlice.startMs + leftSlice.durationMs + DURATION_TOKEN_GAP_MS,
      durationMs: 0,
      accuracy: 1,
      aligned: false,
    })
  }

  // adjust the slice timestamps
  const leftSliceTimings = calcSliceTimes(leftSlice)
  operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'startMs'],
    value: leftSliceTimings.startMs,
  })
  inverseOperations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'startMs'],
    value: leftSlice.startMs,
  })
  leftSlice.startMs = leftSliceTimings.startMs
  operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'durationMs'],
    value: leftSliceTimings.durationMs,
  })
  inverseOperations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'durationMs'],
    value: leftSlice.durationMs,
  })
  leftSlice.durationMs = leftSliceTimings.durationMs

  const rightSliceTimings = calcSliceTimes(rightSlice)
  rightSlice.startMs = rightSliceTimings.startMs
  rightSlice.durationMs = rightSliceTimings.durationMs

  // hack: add a salt to slice.accuracy to use as a psuedo-unique key
  // this is necessary to prevent react key collisions, because slices don't have unique IDs
  // can get into this situation when creating multiple empty slices in a row
  const leftSliceAccuracy = Number((leftSlice.accuracy || 0).toFixed(2)) + Math.random() / 100
  operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'accuracy'],
    value: leftSliceAccuracy,
  })
  inverseOperations.push({
    type: 'object-set',
    path: ['sliceMeta', `${sliceIndex}`, 'accuracy'],
    value: leftSlice.accuracy,
  })
  leftSlice.accuracy = leftSliceAccuracy
  rightSlice.accuracy = Number((rightSlice.accuracy || 0).toFixed(2)) + Math.random() / 100

  // insert slice to the right
  operations.push({
    type: 'array-insert',
    path: ['sliceMeta'],
    index: sliceIndex + 1,
    values: [cloneDeep(rightSlice)],
  })
  inverseOperations.push({
    type: 'array-delete',
    path: ['sliceMeta'],
    startIndex: sliceIndex + 1,
    endIndex: sliceIndex + 1,
  })
  draftTranscript.sliceMeta.splice(sliceIndex + 1, 0, rightSlice)

  // move the selection to the start of the right slice
  editorAction.afterSelection.type = 'Caret'
  editorAction.afterSelection.start = {
    type: 'token',
    sliceIndex: sliceIndex + 1,
    tokenIndex: 0,
    textOffset: 0,
  }
  editorAction.afterSelection.end = cloneDeep(editorAction.afterSelection.start)

  // push operations to editorAction
  editorAction.revision.operations.push(...operations)
  editorAction.inverseRevision.operations.push(...inverseOperations)
}

export function insertText(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: InsertAction,
): void {
  // if transcript is empty seed a new slice and set selection to the beginning
  if (!draftTranscript.sliceMeta.length) {
    // add new slice
    const newSlice = {
      speakerId: -1,
      speakerAccuracy: 1,
      accuracy: 1,
      startMs: 0,
      durationMs: 1,
      transcript: '',
      tokenMeta: [
        {
          transcript: '',
          startMs: 0,
          durationMs: 1,
          accuracy: 1,
          aligned: false,
        },
      ],
    }
    editorAction.revision.operations.push({
      type: 'array-insert',
      path: ['sliceMeta'],
      index: 0,
      values: [cloneDeep(newSlice)],
    })
    editorAction.inverseRevision.operations.push({
      type: 'array-delete',
      path: ['sliceMeta'],
      startIndex: 0,
      endIndex: 0,
    })
    draftTranscript.sliceMeta.push(newSlice)

    // add unknown speaker if it doesn't exist already
    if (!draftTranscript.speakers[-1]) {
      const unknownSpeaker = {
        name: 'Unknown speaker',
      }
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['speakers', '-1'],
        value: cloneDeep(unknownSpeaker),
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-delete',
        path: ['speakers', '-1'],
      })
      draftTranscript.speakers[-1] = unknownSpeaker
    }

    // update the selection
    editorAction.afterSelection = {
      type: 'Caret',
      start: {
        type: 'token',
        sliceIndex: 0,
        tokenIndex: 0,
        textOffset: 0,
      },
      end: {
        type: 'token',
        sliceIndex: 0,
        tokenIndex: 0,
        textOffset: 0,
      },
    }
  }

  if (!editorAction.afterSelection?.end || !editorAction.afterSelection?.start) {
    throw new Error('operation.afterSelection does not exist')
  }
  // delete the selected range
  if (editorAction.afterSelection.type === 'Range') {
    deleteText(editorAction, draftTranscript)
  }

  if (!action.data) return

  let textToInsert = action.data

  // if caret is at end of slice
  if (
    editorAction.afterSelection.start.type === 'token-space' &&
    editorAction.afterSelection.start.textOffset === 1 &&
    editorAction.afterSelection.start.tokenIndex ===
      draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta.length - 1
  ) {
    if (action.data[0] === '\n') {
      // shift caret to left of last space
      editorAction.afterSelection.end.textOffset = 0
      editorAction.afterSelection.start.textOffset = 0
    } else {
      // insert a new token
      const leftToken =
        draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta[
          editorAction.afterSelection.start.tokenIndex
        ]
      const newToken = {
        transcript: '',
        startMs: leftToken.startMs + leftToken.durationMs + DURATION_TOKEN_GAP_MS,
        durationMs: 0,
        accuracy: 1,
        aligned: false,
      }
      editorAction.revision.operations.push({
        type: 'array-insert',
        path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'tokenMeta'],
        index: editorAction.afterSelection.start.tokenIndex + 1,
        values: [cloneDeep(newToken)],
      })
      editorAction.inverseRevision.operations.push({
        type: 'array-delete',
        path: ['sliceMeta', `${editorAction.afterSelection.start.sliceIndex}`, 'tokenMeta'],
        startIndex: editorAction.afterSelection.start.tokenIndex + 1,
        endIndex: editorAction.afterSelection.start.tokenIndex + 1,
      })
      draftTranscript.sliceMeta[editorAction.afterSelection.start.sliceIndex].tokenMeta.push(
        newToken,
      )

      // shift caret to start of new token
      editorAction.afterSelection.end = {
        type: 'token',
        sliceIndex: editorAction.afterSelection.end.sliceIndex,
        tokenIndex: editorAction.afterSelection.end.tokenIndex + 1,
        textOffset: 0,
      }
      editorAction.afterSelection.start = cloneDeep(editorAction.afterSelection.end)

      // consume spaces at start
      textToInsert = textToInsert.replace(/^ +/, '')
    }
  }

  // normalize selection from token-space to token
  editorAction.afterSelection.start = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.start,
    draftTranscript,
  )
  editorAction.afterSelection.end = normalizeTranscriptSelectionNode(
    editorAction.afterSelection.end,
    draftTranscript,
  )

  // TODO convert to TextInsertOperation + TextDeleteOperation
  // once transcript supports applying revisions directly instead of translating to patches
  // iterate through textToInsert to place characters/tokens/slices
  textToInsert.split('').forEach((character, i) => {
    if (!editorAction.afterSelection?.end) {
      throw new Error(`Could not insert character ${character} at index ${i}, no selection point`)
    }
    const {sliceIndex, tokenIndex, textOffset} = editorAction.afterSelection.end

    if (character === ' ') {
      // ignore repeating spaces and newlines
      if (textToInsert[i - 1] === ' ' || textToInsert[i - 1] === '\n') return
      splitToken(editorAction, draftTranscript)
    } else if (character === '\n') {
      // ignore repeating newlines
      if (textToInsert[i - 1] === '\n') return
      splitSlice(editorAction, draftTranscript)
    } else {
      // add character to current token at offset
      const newText = `${draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript.slice(0, textOffset)}${character}${draftTranscript.sliceMeta[
        sliceIndex
      ].tokenMeta[tokenIndex].transcript.slice(textOffset)}`

      const prevOperation = last(editorAction.revision.operations)
      if (
        prevOperation?.type === 'object-set' &&
        isEqual(prevOperation.path, [
          'sliceMeta',
          `${sliceIndex}`,
          'tokenMeta',
          `${tokenIndex}`,
          'transcript',
        ])
      ) {
        // previous iteration was character into same token, replace last operation instead of appending
        // inverse operation remains the same because it reverts to original
        editorAction.revision.operations[editorAction.revision.operations.length - 1] = {
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'transcript'],
          value: newText,
        }
      } else {
        // set token accuracy to 100%
        editorAction.revision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'accuracy'],
          value: 1,
        })
        editorAction.inverseRevision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'accuracy'],
          value: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].accuracy,
        })
        draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].accuracy = 1

        // insert character into token.transcript
        editorAction.revision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'transcript'],
          value: newText,
        })
        editorAction.inverseRevision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'transcript'],
          value: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript,
        })
      }

      draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].transcript = newText

      // update the selection
      editorAction.afterSelection.end.textOffset += 1
    }
  })

  editorAction.afterSelection.type = 'Caret'
  editorAction.afterSelection.start = cloneDeep(editorAction.afterSelection.end)
}

function updateSpeaker(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: UpdateSpeakerAction,
): void {
  const path = ['speakers', `${action.speakerId}`]
  const originalSpeaker = cloneDeep(get(original(draftTranscript), path))
  const updatedSpeaker = {...originalSpeaker, ...action.data}
  editorAction.revision.operations.push({
    type: 'object-set',
    path,
    value: updatedSpeaker,
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-set',
    path,
    value: cloneDeep(originalSpeaker),
  })

  set(draftTranscript, path, updatedSpeaker)
}

function addSpeaker(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: AddSpeakerAction,
): number {
  let speakerId = 0
  while (draftTranscript.speakers[speakerId]) {
    speakerId += 1
  }
  draftTranscript.speakers[speakerId] = {...action.data}

  const path = ['speakers', `${speakerId}`]
  const speaker = cloneDeep(action.data)
  editorAction.revision.operations.push({
    type: 'object-set',
    path,
    value: speaker,
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-delete',
    path,
  })

  return speakerId
}

function deleteSpeaker(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: DeleteSpeakerAction,
): void {
  // remove speaker object
  editorAction.revision.operations.push({
    type: 'object-delete',
    path: ['speakers', `${action.speakerId}`],
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-set',
    path: ['speakers', `${action.speakerId}`],
    value: cloneDeep(current(draftTranscript.speakers[action.speakerId])),
  })
  delete draftTranscript.speakers[action.speakerId]

  // unassign speaker from slices
  draftTranscript.sliceMeta.forEach((slice, i) => {
    if (slice.speakerId === action.speakerId) {
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['sliceMeta', `${i}`, 'speakerId'],
        value: -1,
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-set',
        path: ['sliceMeta', `${i}`, 'speakerId'],
        value: slice.speakerId,
      })
      slice.speakerId = -1

      // create unknown speaker if it doesn't exist already
      if (!draftTranscript.speakers[-1]) {
        draftTranscript.speakers[-1] = {name: 'Unknown Speaker'}
        editorAction.revision.operations.push({
          type: 'object-set',
          path: ['speakers', '-1'],
          value: {name: 'Unknown Speaker'},
        })
        editorAction.inverseRevision.operations.push({
          type: 'object-delete',
          path: ['speakers', '-1'],
        })
      }
    }
  })
}

export function changeSliceSpeaker(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: ChangeSliceSpeakerAction,
): void {
  let {speakerId} = action
  if (speakerId === null) {
    if (!action.data) throw new Error('Speaker data must be provided when adding a new speaker')
    // create speaker if it doesn't exist
    speakerId = addSpeaker(editorAction, draftTranscript, {type: 'add-speaker', data: action.data})
  }

  // set slice speaker id
  editorAction.revision.operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${action.sliceIndex}`, 'speakerId'],
    value: speakerId,
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${action.sliceIndex}`, 'speakerId'],
    value: draftTranscript.sliceMeta[action.sliceIndex].speakerId,
  })
  draftTranscript.sliceMeta[action.sliceIndex].speakerId = speakerId

  // set speaker acccuracy to 100%
  editorAction.revision.operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${action.sliceIndex}`, 'speakerAccuracy'],
    value: 1,
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-set',
    path: ['sliceMeta', `${action.sliceIndex}`, 'speakerAccuracy'],
    value: draftTranscript.sliceMeta[action.sliceIndex].speakerAccuracy,
  })
  draftTranscript.sliceMeta[action.sliceIndex].speakerAccuracy = 1
}

export function updateAccuracy(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: UpdateAccuracyAction,
): void {
  const {endToken, startToken} = action
  getTokenRange(draftTranscript, {startToken, endToken}).forEach(
    ({token, sliceIndex, tokenIndex}) => {
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'accuracy'],
        value: action.accuracy,
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-set',
        path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'accuracy'],
        value: token.accuracy,
      })
      draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].accuracy = action.accuracy
    },
  )
}

export function alignTokens(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: AlignTokensAction,
): void {
  const modifiedSliceIndices: Set<number> = new Set()
  action.ranges.forEach(({range: {startToken, endToken}, tokens: alignedTokens}) => {
    if (!startToken || !endToken) return
    const tokenRange = getTokenRange(draftTranscript, {
      startToken: indexFromPath(startToken),
      endToken: indexFromPath(endToken),
    })

    for (let i = 0; i < alignedTokens.length; i += 1) {
      const {sliceIndex, tokenIndex} = tokenRange[i]

      // if the token is not the same as the aligned token, it means the alignment failed at this point
      // or the underlying transcript has changed, skip the rest of the tokens in this range
      if (tokenRange[i].token.transcript !== alignedTokens[i].transcript) return

      modifiedSliceIndices.add(sliceIndex)

      if (!(alignedTokens[i].startMs === 0 && alignedTokens[i].durationMs === 0)) {
        // set token.startMs
        editorAction.revision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'startMs'],
          value: alignedTokens[i].startMs,
        })
        editorAction.inverseRevision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'startMs'],
          value: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].startMs,
        })
        draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].startMs =
          alignedTokens[i].startMs

        // set token.durationMs
        editorAction.revision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'durationMs'],
          value: alignedTokens[i].durationMs,
        })
        editorAction.inverseRevision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'durationMs'],
          value: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].durationMs,
        })
        draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].durationMs =
          alignedTokens[i].durationMs
      }

      // set token.aligned to true
      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'aligned'],
        value: true,
      })
      if (draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].aligned !== undefined) {
        editorAction.inverseRevision.operations.push({
          type: 'object-set',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'aligned'],
          value: draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].aligned,
        })
      } else {
        editorAction.inverseRevision.operations.push({
          type: 'object-delete',
          path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'aligned'],
        })
      }
      draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex].aligned = true
    }
  })

  // update slice timestamps
  modifiedSliceIndices.forEach((modifiedSliceIndex) => {
    const modifiedSlice = draftTranscript.sliceMeta[modifiedSliceIndex]
    const modifiedSliceTimings = calcSliceTimes(modifiedSlice)
    // set slice.startMs
    editorAction.revision.operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${modifiedSliceIndex}`, 'startMs'],
      value: modifiedSliceTimings.startMs,
    })
    editorAction.inverseRevision.operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${modifiedSliceIndex}`, 'startMs'],
      value: modifiedSlice.startMs,
    })
    modifiedSlice.startMs = modifiedSliceTimings.startMs
    // set slice.durationMs
    editorAction.revision.operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${modifiedSliceIndex}`, 'durationMs'],
      value: modifiedSliceTimings.durationMs,
    })
    editorAction.inverseRevision.operations.push({
      type: 'object-set',
      path: ['sliceMeta', `${modifiedSliceIndex}`, 'durationMs'],
      value: modifiedSlice.durationMs,
    })
    modifiedSlice.durationMs = modifiedSliceTimings.durationMs
  })
}

export function addAnnotation(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: AddAnnotationAction,
): string {
  // add annotations container if it doesn't exist
  if (!draftTranscript.annotations) {
    editorAction.revision.operations.push({
      type: 'object-set',
      path: ['annotations'],
      value: {},
    })
    editorAction.inverseRevision.operations.push({
      type: 'object-delete',
      path: ['annotations'],
    })
    draftTranscript.annotations = {}
  }

  // add the annotation
  const annotationId = action.annotationId || getUniqueId()
  const annotation = {...action.annotation, id: annotationId}
  editorAction.revision.operations.push({
    type: 'object-set',
    path: ['annotations', annotationId],
    value: cloneDeep(annotation),
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-delete',
    path: ['annotations', annotationId],
  })
  draftTranscript.annotations[annotationId] = annotation

  // add annotation id to all slices/tokens in the range
  // TODO convert this to RangeAddAnnotationOperation
  // once transcript supports applying revisions directly instead of translating to patches
  action.ranges?.forEach((range) => {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = range.start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = range.end

    for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
      const slice = draftTranscript.sliceMeta[sliceIndex]

      if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
        const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
        const endTokenIndexInSlice =
          sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

        for (
          let tokenIndex = startTokenIndexInSlice;
          tokenIndex <= endTokenIndexInSlice;
          tokenIndex += 1
        ) {
          const token = slice.tokenMeta[tokenIndex]
          // add token annotations container if it doesn't exist
          if (!token.annotations) {
            editorAction.revision.operations.push({
              type: 'object-set',
              path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
              value: [],
            })
            editorAction.inverseRevision.operations.push({
              type: 'object-delete',
              path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
            })
            token.annotations = []
          }

          // add annotation id to token annotations, if not already included
          if (!token.annotations.find((a) => a.id === annotationId)) {
            editorAction.revision.operations.push({
              type: 'array-insert',
              path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
              index: token.annotations.length,
              values: [{id: annotationId}],
            })
            editorAction.inverseRevision.operations.push({
              type: 'array-delete',
              path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
              startIndex: token.annotations.length,
              endIndex: token.annotations.length,
            })
            token.annotations.push({id: annotationId})
          }
        }
      } else {
        // add slice annotations container if it doesn't exist
        if (!slice.annotations) {
          editorAction.revision.operations.push({
            type: 'object-set',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
            value: [],
          })
          editorAction.inverseRevision.operations.push({
            type: 'object-delete',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
          })

          slice.annotations = []
        }

        // add annotation id to slice annotations, if not already included
        if (!slice.annotations.find((a) => a.id === annotationId)) {
          editorAction.revision.operations.push({
            type: 'array-insert',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
            index: slice.annotations.length,
            values: [{id: annotationId}],
          })
          editorAction.inverseRevision.operations.push({
            type: 'array-delete',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
            startIndex: slice.annotations.length,
            endIndex: slice.annotations.length,
          })
          slice.annotations.push({id: annotationId})
        }
      }
    }
  })

  return annotationId
}

export function deleteAnnotation(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: DeleteAnnotationAction,
): void {
  // if there is no specific range provided delete the top-level annotation
  if (!action.ranges) {
    if (draftTranscript.annotations) {
      editorAction.revision.operations.push({
        type: 'object-delete',
        path: ['annotations', action.annotationId],
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-set',
        path: ['annotations', action.annotationId],
        value: cloneDeep(current(draftTranscript.annotations[action.annotationId])),
      })
      delete draftTranscript.annotations[action.annotationId]
    }
    return
  }

  // otherwise, only remove the annotation pointers from that range
  // TODO convert this to RangeDeleteAnnotationOperation
  // once transcript supports applying revisions directly instead of translating to patches
  action.ranges.forEach((range) => {
    const {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex} = range.start
    const {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex} = range.end

    for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
      const slice = draftTranscript.sliceMeta[sliceIndex]

      if (startTokenIndex !== undefined && endTokenIndex !== undefined) {
        const startTokenIndexInSlice = sliceIndex === startSliceIndex ? startTokenIndex : 0
        const endTokenIndexInSlice =
          sliceIndex === endSliceIndex ? (endTokenIndex as number) : slice.tokenMeta.length - 1

        for (
          let tokenIndex = startTokenIndexInSlice;
          tokenIndex <= endTokenIndexInSlice;
          tokenIndex += 1
        ) {
          const token = slice.tokenMeta[tokenIndex]
          if (token.annotations) {
            const i = token.annotations.findIndex((a) => a.id === action.annotationId)
            if (i !== -1) {
              // remove annotation pointer from each token within the range
              editorAction.revision.operations.push({
                type: 'array-delete',
                path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
                startIndex: i,
                endIndex: i,
              })
              editorAction.inverseRevision.operations.push({
                type: 'array-insert',
                path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta', `${tokenIndex}`, 'annotations'],
                index: i,
                values: [cloneDeep(current(token.annotations[i]))],
              })
              token.annotations.splice(i, 1)
            }
          }
        }
      } else if (slice.annotations) {
        // remove annotation pointer from each slice within the range
        const i = slice.annotations.findIndex((a) => a.id === action.annotationId)
        if (i !== -1) {
          // remove annotation pointer from each token within the range
          editorAction.revision.operations.push({
            type: 'array-delete',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
            startIndex: i,
            endIndex: i,
          })
          editorAction.inverseRevision.operations.push({
            type: 'array-insert',
            path: ['sliceMeta', `${sliceIndex}`, 'annotations'],
            index: i,
            values: [cloneDeep(current(slice.annotations[i]))],
          })
          slice.annotations.splice(i, 1)
        }
      }
    }
  })
}

export function replaceTokens(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: ReplaceTokensAction,
): void {
  action.data.forEach(({sliceIndex, tokenIndex, token}) => {
    // if range does not match existing tokens, skip the rest
    if (!draftTranscript.sliceMeta[sliceIndex]?.tokenMeta[tokenIndex]) return

    // replace entire token
    // TODO convert to object-set instead of array-delete + array-insert
    // once transcript supports applying revisions directly instead of translating to patches
    editorAction.revision.operations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex,
      endIndex: tokenIndex,
    })
    editorAction.inverseRevision.operations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex,
      values: [cloneDeep(current(draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex]))],
    })
    editorAction.revision.operations.push({
      type: 'array-insert',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      index: tokenIndex,
      values: [cloneDeep(token)],
    })
    editorAction.inverseRevision.operations.push({
      type: 'array-delete',
      path: ['sliceMeta', `${sliceIndex}`, 'tokenMeta'],
      startIndex: tokenIndex,
      endIndex: tokenIndex,
    })
    draftTranscript.sliceMeta[sliceIndex].tokenMeta[tokenIndex] = token
  })
}

export function replaceTranscript(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: ReplaceTranscriptAction,
): void {
  const currentTranscriptClone = cloneDeep(current(draftTranscript))

  // add/overwrite all keys present in next
  Object.keys(action.data).forEach((key) => {
    editorAction.revision.operations.push({
      type: 'object-set',
      path: [key],
      value: cloneDeep(action.data[key as keyof APITranscript]),
    })
    editorAction.inverseRevision.operations.push({
      type: 'object-set',
      path: [key],
      value: currentTranscriptClone[key as keyof APITranscript],
    })
    /* @ts-ignore ts(2322) */
    draftTranscript[key as keyof APITranscript] = action.data[key as keyof APITranscript]
  })

  // delete keys present in current but not in next
  Object.keys(draftTranscript).forEach((key) => {
    if (!(key in action.data)) {
      editorAction.revision.operations.push({
        type: 'object-delete',
        path: [key],
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-set',
        path: [key],
        value: currentTranscriptClone[key as keyof APITranscript],
      })
      delete draftTranscript[key as keyof APITranscript]
    }
  })

  editorAction.afterSelection = {type: 'Caret', start: null, end: null}
}

export function appendSlice(
  editorAction: EditorAction,
  draftTranscript: Draft<APITranscript>,
  action: AppendSliceAction,
): void {
  const {slice} = action

  // If the most recent slice is a partial, remove it before appending new slice
  let removedPartialSlice: APITranscriptSlice | undefined
  const lastSliceIndex = draftTranscript.sliceMeta.length - 1
  const lastSlice = draftTranscript.sliceMeta[lastSliceIndex]
  if (lastSlice?.isPartial) {
    removedPartialSlice = cloneDeep(
      current(draftTranscript.sliceMeta[draftTranscript.sliceMeta.length - 1]),
    )

    editorAction.revision.operations.push({
      type: 'array-delete',
      path: ['sliceMeta'],
      startIndex: draftTranscript.sliceMeta.length - 1,
      endIndex: draftTranscript.sliceMeta.length - 1,
    })

    editorAction.inverseRevision.operations.push({
      type: 'array-insert',
      path: ['sliceMeta'],
      index: lastSliceIndex,
      values: [removedPartialSlice],
    })

    draftTranscript.sliceMeta.pop()

    if (removedPartialSlice) {
      // Trim the transcript text to remove the partial slice text
      const newTranscriptText = draftTranscript.transcript
        .substring(0, draftTranscript.transcript.lastIndexOf(removedPartialSlice.transcript))
        .trim()

      editorAction.revision.operations.push({
        type: 'object-set',
        path: ['transcript'],
        value: newTranscriptText,
      })
      editorAction.inverseRevision.operations.push({
        type: 'object-set',
        path: ['transcript'],
        value: draftTranscript.transcript,
      })

      draftTranscript.transcript = newTranscriptText
    }
  }

  // Normalize incoming slice

  /**
   * the AddTranscript message adds an isPartial prop to the slice but this needs to be
   * propagated to the tokens for styling. isRecent is added to the tokens that were not
   * in the prior partial slice
   */
  if (slice.isPartial) {
    const previousSliceLength = removedPartialSlice ? removedPartialSlice.tokenMeta.length : 0
    slice.tokenMeta.forEach((_, i) => {
      slice.tokenMeta[i].isPartial = true
      if (previousSliceLength && i >= previousSliceLength) {
        slice.tokenMeta[i].isRecent = true
      }
    })
  }

  if (slice.tokenMeta.length === 0) {
    slice.tokenMeta.push({
      transcript: ' ',
      startMs: slice.startMs,
      durationMs: 1,
      accuracy: 1,
    })
    slice.transcript = ' '
  }

  // Add the new slice
  editorAction.revision.operations.push({
    type: 'array-insert',
    path: ['sliceMeta'],
    index: draftTranscript.sliceMeta.length,
    values: [slice],
  })

  editorAction.inverseRevision.operations.push({
    type: 'array-delete',
    path: ['sliceMeta'],
    startIndex: draftTranscript.sliceMeta.length,
    endIndex: draftTranscript.sliceMeta.length,
  })

  draftTranscript.sliceMeta.push(slice)
  // Add to speakers if not already present
  if (!draftTranscript.speakers[slice.speakerId]) {
    const speakerInfo = {
      name: `Speaker ${slice.speakerId}`,
    }
    editorAction.revision.operations.push({
      type: 'object-set',
      path: ['speakers', `${slice.speakerId}`],
      value: speakerInfo,
    })
    editorAction.inverseRevision.operations.push({
      type: 'object-delete',
      path: ['speakers', `${slice.speakerId}`],
    })
    draftTranscript.speakers[slice.speakerId] = speakerInfo
  }
  // Update transcript text to include the new slice
  const updatedTranscript = `${draftTranscript.transcript} ${slice.transcript}`.trim()
  editorAction.revision.operations.push({
    type: 'object-set',
    path: ['transcript'],
    value: updatedTranscript,
  })
  editorAction.inverseRevision.operations.push({
    type: 'object-set',
    path: ['transcript'],
    value: draftTranscript.transcript,
  })
  draftTranscript.transcript = updatedTranscript
}

/**
 * Takes an edit action representing a desired change and builds a corresponding EditorAction
 * which includes the revision and other metadata needed to edit the transcript.
 *
 * If the edit action will not cause a change returns null.
 */
export function produceEditorAction({
  action,
  transcript,
  transcriptSelection,
  onError,
  log,
}: {
  action: TranscriptEditAction
  transcript: APITranscript
  transcriptSelection: TranscriptSelection | null
  onError?: (
    error: Error,
    action: TranscriptEditAction,
    transcript: APITranscript,
    transcriptSelection: TranscriptSelection | null,
  ) => void
  log: Logger
}): EditorAction | null {
  // mutated by the action handlers
  const editorAction: EditorAction = {
    revision: {
      operations: [],
    },

    inverseRevision: {
      operations: [],
    },

    // beforeSelection should never change, important for undo/redo
    beforeSelection: cloneDeep(transcriptSelection),

    // afterSelection will be updated to reflect the new selection after every handler
    // multiple handlers can be involved to produce one operation
    // handlers should always reference + mutate afterSelection
    afterSelection: cloneDeep(transcriptSelection),

    undoable: true,
  }

  try {
    // wrap within a single produce call so action handlers can compose each other
    produce(transcript, (draftTranscript) => {
      switch (action.type) {
        case 'delete-text':
          deleteText(editorAction, draftTranscript, action)
          break
        case 'insert-text':
          insertText(editorAction, draftTranscript, action)
          break
        case 'split-slice':
          splitSlice(editorAction, draftTranscript)
          break
        case 'replace-tokens':
          replaceTokens(editorAction, draftTranscript, action)
          break
        case 'replace-transcript':
          replaceTranscript(editorAction, draftTranscript, action)
          break
        case 'update-speaker':
          updateSpeaker(editorAction, draftTranscript, action)
          break
        case 'add-speaker':
          addSpeaker(editorAction, draftTranscript, action)
          break
        case 'delete-speaker':
          deleteSpeaker(editorAction, draftTranscript, action)
          break
        case 'change-slice-speaker':
          changeSliceSpeaker(editorAction, draftTranscript, action)
          break
        case 'update-accuracy':
          updateAccuracy(editorAction, draftTranscript, action)
          break
        case 'align-tokens':
          alignTokens(editorAction, draftTranscript, action)
          break
        case 'add-annotation':
          addAnnotation(editorAction, draftTranscript, action)
          break
        case 'delete-annotation':
          deleteAnnotation(editorAction, draftTranscript, action)
          break
        case 'append-slice':
          appendSlice(editorAction, draftTranscript, action)
          break
        default:
          assertNever(action, 'Unhandled action type')
      }
    })
  } catch (e) {
    if (onError) {
      onError(e as Error, action, transcript, transcriptSelection)
    } else {
      log.error(e as Error, {
        type: action.type,
        action: JSON.stringify(action),
      })
    }
  }

  if (editorAction.revision.operations.length === 0) return null

  // reverse inverse operations order because they were accumulated in same order as forward ops
  editorAction.inverseRevision.operations.reverse()

  return editorAction
}
