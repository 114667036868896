import {useLogger} from '@kensho/lumberjack'
import {usePrevious} from '@kensho/tacklebox'
import {useEffect, useMemo, useRef, useState} from 'react'
import {sortBy} from 'lodash-es'

import useAlignTokens, {AlignTokensParams} from '../../../api/useAlignTokens'
import {APITranscript, APITranscriptToken, AsyncStatus, Mode, Stage} from '../../../types/types'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {indexFromPath} from '../../../utils/transcriptUtils'
import useMultiplayerContext from '../../../hooks/useMultiplayerContext'

import {useDispatchEditorAction} from './DispatchEditorActionProvider'

const THROTTLE_MS = 5 * 1000
const MAX_RETRIES = 3
const MAX_RANGE_LENGTH = 75

/**
 * Tracks the number of failures for a given alignment range within a transcript.
 * If the number of failures exceeds the max retries, the range will be skipped on subsequent calls.
 * This is to prevent the transcript from getting stuck trying to align a range that will never align.
 */
let failureCache: {[alignmentRange: string]: number} = {}

/**
 * Traverse slices and tokens to find the next range of up to MAX_RANGE_LENGTH tokens with aligned: false
 * If no range is found, returns null
 * If the entire transcript is unaligned, returns the whole transcript range
 *
 * 1. Not all tokens in the returned range have to be unaligned
 * 2. Unaligned tokens within the range are not necessarily contiguous
 */
export const findNextUnalignedRange = (
  transcript: APITranscript,
  startOffset?: {
    sliceIndex: number
    tokenIndex: number
  },
): AlignTokensParams | null => {
  let nextUnalignedRange = null
  // if we have a startOffset, then we have already looked at the whole transcript
  // so we know that there was an unaligned range already found
  let entireTranscriptIsUnaligned = !startOffset
  if (entireTranscriptIsUnaligned) {
    // initialized to true, check if that is valid
    for (let sliceIndex = 0; sliceIndex < transcript.sliceMeta.length; sliceIndex += 1) {
      const slice = transcript.sliceMeta[sliceIndex]
      for (let tokenIndex = 0; tokenIndex < slice.tokenMeta.length; tokenIndex += 1) {
        const token = slice.tokenMeta[tokenIndex]
        if (!('aligned' in token) || token.aligned === true) {
          entireTranscriptIsUnaligned = false
          break
        }
      }
    }
  }

  if (entireTranscriptIsUnaligned) {
    // use the whole transcript range
    nextUnalignedRange = {
      startToken: '/slice_meta/0/token_meta/0',
      endToken: `/slice_meta/${transcript.sliceMeta.length - 1}/token_meta/${
        transcript.sliceMeta[transcript.sliceMeta.length - 1].tokenMeta.length - 1
      }`,
    }
  } else {
    // otherwise, walk through slices and tokens to find the range that needs to be aligned
    const currentRange: AlignTokensParams = {startToken: '', endToken: ''}
    let lastUnalignedToken = {sliceIndex: 0, tokenIndex: 0}
    let currentRangeLength = 0
    let sliceIndex = startOffset?.sliceIndex || 0
    let tokenIndex = startOffset?.tokenIndex || 0

    for (sliceIndex; sliceIndex < transcript.sliceMeta.length; sliceIndex += 1) {
      const slice = transcript.sliceMeta[sliceIndex]
      if (nextUnalignedRange) break

      for (tokenIndex; tokenIndex < slice.tokenMeta.length; tokenIndex += 1) {
        const token = slice.tokenMeta[tokenIndex]

        if ('aligned' in token && token.aligned === false) {
          lastUnalignedToken = {sliceIndex, tokenIndex}
        }

        if ('aligned' in token && token.aligned === false && !currentRange.startToken) {
          currentRange.startToken = `/slice_meta/${sliceIndex}/token_meta/${tokenIndex}`
        }

        if (currentRange.startToken) {
          currentRange.endToken = `/slice_meta/${sliceIndex}/token_meta/${tokenIndex}`
          currentRangeLength += 1
        }

        if (currentRangeLength >= MAX_RANGE_LENGTH) {
          currentRange.endToken = `/slice_meta/${sliceIndex}/token_meta/${tokenIndex}`

          nextUnalignedRange = currentRange
          break
        }
      }

      // reset tokenIndex to 0 for the next slice
      tokenIndex = 0
    }

    // if we reached the end of the transcript add the currently open range
    if (currentRange.startToken) nextUnalignedRange = currentRange

    // trim the range to the last unaligned token
    if (nextUnalignedRange) {
      const endOfRangeToken = indexFromPath(nextUnalignedRange.endToken as string)
      if (
        lastUnalignedToken.sliceIndex < endOfRangeToken.sliceIndex ||
        lastUnalignedToken.tokenIndex < endOfRangeToken.tokenIndex
      ) {
        nextUnalignedRange.endToken = `/slice_meta/${lastUnalignedToken.sliceIndex}/token_meta/${lastUnalignedToken.tokenIndex}`
      }
    }
  }

  return nextUnalignedRange
}

/**
 * Periodically aligns the transcript.
 */
export default function useBackgroundAligner({
  transcriptId,
  transcript,
  mode,
  stage,
  disabled = false,
}: {
  transcriptId: string
  transcript: APITranscript
  mode: Mode
  stage: Stage
  disabled?: boolean
}): AsyncStatus {
  const alignTokens = useAlignTokens()
  const log = useLogger()
  const prevTranscriptId = usePrevious(transcriptId)
  const {dispatchEditorAction} = useDispatchEditorAction()
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const {sessionId, connectedUsers} = useMultiplayerContext()
  const sortedConnectedUsers = useMemo(
    () => sortBy(connectedUsers, (connectedUser) => connectedUser.sessionId),
    [connectedUsers],
  )
  // sort by sessionId so that clients can determine if they should elect themselves
  // as the one responsible for alignment
  const shouldAlign = sessionId && sortedConnectedUsers[0]?.sessionId === sessionId

  // the last time we called the alignTokens endpoint
  const lastCalledRef = useRef(Date.now())
  const requestRef = useRef(false)

  const transcriptRef = useRef(transcript)
  useEffect(() => {
    transcriptRef.current = transcript
  }, [transcript])

  // look for a range in the transcript where tokens have the aligned = false flag
  // send that range to the alignment endpoint
  // then patch the transcript with the alignment response received
  useEffect(() => {
    if (disabled || !shouldAlign) return undefined
    if (transcriptId !== prevTranscriptId) failureCache = {}

    const alignOuter = async (): Promise<void> => {
      if (requestRef.current) return
      if (Date.now() - lastCalledRef.current < THROTTLE_MS) return
      if (
        mode === 'realtime' ||
        stage !== 'POST_TRANSCRIPTION' ||
        !transcriptId ||
        !transcriptRef.current ||
        transcriptRef.current.sliceMeta.length === 0
      )
        return

      const align = async (): Promise<void> => {
        if (!transcriptRef.current) return

        let rangeToAlign: AlignTokensParams | null = null

        let startOffset = {sliceIndex: 0, tokenIndex: 0}
        while (
          !rangeToAlign &&
          startOffset.sliceIndex < transcriptRef.current.sliceMeta.length &&
          startOffset.tokenIndex <
            transcriptRef.current.sliceMeta[startOffset.sliceIndex].tokenMeta.length
        ) {
          rangeToAlign = findNextUnalignedRange(transcriptRef.current, startOffset)
          if (rangeToAlign) {
            const requestKey = `${rangeToAlign.startToken}:${rangeToAlign.endToken}`
            if (failureCache[requestKey] && failureCache[requestKey] >= MAX_RETRIES) {
              startOffset = indexFromPath(rangeToAlign.endToken as string)
              startOffset.tokenIndex += 1
              if (
                startOffset.tokenIndex >=
                transcriptRef.current.sliceMeta[startOffset.sliceIndex].tokenMeta.length
              ) {
                startOffset.sliceIndex += 1
                startOffset.tokenIndex = 0
              }

              rangeToAlign = null
            }
          } else {
            break
          }
        }
        if (!rangeToAlign) return

        let alignmentResponse: APITranscriptToken[] | null = null
        try {
          setStatus('pending')
          lastCalledRef.current = Date.now()
          alignmentResponse = await alignTokens(transcriptId, rangeToAlign)
        } catch (e) {
          setStatus('error')
          const requestKey = `${rangeToAlign.startToken}:${rangeToAlign.endToken}`
          if (!failureCache[requestKey]) {
            failureCache[requestKey] = 0
          }
          failureCache[requestKey] += 1

          if (failureCache[requestKey] > MAX_RETRIES) {
            log.error(`Range exceeded max retries`, {
              startToken: rangeToAlign.startToken,
              endToken: rangeToAlign.endToken,
            })
          }

          // log failure but ignore error because this is a background process and we don't
          // want to interrupt the user's workflow
          if (
            e instanceof Error &&
            !e.message.includes('Reached unexpected end while trying to process alignments')
          ) {
            log.error(e, {
              message: `Failed to align range ${rangeToAlign.startToken} - ${rangeToAlign.endToken}}`,
            })
          }
        }

        if (!alignmentResponse || !transcriptRef.current) {
          setStatus('idle')
          return
        }

        // update the transcript with the new alignmentResponse
        const editorAction = produceEditorAction({
          action: {
            type: 'align-tokens',
            ranges: [{range: rangeToAlign, tokens: alignmentResponse}],
          },
          transcript: transcriptRef.current,
          transcriptSelection: null,
          log,
        })

        if (!editorAction) {
          setStatus('idle')
          return
        }

        // prevent selection change and undo/redo since this is a background process not initiated by the user
        editorAction.selectionChangeDisabled = true
        editorAction.undoable = false
        dispatchEditorAction(editorAction)

        setStatus('success')
      }

      try {
        requestRef.current = true
        await align()
      } catch (e) {
        // noop
      } finally {
        requestRef.current = false
      }
    }

    // this interval within this effect acts as a debounce
    // it is separate from the lastCalledRef and THROTTLE_MS because we want to
    // keep periodically checking for re-alignment even if the last call was skipped
    const intervalId = window.setInterval(() => {
      alignOuter()
    }, 3000)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [
    disabled,
    shouldAlign,
    transcriptId,
    prevTranscriptId,
    transcript,
    mode,
    stage,
    alignTokens,
    log,
    dispatchEditorAction,
  ])

  return status
}
