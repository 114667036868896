import {useCallback, useEffect, useState} from 'react'
import {Select} from '@kensho/neo'

import closeMediaStream from '../utils/closeMediaStream'
import useAudioDevices from '../core/transcription/transcriptionConfig/useAudioDevices'

interface AudioInputDeviceSelectProps {
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextValue?: string) => void
}

export default function AudioInputDeviceSelect({
  audioInputDeviceId,
  onAudioInputDeviceChange,
}: AudioInputDeviceSelectProps): React.ReactNode {
  const audioDevices = useAudioDevices()
  const {audioInputDevicesQuery, microphonePermissionQuery} = audioDevices
  const {audioInputDevices} = audioInputDevicesQuery
  const [isDeviceCheckPending, setIsDeviceCheckPending] = useState(false)
  const [initialSetFlag, setInitialSetFlag] = useState(false)
  const [deviceChangeError, setDeviceChangeError] = useState<string | undefined>()

  let errorText: string | undefined
  if (microphonePermissionQuery.status === 'error') {
    errorText = 'Your microphone is not enabled. Please enable microphone in your browser settings.'
  } else if (audioInputDevicesQuery.status === 'error') {
    errorText =
      'Something went wrong when we tried to get a list of your audio devices. Try refreshing the page or granting permission.'
  } else if (audioInputDevices && audioInputDevices.length === 0) {
    errorText = 'No microphone detected. Please connect a microphone'
  } else if (deviceChangeError) {
    errorText = deviceChangeError
  }

  const handleDeviceChange = useCallback(
    async (next: string, label: string | undefined) => {
      setIsDeviceCheckPending(true)
      setDeviceChangeError(undefined)
      onAudioInputDeviceChange(undefined)
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {deviceId: next},
        })
        closeMediaStream(stream)
        onAudioInputDeviceChange(next)
      } catch {
        onAudioInputDeviceChange(undefined)
        if (label) {
          setDeviceChangeError(`Unable to read from ${label}`)
        }
      }
      setIsDeviceCheckPending(false)
    },
    [onAudioInputDeviceChange],
  )

  useEffect(() => {
    if (audioInputDevicesQuery.status !== 'success') return
    if (initialSetFlag) return
    setInitialSetFlag(true)
    if (audioInputDevices && audioInputDevices.length > 0) {
      handleDeviceChange(audioInputDevices[0].deviceId, undefined)
    }
  }, [handleDeviceChange, initialSetFlag, audioInputDevicesQuery.status, audioInputDevices])

  return (
    <Select
      label="Microphone setting"
      disabled={!audioInputDevices || isDeviceCheckPending}
      errorText={errorText}
      isInvalid={!!errorText}
      onChange={(value) => {
        const label = audioInputDevices?.find((device) => device.deviceId === value)?.label
        handleDeviceChange(value, label)
      }}
      options={[
        {
          disabled: true,
          label: isDeviceCheckPending ? 'Loading…' : 'Select a microphone',
          value: '',
        },
        ...(audioInputDevices?.map((device, i) => ({
          label: device.label || `Microphone ${i + 1}`,
          value: device.deviceId,
        })) || []),
      ]}
      value={audioInputDeviceId || ''}
    />
  )
}
