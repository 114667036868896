import {Patch} from 'immer'
import {cloneDeep, reverse} from 'lodash-es'

import {Revision} from '../types/schemas'

export function convertRevisionToPatches(revision: Revision): Patch[] {
  // TODO remove ignore comment once all cases are handled
  /* @ts-ignore */
  return revision.operations.reduce<Patch[]>((acc, operation) => {
    switch (operation.type) {
      case 'object-set':
        acc.push({
          // "add" instead of "replace" because, as per JSONPatch spec (https://datatracker.ietf.org/doc/html/rfc6902/#section-4.1),
          // "add" will replace a value if it already exists, but "replace" will fail if it doesn't exist
          op: 'add',
          path: [...operation.path],
          value: cloneDeep(operation.value),
        })
        break
      case 'object-delete':
        acc.push({
          op: 'remove',
          path: [...operation.path],
        })
        break
      case 'array-insert':
        acc.push(
          ...reverse([...operation.values]).map<Patch>((value) => ({
            op: 'add',
            path: [...operation.path, `${operation.index}`],
            value: cloneDeep(value),
          })),
        )
        break
      case 'array-delete': {
        acc.push(
          ...Array.from(Array(operation.endIndex - operation.startIndex + 1).keys()).map<Patch>(
            () => ({
              op: 'remove',
              // points to same index n times because array is shifted immediately between removes
              path: [...operation.path, `${operation.startIndex}`],
            }),
          ),
        )
        break
      }
      default:
        throw new Error(`Unexpected value: ${operation.type}`)
    }

    return acc
  }, [])
}

export function convertPatchesToRevision(patches: Patch[]): Revision {
  function isArrayPath(path: (string | number)[]): boolean {
    return (
      path[0] !== 'annotations' &&
      ['sliceMeta', 'tokenMeta', 'annotations'].includes(`${path[path.length - 2]}`)
    )
  }

  return {
    operations: patches.map((patch) => {
      switch (patch.op) {
        case 'add':
          if (isArrayPath(patch.path)) {
            const path = patch.path.map((part) => `${part}`)
            const index = Number(path.pop())
            return {
              type: 'array-insert',
              path,
              index,
              values: [patch.value],
            }
          }

          return {
            type: 'object-set',
            path: patch.path.map((part) => `${part}`),
            value: cloneDeep(patch.value),
          }
        case 'replace':
          return {
            type: 'object-set',
            path: patch.path.map((part) => `${part}`),
            value: cloneDeep(patch.value),
          }
        case 'remove':
          if (isArrayPath(patch.path)) {
            const path = patch.path.map((part) => `${part}`)
            const index = Number(path.pop())
            return {
              type: 'array-delete',
              path,
              startIndex: index,
              endIndex: index,
            }
          }

          return {
            type: 'object-delete',
            path: patch.path.map((part) => `${part}`),
          }
        default:
          throw new Error(`Unexpected value: ${patch.op}`)
      }
    }),
  }
}
