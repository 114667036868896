import {Button, ButtonLink, Dialog, DropdownMenu} from '@kensho/neo'
import {useState} from 'react'
import {useNavigate} from 'react-router'

interface NewTranscriptMenuProps {
  showUnsavedTranscriptWarning: boolean
  setTranscriptDownloaded: React.Dispatch<React.SetStateAction<boolean>>
  reset: () => void
}

export default function NewTranscriptMenu({
  showUnsavedTranscriptWarning,
  setTranscriptDownloaded,
  reset,
}: NewTranscriptMenuProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      <Dialog
        backdrop="light"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Leave page and discard this transcript?"
      >
        <div className="flex flex-col gap-4">
          <p>Are you sure you want to leave this page and discard this transcript?</p>
          <p>
            Scribe does not save real time transcriptions, so be sure to download your transcript
            text before closing your session, or it will be lost permanently.
          </p>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button onClick={() => setIsOpen(false)}>Cancel & stay on page</Button>
          <ButtonLink
            intent="danger"
            href="/transcriptions/new/realtime"
            onClick={() => {
              reset()
            }}
          >
            Leave & discard transcript
          </ButtonLink>
        </div>
      </Dialog>

      <div className="flex w-full flex-col">
        <DropdownMenu
          items={[
            {
              id: 'batch',
              label: 'Upload',
              onAction() {
                reset()
                navigate('/transcriptions/new/batch')
              },
            },
            {
              id: 'realtime',
              label: 'Record',
              onAction() {
                if (showUnsavedTranscriptWarning) {
                  setIsOpen(true)
                  setTranscriptDownloaded(true)
                } else {
                  reset()
                  navigate('/transcriptions/new/realtime')
                }
              },
            },
          ]}
        >
          <Button icon="PlusCircleIcon" rightIcon="ChevronDownIcon">
            Create new
          </Button>
        </DropdownMenu>
      </div>
    </>
  )
}
