import {Button, Dialog} from '@kensho/neo'
import {useCallback, useState} from 'react'

import {Mode, Stage} from '../../types/types'

interface Props {
  mode: Mode
  stage: Stage
  hasMedia: boolean
  transcriptId?: string
  openFileChooser: () => void
}

const shownForTranscriptIdsCache = new Set<string>()

export default function PlaybackUploadDialog(props: Props): React.ReactNode {
  const {mode, stage, hasMedia, transcriptId, openFileChooser} = props
  const [shownForTranscriptIds, setShownForTranscriptIds] = useState<Set<string>>(
    new Set(shownForTranscriptIdsCache),
  )
  const onClose = useCallback(() => {
    if (transcriptId) {
      shownForTranscriptIdsCache.add(transcriptId)
      setShownForTranscriptIds(new Set(shownForTranscriptIdsCache))
    }
  }, [transcriptId])

  return (
    <Dialog
      isOpen={
        stage === 'POST_TRANSCRIPTION' &&
        mode !== 'realtime' &&
        !hasMedia &&
        !!transcriptId &&
        !shownForTranscriptIds.has(transcriptId)
      }
      title="Playback requires an audio file"
      onClose={onClose}
      backdrop="light"
    >
      <div className="mb-6">
        To view the existing transcript with audio playback, please provide an audio/video file.
      </div>
      <div className="flex justify-end gap-4">
        <Button onClick={onClose}>Ignore</Button>
        <Button
          onClick={() => {
            onClose()
            openFileChooser()
          }}
          intent="primary"
        >
          Upload file
        </Button>
      </div>
    </Dialog>
  )
}
