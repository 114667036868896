import {useLogger} from '@kensho/lumberjack'
import {Button, Tooltip} from '@kensho/neo'
import {useCallback, use} from 'react'

import TranscriptContext from '../../../providers/TranscriptContext'
import {TranscriptSelection} from '../../../types/types'
import {produceEditorAction} from '../../../utils/transcriptRevisionUtils'
import {useDispatchEditorAction} from '../transcript/DispatchEditorActionProvider'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'
import UserContext from '../../../providers/UserContext'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import {prettyShortcutKey} from '../../../shortcuts/ShortcutSection'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'

interface Props {
  transcriptId: string
  transcriptSelection: TranscriptSelection | null
}

export default function CreateNeedsReviewHighlightButton(props: Props): React.ReactNode {
  const {transcriptSelection} = props
  const {transcript} = use(TranscriptContext)
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {dispatchEditorAction} = useDispatchEditorAction()
  const log = useLogger()
  const {user} = use(UserContext)

  const markSelectedForReview = useCallback(async () => {
    if (
      !transcriptPermissions.edit ||
      transcriptSelection?.type !== 'Range' ||
      !transcriptSelection.start ||
      !transcriptSelection.end ||
      !user
    )
      return

    const editorAction = produceEditorAction({
      action: {
        type: 'add-annotation',
        annotation: {
          source: 'scribe',
          knowledgeBase: 'custom',
          label: 'needs-review-highlight',
          type: 'comment',
          createdBy: user.email,
          createdOn: new Date().toISOString(),
        },
        ranges: [
          {
            start: {
              sliceIndex: transcriptSelection.start.sliceIndex,
              tokenIndex: transcriptSelection.start.tokenIndex,
            },
            end: {
              sliceIndex: transcriptSelection.end.sliceIndex,
              tokenIndex: transcriptSelection.end.tokenIndex,
            },
          },
        ],
      },
      transcript,
      transcriptSelection: null,
      log,
    })
    if (!editorAction) return

    dispatchEditorAction(editorAction)
  }, [transcript, transcriptPermissions, transcriptSelection, dispatchEditorAction, log, user])
  const {keys} = SHORTCUTS.Edit.highlight

  useKeyboardShortcut(keys, () => {
    markSelectedForReview()
  })

  const label = `Highlight (${keys.map((key) => prettyShortcutKey(key)).join('')})`
  return (
    <Tooltip content={label} position="bottom">
      <Button
        disabled={
          !transcript || !transcriptPermissions.edit || transcriptSelection?.type !== 'Range'
        }
        onClick={markSelectedForReview}
        icon="PaintBrushIcon"
        minimal
        intent="primary"
        aria-label={label}
      />
    </Tooltip>
  )
}
