import HistoricalTranscriptsTable from '../core/historicalTranscripts/HistoricalTranscriptsTable/HistoricalTranscriptsTable'
import BasePage from '../components/BasePage'

export default function TranscriptionsPage(): React.ReactNode {
  return (
    <BasePage footer={false}>
      <div className="m-auto mt-3 flex w-full max-w-[100vw] flex-auto justify-center gap-10 overflow-hidden">
        <HistoricalTranscriptsTable />
      </div>
    </BasePage>
  )
}
